import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class OrgItem extends Component {
	render() {
		const { organisation } = this.props;

		return (
			<Fragment>
				<tr>
					<th><Link to={'organisation/'+organisation.id}>{organisation.title}</Link></th>
					<th>{organisation.status}</th>
					<th>{ organisation.published_at ? moment(organisation.published_at).format("D MMM YYYY, h:mm A") : ''}</th>
					<th>{ organisation.created_at ? moment(organisation.created_at).format("D MMM YYYY, h:mm A") : ''}</th>
				</tr>
			</Fragment>
		);		
	}
}

export default OrgItem;