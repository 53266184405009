import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { 
	orgPostToApi,
	isNumericBoolean
} from '../Utilities/adapter';
import {
	escapeRegexCharacters
} from '../Utilities/helper';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match'; 
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import ContactModal from '../ContactModal';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
const API_URL = process.env.REACT_APP_API_URL+'api/organisations';
const ORG_URL = process.env.REACT_APP_API_URL+'api/organisations/filter';

const INITIAL_STATE = {
	org: {
		title: '',
		summary: '',
		website: '',
		type: 1,
		artforms: [],
		arts_award_supporter: 0,
		artsmark_partner: 0,
		contact_email: '',
		address_1: '',
		address_2: '',
		borough: '',
		logo: '',
		postcode: '',
		support_send: 0,
		keystages: [],
		contact_first_name: '',
		contact_last_name: '',
		artsmark_partner_offer: '',
	},
	artforms: [],
	organisations: [],
	organisation_id: null,
	organisation_title: '',
	organisation_search: '',
	selected_organisation: [],
	value: ''
};

class OrgCreateForm extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getArtformTypes();
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const formData = orgPostToApi(this.state.org);

		axios.post(API_URL, formData)
		.then(response => {
			if (response.status === 200) {
				this.setState({INITIAL_STATE});

				toast.success('Organisation submitted', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					onClose: () => window.location.reload()
				});

				toast.info('Waiting for admin approval', {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		})
		.catch(errorMsg => {
			console.log(errorMsg);
			console.log(errorMsg.response);
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				console.log(eData);
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	};

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					org: { ...prevState.org, [fieldName] : numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					org: { ...prevState.org, [fieldName] : fieldValue }
				})
			);
		}
	}

	onUploadPhoto = (e) => {
		const file = e.target.files[0];

		this.setState(
			prevState => ({
				org: {
					...prevState.org,
					logo: file		
				}
			})
		);
	}

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						artforms: [...prevState.org.artforms, fieldValue]
					}
				})
			);
		} else {
			let artforms = [...this.state.org.artforms];
			let index = artforms.indexOf(fieldValue);

			if (index !== -1) {
				artforms.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							artforms: artforms
						}
					})
				);				
			}

		}
	}

	selectKeystageCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						keystages: [...prevState.org.keystages, fieldValue]
					}
				})
			);
		} else {
			let keystages = [...this.state.org.keystages];
			let index = keystages.indexOf(fieldValue);

			if (index !== -1) {
				keystages.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							keystages: keystages
						}
					})
				);				
			}

		}
	}

	onSearchOrg = (e) => {
		let fieldValue = e

		this.setState(
			prevState => ({
				org: {
					...prevState.org,
					title: fieldValue
				},
				organisation_search: fieldValue
			}), () => {
				this.getOrganisation();
			}
		);
	};

	onSuggest = (event, { newValue, method }) => {
		if (newValue.length > 0) {
			this.setState(
				prevState => ({
					...prevState,
					org: {
						...prevState.org,
						title: newValue
					},
					organisation_search: newValue,
					value: newValue
				})
			);			
		} else {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						title: ''
					},
					value: '',
					organisation_title: '',
					organisation_search: '',
					organisation_id: null,
					selected_organisation: [],
					organisations: []
				})
			);
		}
			
	};

	onSelectExistingOrganisation = (e) => {
		let orgId = parseInt(e);

		this.setState(
			prevState => ({
				...prevState,
				org: {
					...prevState.org,
					organisation_id: orgId
				},
				organisation_id: orgId
			}), () => {
				this.getOrganisation(orgId)
			}
		);
	};

	getOrganisation = (id) => {
		const orgId = id;

		if (typeof orgId === 'undefined') {
			axios.post(ORG_URL, {
				title: this.state.organisation_search,
				status: 'active'
			})
			.then(response => {
				let organisations = response.data.data;

				if (organisations.length > 0) {
					this.setState(prevState => ({
						...prevState,
						org: {
							...prevState.org
						},
						organisations: organisations
					}));					
				} else {
					this.setState(prevState => ({
						...prevState,
						org: {
							...prevState.org,
							organisation_id: null
						},
						organisations: organisations
					}));
				}
			});			
		} else {
			axios.get(API_URL+'/'+orgId)
			.then(response => {
				let organisations = response.data.data;

				this.setState(prevState => ({
					...prevState,
					selected_organisation: organisations,
					organisation_search: organisations.title,
					org: {
						...prevState.org,
						title: organisations.title
					}
				}));
			});			
		}
	};

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	}

	// Teach Autosuggest how to calculate suggestions for any given input value.
	getSuggestions = value => {
	  	const escapedValue = escapeRegexCharacters(value.trim());

	  	if (escapedValue === '') {
	  		return [];
	  	}

	  	const regex = new RegExp('^' + escapedValue, 'i');
	  	return this.state.organisations.filter(org =>
	   		regex.test(org.title)
	  	);
	};

	// When suggestion is clicked, Autosuggest needs to populate the input
	// based on the clicked suggestion. Teach Autosuggest how to calculate the
	// input value for every given suggestion.
	getSuggestionValue = suggestion => {
		this.onSelectExistingOrganisation(suggestion.id);

		return suggestion.title.toString();
	}

	onSuggestionSelected = (event, { 
		suggestion, suggestionValue, 
		suggestionIndex, sectionIndex, method 
	}) => {
		if (method === 'enter') {
			event.preventDefault();
		}

		this.setState(
			prevState => ({
				...prevState,
				org: {
					...prevState.org
				},
				selected_organisation: suggestion
			})
		);
	}

  	// Autosuggest will call this function every time you need to update suggestions.
  	// You already implemented this logic above, so just use it.
  	onSuggestionsFetchRequested = ({ value }) => {
  		this.onSearchOrg(value);
  	};

  	// Autosuggest will call this function every time you need to clear suggestions.
  	onSuggestionsClearRequested = () => {
    	this.setState(
    		prevState => ({
    			...prevState,
				org: {
					...prevState.org
				},
				organisation_title: '',
				organisation_id: null,
				organisations: [],
    		})
    	);
  	};

	// Use your imagination to render suggestions.
	renderSuggestion (suggestion, { query }) {
		const matches = AutosuggestHighlightMatch(suggestion.title, query);
		const parts = AutosuggestHighlightParse(suggestion.title, matches);

		return (
			<Fragment>
				{parts.map((part, index) => {
					const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

					return (
						<span className={className} key={index}>{part.text}</span>
					);
				})}
			</Fragment>
		)
	}

	renderOrgResults() {
		if (this.state.organisations.length) {
			return (
				<div className="form-group">
					<label htmlFor="orgResults">Organisations</label>

					<select onChange={this.onSelectExistingOrganisation} id="orgResults" className="form-control border" size="5">
						{this.state.organisations.map(org => 
							<option key={org.id} value={org.id} data-title={org.title}>{org.title}</option>
						)}
					</select>
				</div>
			);			
		}
	};

	renderSelectedOrganisation() {
		if (this.state.selected_organisation.length !== 0) {
			return (
				<Fragment>
					<ContactModal name="Contact us to change details about your organisation" classes="btn btn-primary btn-block"/>
					<div className="form-group border bg-white p-1 mt-2">
						<p>{ this.state.selected_organisation.title }</p>
						<p>{ this.state.selected_organisation.summary }</p>
						<p>{ this.state.selected_organisation.arts_award_supporter ? 'Arts Award Supporter' : '' }</p>
						<p>{ this.state.selected_organisation.artsmark_partner ? 'Artsmark Partner' : '' }</p>
					</div>
				</Fragment>
			);			
		}
	}

	renderUploadPhoto() {
		return (
			<div className="form-group my-4">
				<p className="font-weight-bold">Upload Logo</p>
				<input onChange={this.onUploadPhoto} type="file" name="logo" className="form-control border mb-1" placeholder="Logo" />
			</div>
		);
	}

	renderForm() {
		if (this.state.organisation_search !== '' && this.state.selected_organisation.length === 0) {
			return (
				<Fragment>
					<div className="form-group">
						<label htmlFor="orgDescription">Organisation Description (350 characters max)*</label>
						<textarea 
							onChange={this.onInputChange} 
							value={this.state.org.summary} 
							id="orgDescription" 
							className="form-control border" 
							name="summary"
							maxLength="350"
							rows="8" 
							placeholder="Enter a brief summary of the organisation here" 
							required>
						</textarea>
					</div>
				
					<div className="form-group">
						<label htmlFor="websiteURL">Website URL *</label>
						<input onChange={this.onInputChange} value={this.state.org.website} type="text" name="website" className="form-control border" placeholder="http://www.yourwebsiteaddress.com" required/>
					</div>

					{ this.renderUploadPhoto() }

					<div className="form-group mt-4">
						<p className="font-weight-bold text-dark">Artforms *</p>
					</div>

					<div className="row">
						<div className="col">
							{ this.renderArtformSelection() }
						</div>
					</div>

					<div className="my-2">
						<p className="font-weight-bold">Key stage</p>
					</div>

					<div className="d-flex justify-content-between">
						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key0" 
								className="border mr-1" 
								type="checkbox" 
								name="key0" 
								value={0} 
							/>

							<label htmlFor="key1">EY</label>
						</div>
						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key1" 
								className="border mr-1" 
								type="checkbox" 
								name="key1" 
								value={1} 
							/>

							<label htmlFor="key1">1</label>
						</div>

						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key2" 
								className="border mr-1" 
								type="checkbox" 
								name="key2" 
								value={2} 
							/>

							<label htmlFor="key2">2</label>
						</div>

						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key3" 
								className="border mr-1" 
								type="checkbox" 
								name="key3" 
								value={3} 
							/>

							<label htmlFor="key3">3</label>
						</div>

						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key4" 
								className="border mr-1" 
								type="checkbox" 
								name="key4" 
								value={4} 
							/>

							<label htmlFor="key4">4</label>
						</div>

						<div>
							<input 
								onChange={this.selectKeystageCheckboxes} 
								id="key5" 
								className="border mr-1" 
								type="checkbox" 
								name="key5" 
								value={5} 
							/>

							<label htmlFor="key5">5</label>
						</div>
					</div>

					<div className="mt-2">
						<p className="font-weight-bold mb-0">SEND</p>
					</div>

					<div className="form-group">
						<label htmlFor="offerSupport">Is your schools offer adaptable for disabled students? </label>
						<input onChange={this.onInputChange} value={this.state.org.support_send} id="offerSupport" type="checkbox" name="support_send" className="border ml-1" />
					</div>

					<div>
						<p className="font-weight-bold text-primary text-uppercase mb-0">Artsmark Partner</p>
						<p className="mb-2">Have you attended an <a href="https://www.anewdirection.org.uk/what-we-do/our-work-with-cultural-sector/become-an-artsmark-partner">Artsmark Partnership Programme</a> briefing and received confirmation of your Artsmark Partner status?</p>
						
						<div className="form-group d-flex">
							<div className="mr-4">
								<label htmlFor="artsmarkPartner" className="mr-2">Yes</label>
								<input onChange={this.onInputChange} value={this.state.org.artsmark_partner} id="artsmarkPartner" type="checkbox" name="artsmark_partner" className="border mr-1" />
							</div>

							<div>
							<label htmlFor="artsmarkPartnerNo" className="mr-2">No</label>
								<input id="artsmarkPartnerNo" type="checkbox" className="border mr-1" />
							</div>
						</div>

						<p className="mb-0">If yes, please provide details of your Artsmark Partner offer to schools below:</p>
						<textarea onChange={this.onInputChange} value={this.state.org.artsmark_partner_offer} name="artsmark_partner_offer" className="form-control border mb-4" placeholder="Please provide a 200 word description"></textarea>
					</div>

					<div>
						<p className="font-weight-bold text-primary text-uppercase mb-0">Arts Award Supporter</p>
						<p className="mb-2">Is your organisation a registered <a href="https://www.anewdirection.org.uk/programmes/arts-award-in-london/arts-award-supporter">Arts Award Supporter</a>?</p>

						<div className="form-group d-flex">
							<div className="mr-4">
								<label htmlFor="artsAwardSupporter" className="mr-2">Yes</label>
								<input onChange={this.onInputChange} value={this.state.org.arts_award_supporter} id="artsAwardSupporter" type="checkbox" name="arts_award_supporter" className="border mr-1" />
							</div>

							<div>
								<label htmlFor="artsAwardSupporterNo" className="mr-2">No</label>
								<input id="artsAwardSupporterNo" type="checkbox" className="border mr-1" />
							</div>
						</div>
					</div>

					<div>
						<p className="font-weight-bold text-uppercase text-primary my-2">Contact</p>
						<p className="font-weight-bold">Contact details</p>
						<p>Your contact details will not be publicly visible or available to users. A New Direction will use these details to contact you about your organisation listing.</p>

						<input onChange={this.onInputChange} value={this.state.org.contact_first_name} type="text" name="contact_first_name" className="form-control border mb-1" placeholder="Contact First name*" required />
						<input onChange={this.onInputChange} value={this.state.org.contact_last_name} type="text" name="contact_last_name" className="form-control border mb-1" placeholder="Contact Last name*" required />
						<input onChange={this.onInputChange} value={this.state.org.contact_email} type="email" name="contact_email" className="form-control border" placeholder="Contact email*" required />
					</div>

					<div className="mt-3">
						<p className="font-weight-bold">Organisation address</p>
						<input onChange={this.onInputChange} value={this.state.org.address_1} type="text" name="address_1" className="form-control border mb-1" placeholder="Address 1*" required />
						<input onChange={this.onInputChange} value={this.state.org.address_2} type="text" name="address_2" className="form-control border mb-1" placeholder="Address 2" />
						<input onChange={this.onInputChange} value={this.state.org.borough} type="text" name="borough" className="form-control border mb-1" placeholder="Borough*" required />
						<input onChange={this.onInputChange} value={this.state.org.postcode} type="text" name="postcode" className="form-control border" placeholder="Postcode*" required />
					</div>

					<div className="mt-3">
						<p className="font-weight-bold">Privacy</p>
						<p>By submitting content to LookUp you are agreeing to A New Direction storing your details in case we need to contact you in relation to your submission.</p>
					</div>

					<div className="form-group">
						<input id="privacy" type="checkbox" name="privacy" className="border mr-2" required />
						<label htmlFor="privacy">I accept the terms and conditions</label>
					</div>

					<div className="mt-3">
						<p>We often communicate about LookUp via our newsletter, which includes information about our other work, events and opportunities. If you would also like to receive this, please sign up to our newsletter <a href="https://www.anewdirection.org.uk/newsletter" target="_blank" rel="noopener noreferrer">here.</a></p>
					</div>

					<button type="submit" className="btn btn-primary btn-lg mb-5">Submit</button>
				</Fragment>
			);			
		}
	}

	renderArtformSelection() {
		return (
			this.state.artforms.map(art =>
				<div key={art.id} className="form-group" style={{display: 'inline-block', width: '50%'}} >
					<input id={art.id} onChange={this.selectArtformCheckboxes} type="checkbox" name="artforms" className="border mr-1" value={art.id} />
					<label htmlFor={art.id}>{art.name}</label>
				</div>
			)
		);
	}

	render () {
		const { value, organisations } = this.state;

		const autoProps = {
			placeholder: 'Type here',
			value,
			onChange: this.onSuggest
		};

		const theme = {
			container: 'react-autosuggest__container',
			input: 'react-autosuggest__input form-control border',
			suggestion: 'react-autosuggest__suggestion',
			suggestionFirst: 'react-autosuggest__suggestion--first',
			suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
			suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open form-control border p-0',
			suggestionsList: 'list-unstyled p-0 m-0'
		};

		return (
			<section className="container-fluid">
				<div className="row d-flex">
					<div className="col-12 col-md-6 bg-grey order-md-2">
			        	<div className="float-left my-4" style={{maxWidth: '560px', width: '100%'}}>
			        		<p className="font-weight-bold">GUIDELINES</p>
			        		<p>A New Direction is happy to profile and promote arts and cultural organisations on LookUp. If you are interested in adding details of your organisation's schools offer, please complete the form on the left. , and all fields which are required are indicated with a *</p>
			        		
			        		<p className="font-weight-bold">Logos</p>
			        		<p>You can add your organisations logo to your profile. Accepted file formats are PNG, JPG, GIF and BMP.</p>

			        		<p className="font-weight-bold">Website URL</p>
			        		<p>Where possible please add a link to a page on your website with more details about your schools offer, otherwise just link to your homepage.</p>

			        		<p className="font-weight-bold">Artforms</p>
			        		<p>Please specify which artforms are relevant to you work. You must select at least one artform to appear in search results. Artforms are intentionally broad to increase usability for schools, however if you think we're currently missing an artform please <ContactModal name="contact us" classes=""/></p>
							
			        		<p className="font-weight-bold">Key stage</p>
			        		<p>If your schools offer is tailored to specific Key Stages, please select them here.</p>

			        		<p className="font-weight-bold">Artsmark Partner</p>
			        		<p>We will verify your organisation against our Artsmark Partnership records. If approved, an Artsmark logo will be added to your listing page along with details of your offer.</p>

			        		<p className="font-weight-bold">Arts Award Supporter</p>
			        		<p>We will verify your organisation against our Arts Award Supporter records. If approved, an Arts Award logo will be added to your listing page along with details of the Supporter offer.</p>
			        	</div>
					</div>

					<div className="col-12 col-md-6 order-md-1">
						<div className="float-right" style={{maxWidth: '560px', width: '100%'}}>
							<form className="my-4" onSubmit={this.handleSubmit}>
								<div className="form-group">
									<label htmlFor="checkOrgName" className="mb-0 text-primary">What is your organisations name?</label>
									<Autosuggest 
								        suggestions={organisations}
								        onSuggestionSelected={this.onSuggestionSelected}
								        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
								        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
								        getSuggestionValue={this.getSuggestionValue}
								        renderSuggestion={this.renderSuggestion}
										inputProps={autoProps}
										theme={theme}
									/>
								</div>
								
								{ this.renderSelectedOrganisation() }
								{ this.renderForm() }
							</form>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default OrgCreateForm;