import React, { Component, Fragment } from 'react';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import OrgItem from './OrgItem';
import OrgFilter from './OrgFilter';
import Pagination from 'react-js-pagination';
// import '../../../sass/modules/pagination.scss';
import { Scrollbars } from 'react-custom-scrollbars';

const ORG_URL = process.env.REACT_APP_API_URL+'api/organisations';
const INITIAL_STATE = {
	organisations: [],
	pop_state: false,
	org_url: ORG_URL,
	next_page: ORG_URL,
	init_load_more: false,
	filtered: false,
	loading: false,
	sorted: true,
	pagination: [],
	org: {
		keyword: '',
		artsAward: 0,
		artsMark: 0,
		supportSend: 0,
		basic: 0,
		artform: 0,
		postcode: '',
		sort: 'title',
		order: 'asc',
		status: '',
		contact_first_name: '',
		contact_last_name: '',
		keystages: [],
		page: 1,
		npo: 0
	}
};

class OrganisationList extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		if (window.location.search.indexOf('page') > 0) {
			let url = new URL(window.location.href);
			const page = url.searchParams.get('page');

			url = ORG_URL + '?page=' + page;

			this.setState(
				prevState => ({
					...prevState,
					org: {
						...prevState.org,
						page: parseInt(page)
					},
					next_page: url
				}), () => {
					this.init();
				}
			)
		} else {
			this.init();
		}

		this.orgFilter = React.createRef();
	}

	init() {
		if(!this.state.loading) {
			this.setState({
				loading: true
			});

			// API endpoints for organisation list
			axios.get(this.state.next_page)
				.then(response => {
					const paginator = response.data.meta.pagination;
					const organisations = response.data.data;

					if (organisations.length) {
						this.setState(
							prevState => ({
								...prevState,
								org: {
									...prevState.org
								},
								organisations: organisations,
								next_page: paginator.links.next,
								loading: false,
								init_load_more: true,
								pagination: paginator
							}), () => {
								const href = window.location.href;
								let url = href + '?page=' + this.state.org.page;
			
								if (window.location.search.indexOf('page') > 0) {
									const page = this.state.org.page;
									url = '?page=' + page;
								}
			
								this.saveState(url, this.state.organisations, this.state.org.page, this.state.pagination);
								this.events();
							}
						);
					}
				});
		}
	}

	events() {
		window.addEventListener("popstate", (e) => {
			if (e.state !== null) {
				this.setState(
					prevState => ({
						...prevState,
						organisations: e.state.filtersHtml,
						org: {
							...prevState.org,
							page: e.state.page
						},
						pagination: e.state.pagination
					})
				);				
			}
		});
	}

	onClearFilter = () => {
		this.setState(INITIAL_STATE, () => {
			this.init();
			this.orgFilter.current.getArtformTypes();
		});
	};

	onFilteredResults = (props) => {
		this.setState({
			organisations: [],
			init_load_more: false,
			filtered: true,
			org: {
				keyword: props.org.keyword,
				arts_award_supporter: props.org.artsAward,
				artsmark_partner: props.org.artsMark,
				support_send: props.org.supportSend,
				basic: props.org.basic,
				postcode: props.org.postcode,
				keystages: props.org.keystages,
				status: props.org.status,
				contact_first_name: props.org.contact_first_name,
				contact_last_name: props.org.contact_last_name,
				artform: props.org.artform,
				page: props.page,
				npo: props.org.npo,
			}
		}, () => {
			this.getFilteredResults();
		});
	}

	sortOrder(val) {
		const orgStatus = this.state.org.status === '' ? 'active' : this.state.org.status;

		if (!this.state.sorted) {
			this.setState(
				prevState => ({
					...prevState,
					organisations: [],
					init_load_more: false,
					filtered: true,
					org: {
						...prevState.org,
						status: orgStatus,
						sort: val,
						order: 'asc'
					},
					sorted: true
				}), () => {
					this.getFilteredResults();
					
				}
			);				
		} else {
			this.setState(
				prevState => ({
					...prevState,
					organisations: [],
					init_load_more: false,
					filtered: true,
					org: {
						...prevState.org,
						status: orgStatus,
						sort: val,
						order: 'desc'
					},
					sorted: false
				}), () => {
					this.getFilteredResults();
					
				}
			);
		}
	};

	getFilteredResults = () => {
		if (!this.state.loading) {
			this.setState({
				loading: true
			});

			axios.post(ORG_URL+'/filter', {
				keyword: this.state.org.keyword,
				arts_award_supporter: this.state.org.arts_award_supporter,
				artsmark_partner: this.state.org.artsmark_partner,
				support_send: this.state.org.support_send,
				basic: this.state.org.basic,
				postcode: this.state.org.postcode,
				keystages: this.state.org.keystages,
				sort: this.state.org.sort,
				status: this.state.org.status,
				contact_first_name: this.state.org.contact_first_name,
				contact_last_name: this.state.org.contact_last_name,
				artform: this.state.org.artform,
				page: this.state.org.page,
				npo: this.state.org.npo,
				order: this.state.org.order
			})
			.then(response => {
				const organisations = response.data.data;
				const pagination = response.data.meta.pagination;

				if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
					this.setState({
						organisations: organisations,
						loading: false,
						pagination: pagination,
					}, () => {
						const href = window.location.href;
			            let url = href + '?page=' + this.state.org.page;
						
						if (window.location.search.indexOf('page') > 0) {
							const page = this.state.org.page;
							url = '?page=' + page;
						}

						this.saveState(url, this.state.organisations);
					});
				} else {
					this.setState({
						organisations: organisations,
						loading: false,
						pagination: pagination,
					});				
				}
			});
		}
	};

	handlePageChange = (pageNumber) => {
        let url = '';

		if (window.location.search.indexOf('page') > 0) {
			url = ORG_URL + '?page=' + pageNumber;
		}

		this.setState(
			prevState => ({
				...prevState,
				next_page: url,
				org: {
					...prevState.org,
					page: pageNumber
				},
				pagination: {
					...prevState.pagination,
					current_page: pageNumber
				}
			}), () => {
				if (this.state.init_load_more) {
					this.init();
				} else {
					this.getFilteredResults();					
				}
			}
		)
	}

	saveState(url, container, page, pagination) {
		window.history.pushState({
			path : url,
			filtersHtml : container,
			page: page,
			pagination: pagination,
		}, 'Org list', url);
	}

	renderResults() {
		if (this.state.organisations.length) {
			return (
				this.state.organisations.map(org => 
					<OrgItem key={org.id} organisation={org} match={this.props.props.match} saveState={this.saveState} />
				)
			);
		}
	}

	renderLoadStatus() {
		if (this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<PulseLoader
								color={'#28A2FB'}
								loading={this.state.loading}
							/>
						</div>
					</div>
				</div>
			);
		}

		if (!this.state.loading && this.state.organisations.length === 0) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);			
		}
	}

	renderPagination() {
		const pagination = this.state.pagination;

		if (pagination.total_pages > 1 && !this.state.loading) {
			return (
				<section className="bg-grey">
					<div className="container">
						<nav aria-label="Pagination nav">
							<Scrollbars autoHide autoHeight>
								<Pagination 
									activePage={pagination.current_page}
									itemsCountPerPage={pagination.per_page}
									totalItemsCount={pagination.total}
									pageRangeDisplayed={pagination.count}
									onChange={this.handlePageChange}
									itemClassName="page-item"
									linkClassName="page-link"
									firstPageText={<span className="fa fa-angle-double-left"></span>}
									lastPageText={<span className="fa fa-angle-double-right"></span>}
									nextPageText={<span className="fa fa-angle-right"></span>}
									prevPageText={<span className="fa fa-angle-left"></span>}
								/>
							</Scrollbars>
						</nav>
					</div>
				</section>
			)			
		}
	}

	renderNumberResults() {
		const results = this.state.pagination;

		if (this.state.filtered && typeof results.total !== undefined) {
			return (
				<section className="bg-grey">
					<div className="container">
						<div className="row">
							<div className="col">
								<p className="my-2">{results.total} filtered organisations found in alphabetical order</p>
							</div>
						</div>
					</div>
				</section>
			)			
		} else {
			return (
				<section className="bg-grey">
					<div className="container">
						<div className="row">
							<div className="col">
								<p className="my-2">Total of {results.total} organisations found in alphabetical order</p>
							</div>
						</div>
					</div>
				</section>
			)
		}

	}

	render () {
		return (
			<Fragment>
				<OrgFilter 
					ref={this.orgFilter}
					onFilterSubmit={this.filterSubmit} 
					onClearFilter={this.onClearFilter} 
					onFilteredResults={this.onFilteredResults}
					match={this.props.props.match}
				/>
				{this.renderNumberResults()}
				
				<div className="border-top">
					{this.renderPagination()}
				</div>

				<section className="dashboard-results py-0">
				    <div className="dashboard-results-orgs container">
				        <div className="table-responsive">
							<table className="table dashboard-results-table">
								<thead>
									<tr>
										<th style={{borderTop: '0'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('title')}
											>
												Name <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0'}}>Status</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('published')}
											>
												Date published <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('submitted')}
											>
												Submitted on <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
									</tr>
								</thead>

								<tbody>
									{ this.renderResults() }
								</tbody>
							</table>
						</div>
					</div>
				</section>

				{ this.renderLoadStatus() }
				{ this.renderPagination() }
			</Fragment>
		)
	}
}
	
export default OrganisationList;