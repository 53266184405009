import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FrontNav from '../../components/FrontNav';
import TabNav from '../../components/TabNav';
import OrganisationList from '../../components/Organisation/OrgList';

class ScreenOrganisationsList extends Component {
	render () {
		return (
			<section>
				<Header />
				<FrontNav match={this.props.match} />
				<TabNav match={this.props.match} />
	    		<OrganisationList />
	    		<Footer />
			</section>
		)
	}
}

export default ScreenOrganisationsList;