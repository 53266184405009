import React, { Component, Fragment } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

import ScreenHome from './screens/Other/Home';
import ScreenPublish from './screens/Other/Publish';

import ScreenCreateNewActivity from './screens/Activity/Create';

import ScreenEventsTagList from './screens/Activity/Event/TagList';
import ScreenEventsList from './screens/Activity/Event/List';

import ScreenProjectsTagList from './screens/Activity/Project/TagList';
import ScreenProjectsList from './screens/Activity/Project/List';

import ScreenResourcesTagList from './screens/Activity/Resource/TagList';
import ScreenResourcesList from './screens/Activity/Resource/List';

import ScreenOrganisationsArtsmarkPartnerList from './screens/Organisation/ArtsmarkPartner';
import ScreenOrganisationsList from './screens/Organisation/List';
import ScreenCreateNewOrganisation from './screens/Organisation/Create';

import ScreenAdminOrganisationsList from './screens/Auth/Organisation/List';
import ScreenAdminEditOrganisation from './screens/Auth/Organisation/Edit';
import ScreenAdminNewOrganisation from './screens/Auth/Organisation/Create';

import ScreenAdminActivitiesList from './screens/Auth/Activity/List';
import ScreenAdminEditActivity from './screens/Auth/Activity/Edit';
import ScreenAdminCreateActivity from './screens/Auth/Activity/Create';

import ScreenAdminTagsList from './screens/Auth/Tag/List';
import ScreenAdminEditTag from './screens/Auth/Tag/Edit';
import ScreenAdminNewTag from './screens/Auth/Tag/Create';

import ScreenLogin from './screens/Auth/Login';
import ScreenLogout from './screens/Auth/Signout';
import ScreenDashboard from './screens/Auth/Dashboard';
//import ScreenContact from './screens/Contact/Contact';

import ScreenEventsSearchList from './screens/Activity/Event/Search';
import ScreenProjectsSearchList from './screens/Activity/Project/Search';
import ScreenResourcesSearchList from './screens/Activity/Resource/Search';

import ScreenResourcesFreeList from './screens/Activity/Resource/Free';
import ScreenResourcesSuitableForSendList from './screens/Activity/Resource/SuitableForSend';

import ScreenForgotPassword from './screens/Auth/ForgotPassword';
import ScreenResetPassword from './screens/Auth/ResetPassword';

import Screen404 from './screens/404.js';
import './sass/app.scss';

//import GAListener from './components/HOC/GAListener';

class App extends Component {
	render () {
		return (
			<Fragment>
				<Router history={history}>
						<Switch>
							<Route exact path='/' component={ScreenHome} />
							<Route exact path='/publish' component={ScreenPublish} />

							<Route exact path='/organisations' component={ScreenOrganisationsList} />
							<Route exact path='/organisations/create' component={ScreenCreateNewOrganisation} />

							
							<Route exact path='/activities/create' component={ScreenCreateNewActivity} />

							<Route exact path='/tags/:tag/events' component={ScreenEventsTagList} />
							<Route exact path='/events' component={ScreenEventsList} />

							<Route exact path='/tags/:tag/projects' component={ScreenProjectsTagList} />
							<Route exact path='/projects' component={ScreenProjectsList} />

							<Route exact path='/tags/:tag/resources' component={ScreenResourcesTagList} />
							<Route exact path='/resources' component={ScreenResourcesList} />

							<Route exact path='/admin/tags' component={ScreenAdminTagsList} />
							<Route exact path='/admin/tags/create' component={ScreenAdminNewTag} />
							<Route exact path='/admin/tags/:id' component={ScreenAdminEditTag} />
							

							<Route exact path='/admin/organisations' component={ScreenAdminOrganisationsList} />
							<Route exact path='/admin/organisation/:id' component={ScreenAdminEditOrganisation} />
							<Route exact path='/admin/organisations/create' component={ScreenAdminNewOrganisation} />

							<Route exact path='/admin/activities' component={ScreenAdminActivitiesList} />
							<Route exact path='/admin/activity/:id' component={ScreenAdminEditActivity} />
							<Route exact path='/admin/activities/:type/create' component={ScreenAdminCreateActivity} />

							<Route exact path='/login' component={ScreenLogin} />
							<Route exact path='/logout' component={ScreenLogout} />
							<Route exact path='/dashboard' component={ScreenDashboard} />
							<Route exact path='/search/:id/events' component={ScreenEventsSearchList} />
							<Route exact path='/search/:id/projects' component={ScreenProjectsSearchList} />
							<Route exact path='/search/:id/resources' component={ScreenResourcesSearchList} />

							<Route exact path='/artsmark-partner/organisations' component={ScreenOrganisationsArtsmarkPartnerList} />
							<Route exact path='/free/resources' component={ScreenResourcesFreeList} />
							<Route exact path='/suitable-for-send/resources' component={ScreenResourcesSuitableForSendList} />

							{/* <Route exact path='/contact' component={ScreenContact} /> */}
							<Route exact path='/password' component={ScreenForgotPassword} />
							<Route exact path='/reset/:token' component={ScreenResetPassword} />
						
							<Route component={Screen404} />
						</Switch>
				</Router>
				<ToastContainer 
					className='toast-btn'
					toastClassName="toast-btn-body"
				/>
			</Fragment>
		)
	}
}

export default App;