import moment from 'moment';

export const handleDate = event => {
	const fieldValue = event.target.value;
	const fieldName = event.target.name;
	const fieldClass = event.target.getAttribute('data-date');

	if (fieldName === 'start_date') {
		if (moment(this.state.event.start_date).isSameOrAfter(this.state.event.end_date) || this.state.event.end_date === '') {
			this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue,
						end_date : fieldValue
					}
				}),
				() => console.log(this.state)
			)
		} else {
			this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue
					}
				}),
				() => console.log(this.state)
			)
		}
	} else if (fieldName === 'end_date') { 
		if (moment(this.state.event.end_date).isBefore(this.state.event.start_date)) {
			this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue,
						end_date : fieldValue
					}
				}),
				() => console.log(this.state)
			)
		} else {
			this.setState(
				prevState => ({
					event: {
						...prevState.event,
						end_date : fieldValue
					}
				}),
				() => console.log(this.state)
			)
		}
	} else {
		this.setState(
			prevState => ({
				event: {
					...prevState.event,
					[fieldName] : fieldValue
				}
			}),
			() => console.log(this.state)
		)
	}
};

export const escapeRegexCharacters = (str) => {
  	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}