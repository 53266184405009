import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

const API_URL = process.env.REACT_APP_BASE_URL;

class OrgItem extends Component {
	state = {
		artsmarkSupport: false,
		artsawardSupport: false
	};

	logEvent = (org) => {
		ReactGA.event({
			category: 'Organisation',
			action: 'Visit website (' + org.title + ')',
			label: org.title + ' - ' + org.links.website
		});
	}

	onSelectSupport = (e) => {
		const target = e.target.name ? e.target.name : e.target.getAttribute('name');

		if (target === 'artsmarkSupport') {
			this.setState(
				prevState => ({
					artsmarkSupport:  !this.state.artsmarkSupport,
					artsawardSupport: false
				})
			);			
		} else if (target === 'artsawardSupport') {
			this.setState(
				prevState => ({
					artsmarkSupport:  false,
					artsawardSupport: !this.state.artsawardSupport
				})
			);
		}
	}

	renderSummary() {
		const organisation = this.props.organisation;

		if (organisation.summary !== '' || organisation.summary !== false) {
			return (
				<p>{organisation.summary}</p>
			)
		}
	}

	renderContactSummary() {
		const organisation = this.props.organisation;

		if (organisation.summary === '' || organisation.summary === false) {
			return (
				<div className="mb-2">
					<p className="mb-0">We don't have any information for this organisation. To update this organisation please <a href="https://lookup.anewdirection.org.uk/contact">contact us</a>.</p>
				</div>
			)
		}
	}

	renderDetail () {
		const organisation = this.props.organisation;
		const artforms = [];

		organisation.artforms.map((art, index) => {
			if (index === 0) {
				artforms.push(art.charAt(0).toUpperCase() + art.slice(1));
			} else {
				artforms.push(art.toLowerCase());
			}
		});

		return (
			<div className="col-12 col-md-8">
				{this.renderSummary()}

				<div className="mb-0">
					{organisation.artforms.length !== 0 ?
						<span className="font-weight-bold">Artform</span>
						: <div><span className="font-weight-bold">Artform</span> - not specific</div>
					}
					{organisation.artforms.length !== 0 ? ' - ' + artforms.join(', ') : ''}
				</div>
				<div>
					{organisation.keystages.length !== 0 ?
						<span className="font-weight-bold">Key stage</span>
						: <div><span className="font-weight-bold">Key stage</span> - not specific</div>
					}

					{organisation.keystages.length !== 0 ? ' - ' + organisation.keystages.join(', ').replace('0', 'EY') : ''}
				</div>
			</div>
		);
	}

	renderSupport () {
		const organisation = this.props.organisation;

		if (this.state.artsmarkSupport) {
			return (
				<div className="col-12 col-md pl-0 bg-primary">
					<div className="p-md-2 h-100 d-flex flex-column">
	                    <div className="d-flex flex-column">
	                        {(organisation.artsmark_partner === true) ? 
	                        	<div className="d-flex text-white align-items-center">
	                        		<img src="/artsmark-logo.png" alt="artsmark logo" style={{ height:'30px' }} /> Artsmark Partner 
	                        		<button className="btn text-white ml-auto" onClick={this.onSelectSupport} name="artsmarkSupport">
	                        			<span className="sr-only">Artsmark partner</span>
	                        			<span className="fa fa-times" style={{fontSize: '22px'}} onClick={this.onSelectSupport} name="artsmarkSupport"></span>
	                        		</button>
	                        	</div> 
	                        	: ''
	                        }
	                    </div>
	                    
	                    <div className="d-flex flex-column text-white" style={{whiteSpace: 'pre-wrap'}}>
	                    	{organisation.artsmark_partner_offer}
	                    </div>

	                    <div className="d-flex flex-column mt-auto">
	                    	<a href="https://www.anewdirection.org.uk/what-we-do/artsmark/connect-with-an-artsmark-partner" className="text-dark">
	                    		<span className="fa fa-angle-double-right"></span> Find out more about the Artsmark Partnership programme
	                    	</a>
	                    </div>
					</div>
				</div>
			);
		} else if (this.state.artsawardSupport) {
			return (
				<div className="col-12 col-md pl-0 bg-primary">
					<div className="p-md-2 h-100 d-flex flex-column">
	                    <div className="d-flex flex-column">
	                        {(organisation.arts_award_supporter === true) ? 
	                        	<div className="d-flex align-items-center text-white"> 
	                        		<img src="/arts-award-logo.png" alt="arts award logo" style={{ height: '30px' }} /> Arts Award Supporter 
	                        		<button className="btn text-white ml-auto" onClick={this.onSelectSupport} name="artsawardSupport">
	                        			<span className="sr-only">Arts award supporter</span>
	                        			<span className="fa fa-times" style={{fontSize: '22px'}} onClick={this.onSelectSupport} name="artsawardSupport"></span>
	                        		</button>
	                        	</div>
	                        	: ''
	                        }
	                    </div>

	                    <div className="d-flex flex-column text-white">
	                    	Arts Award Supporters are organisations offering activities that can support young people who are working towards achieving their Arts Award.
	                    </div>
	                    
	                    <div className="d-flex flex-column mt-auto">
	                    	<a href="https://www.anewdirection.org.uk/programmes/arts-award-in-london/arts-award-supporter" className="text-dark"><span className="fa fa-angle-double-right"></span> Find out more about Arts Award Supporters</a>
	                    </div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="col-12 col-md pl-0">
					<div className="p-md-2 tab-filter-item-border h-100 d-flex flex-column">
	                    <h3>{organisation.contacts.borough} {organisation.contacts.postcode}</h3>

	                    <div className="d-flex flex-column">
	                        {(organisation.artsmark_partner === true) ? 
	                        	<button className="btn text-left text-primary p-0" onClick={this.onSelectSupport} name="artsmarkSupport"> 
	                        		<img src="/artsmark-logo.png" alt="artsmark logo" style={{ height:'30px' }} /> Artsmark Partner
	                        	</button> 
	                        	: ''
	                        }
	                        {(organisation.arts_award_supporter === true) ? 
	                        	<button className="btn text-left text-primary p-0" onClick={this.onSelectSupport} name="artsawardSupport"> 
	                        		<img src="/arts-award-logo.png" alt="arts award logo" style={{ height: '30px' }} /> Arts Award Supporter
	                        	</button> 
	                        	: ''
	                       	}

	                       	{(organisation.support_send === true) ? 
	                       		<div className="d-flex align-items-center">
	                       			<i className="fa fa-smile-o" aria-hidden="true" style={{height: '30px', width: '30px', fontSize: '35px', marginRight: '4px'}}></i> 
	                       			<span>Accessible for SEND students</span>
	                       		</div>
	                       		: ''
	                       	}
	                    </div>
	                    
	                    { this.renderContactSummary() }

	                    <div className="d-flex flex-column font-weight-bold mt-auto">
	                        {(organisation.links.website !== false) ? <a onClick={() => this.logEvent(organisation)} href={organisation.links.website} target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Visit website</a> : ''}
	                        {(organisation.no_of_activities.events > 0) ? <a href={'search/'+ organisation.id + '/events'}><i className="fa fa-angle-double-right" aria-hidden="true"></i> Events - live posts</a> : ''}
							{(organisation.no_of_activities.projects > 0) ? <a href={'search/'+ organisation.id + '/projects'}><i className="fa fa-angle-double-right" aria-hidden="true"></i> Projects - live posts</a> : ''}
							{(organisation.no_of_activities.resources > 0) ? <a href={'search/'+ organisation.id + '/resources'}><i className="fa fa-angle-double-right" aria-hidden="true"></i> Resources - live posts</a> : ''}
	                        {/* {(organisation.basic === 1) ? <Link to={{ pathname: "/contact", title: organisation.title }}><i className="fa fa-angle-double-right" aria-hidden="true"></i> Contact us to update this profile</Link> : '' } */}
	                    </div>
					</div>
				</div>
			);			
		}
	}

	render () {
		const organisation = this.props.organisation;
		const organisationLogo = (organisation.links.logo) ? <img className="p-1" src={API_URL+'storage/'+organisation.links.logo} alt="" style={{maxHeight: '200px', float: 'right'}} /> : '';

		return (
			<div className="org-detail border-bottom">
				<div className="container">
					<div className="row no-gutters mb-2 mb-md-0">
						<div className="col-12 col-md-8">
							<div className="tab-filter-item my-2">
								<div className="row">
									<div className="col-12 col-md-8">
										<h2 className="mb-1">{organisation.title}</h2>
									</div>
								</div>

								<div className="row">
									{ this.renderDetail() }

									<div className="col-12 col-md">
			                            { organisationLogo }
									</div>
								</div>
							</div>
						</div>

						{ this.renderSupport() }
					</div>
				</div>
			</div>
		);
	}

}

export default OrgItem;