import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Form/Button';
import axios from 'axios';
import { toast } from 'react-toastify';
import history from '../../history';
import redirectLogin from '../../components/Auth/redirectLogin';

const RESET_LINK = process.env.REACT_APP_API_URL+'api/password/reset';
class ResetPassword extends Component {
	state = {
		email: '',
		password: '',
		confirmPassword: '',
		error: '',
		success: '',
		token: '',
	};

	componentDidMount() {
		this.setState({ token: this.props.match.params.token });
	}

	handleSubmit = (e) => {
		e.preventDefault();

		if (this.state.password !== this.state.confirmPassword) {
			return this.setState({ error: 'Password dont match' });
		} else {
			axios.post(RESET_LINK, {
				email: this.state.email,
				password: this.state.password,
				password_confirmation: this.state.confirmPassword,
				token: this.state.token
			})
			.then(response => {
				if (response.status === 200) {
					toast.success("Password updated. Redirecting to login page...", {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
						onClose: () => history.push('/login')
					});
				}
			})
			.catch(errorMsg => {
				console.log(errorMsg);
				let eData = errorMsg.response.data;
				let errM = errorMsg.response.data.errors;

				if (errorMsg.response.data.hasOwnProperty('errors')) {
					Object.entries(errM).map(([field, index]) => {
						let message = 'Error: ' + index;

						this.setState({
							error: message
						});
					});				
				} else {
					console.log(eData);

					this.setState({
						error: eData.message
					});
				}
			});
		}
	}

	renderSuccess() {
		if (this.state.success !== '') {
			return (
				<div className="form-group row">
					<div className="col offset-md-4">
						<p className="text-success mb-0">{this.state.success}</p>
					</div>
				</div>
			);
		}
	}

	renderError() {
		if (this.state.error !== '') {
			return (
				<div className="form-group row">
					<div className="col offset-md-4">
						<p className="text-danger mb-0">{this.state.error}</p>
					</div>
				</div>
			);
		}
	}

	render() {
		return (
			<Fragment>
				<Header />

				<section className="container">
					<div className="row justify-content-center align-items-center py-9">
						<div className="col">
					        <form method="POST" onSubmit={this.handleSubmit}>
					        	{this.renderSuccess()}
					        	{this.renderError()}

						        <div className="form-group row">
						            <label 
						            	htmlFor="email" 
						            	className="col-sm-4 col-form-label text-md-right">
						            	Email address
						            </label>

						            <div className="col-md-4">
						                <input 
						                	id="email"
						                	type="email" 
						                	className="form-control border" 
						                	name="email"
						                	value={this.state.email}
						                	onChange={(e) => this.setState({ email: e.target.value }) }
						                	placeholder={'Enter your email address'}
						                	required={true}
						                	autoFocus={true}
						                />
						            </div>
						        </div>

						        <div className="form-group row">
						            <label 
						            	htmlFor="password" 
						            	className="col-sm-4 col-form-label text-md-right">
						            	Password
						            </label>

						            <div className="col-md-4">
						                <input 
						                	id="password"
						                	type="password" 
						                	className="form-control border" 
						                	name="password"
						                	value={this.state.password}
						                	onChange={(e) => this.setState({ password: e.target.value }) }
						                	placeholder={'Enter your password'}
						                	required={true}
						                />
						            </div>
						        </div>

						        <div className="form-group row">
						            <label 
						            	htmlFor="confirmPassword" 
						            	className="col-sm-4 col-form-label text-md-right">
						            	Confirm password
						            </label>

						            <div className="col-md-4">
						                <input 
						                	id="confirmPassword"
						                	type="password" 
						                	className="form-control border" 
						                	name="confirmPassword"
						                	value={this.state.confirmPassword}
						                	onChange={(e) => this.setState({ confirmPassword: e.target.value }) }
						                	placeholder={'Re-enter password to confirm'}
						                	required={true}
						                />
						            </div>
						        </div>

					            <div className="form-group row mb-0">
					                <div className="col-md-8 offset-md-4">
					                	<Button
					                		title={'Submit'}
					                		type={'submit'}
					                		className={'btn btn-primary btn-lg'}
					                	/>
					                </div>
					            </div>
					        </form>
						</div>
					</div>
				</section>
				
				<Footer />
			</Fragment>
		);
	}
}

export default redirectLogin(ResetPassword);