import React from 'react';

const Keystages = ({ onChange, state }) => {
	return (
		<div className="form-group row">
			<label className="text-primary col-12 col-md-3">Key stage</label>

			<div className="col-12 col-md-8 col-lg-6">
				<input 
					onChange={onChange} 
					id="key0" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage0"
					checked={state.keystage0}
					value={0}
				/>
				<label htmlFor="key0" className="mr-3">EY</label>

				<input 
					onChange={onChange} 
					id="key1" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage1"
					checked={state.keystage1}
					value={1}
				/>
				<label htmlFor="key1" className="mr-3">1</label>

				<input 
					onChange={onChange} 
					id="key2" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage2" 
					checked={state.keystage2}
					value={2} 
				/>
				<label htmlFor="key2" className="mr-3">2</label>
				
				<input 
					onChange={onChange} 
					id="key3" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage3" 
					checked={state.keystage3}
					value={3} 
				/>
				<label htmlFor="key3" className="mr-3">3</label>
				
				<input 
					onChange={onChange} 
					id="key4" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage4" 
					checked={state.keystage4}
					value={4} 
				/>
				<label htmlFor="key4" className="mr-3">4</label>
				
				<input 
					onChange={onChange} 
					id="key5" 
					className="border mr-1" 
					type="checkbox" 
					name="keystage5" 
					checked={state.keystage5}
					value={5} 
				/>
				<label htmlFor="key5">5</label>
			</div>
		</div>
	)
}

export default Keystages;