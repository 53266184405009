import React, { Component } from 'react';
import axios from 'axios';
import history from '../../../history';
import { toast } from 'react-toastify';
import { 
	orgPostToApi,
	isNumericBoolean
} from '../../Utilities/adapter';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
const API_URL = process.env.REACT_APP_API_URL+'api/organisations';

const INITAL_STATE = {
	org: {
		title: '',
		summary: '',
		website: '',
		type: 1,
		artforms: [],
		arts_award_supporter: 0,
		artsmark_partner: 0,
		contact_email: '',
		address_1: '',
		address_2: '',
		logo: '',
		borough: '',
		postcode: '',
		support_send: 0,
		keystages: [],
		contact_first_name: '',
		contact_last_name: '',
		artsmark_partner_offer: '',
		status: 'active',
		npo: 0
	},
	artforms: [],
	organisations: [],
	organisation_id: null,
	organisation_title: '',
	organisation_search: '',
	accessToken: '',
};

class OrgCreate extends Component {
	state = INITAL_STATE;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getArtformTypes();
 		this.setState({ accessToken: localStorage.getItem('accessToken') });
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const formData = orgPostToApi(this.state.org);
		
		axios.post(API_URL+'/admin', formData, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
					onClose: () => history.push('/admin/organisations')
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	};

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					org: { ...prevState.org, [fieldName] : numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					org: { ...prevState.org, [fieldName] : fieldValue }
				})
			);
		}
	}

	onUploadPhoto = (e) => {
		const file = e.target.files[0];

		this.setState(
			prevState => ({
				org: {
					...prevState.org,
					logo: file		
				}
			})
		);
	}

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						artforms: [...prevState.org.artforms, fieldValue]
					}
				})
			);
		} else {
			let artforms = [...this.state.org.artforms];
			let index = artforms.indexOf(fieldValue);

			if (index !== -1) {
				artforms.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							artforms: artforms
						}
					})
				);				
			}

		}
	}

	selectKeystageCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						keystages: [...prevState.org.keystages, fieldValue]
					}
				})
			);
		} else {
			let keystages = [...this.state.org.keystages];
			let index = keystages.indexOf(fieldValue);

			if (index !== -1) {
				keystages.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							keystages: keystages
						}
					})
				);				
			}
		}
	}

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	}

	renderArtformSelection() {
		return (
			this.state.artforms.map(art =>
				<div key={art.id} 
					className="form-group" 
					style={{display: 'inline-block', width: '50%'}}>
					<input 
						id={art.id} 
						onChange={this.selectArtformCheckboxes} 
						type="checkbox" 
						name="artforms" 
						className="border mr-1" 
						value={art.id} 
					/>
					<label htmlFor={art.id}>{art.name}</label>
				</div>
			)
		);
	}

	renderUploadPhoto() {
		return (
			<div className="form-group row">
				<label className="text-primary col-12 col-md-3">Upload Photo</label>

				<div className="col-12 col-md-8 col-lg-6">
					<small><i>Format accepted: PNG, JPG, GIF and BMP</i></small>
					<input onChange={this.onUploadPhoto} type="file" name="logo" className="form-control border mb-1" placeholder="Logo" />
				</div>
			</div>
		);
	}

	render () {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="form-group row">
					<label 
						className="form-label text-primary col-12 col-md-3">Organisation name: </label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.title} 
							type="text" 
							name="title" 
							placeholder="Organisation name" 
							className="form-control border" 
							required />
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="orgDescription"
						className="text-primary col-12 col-md-3">Organisation Description (350 characters max)*: </label>
					<div className="col-12 col-md-8 col-lg-6">
						<textarea 
							onChange={this.onInputChange} 
							value={this.state.org.summary} 
							id="orgDescription" 
							className="form-control border" 
							name="summary"
							maxLength="350"
							rows="8"
							placeholder="Enter a brief summary of the organisation here" 
							required></textarea>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="websiteURL" className="text-primary col-12 col-md-3">Website URL</label>

					<div className="col-12 col-md-8 col-lg-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.website} 
							type="text" 
							name="website" 
							className="form-control border" 
							placeholder="http://www.yourwebsiteaddress.com" 
						/>
					</div>
				</div>

				{ this.renderUploadPhoto() }

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Artforms</label>

					<div className="col-12 col-md-8 col-lg-6">
						{ this.renderArtformSelection() }
					</div>
				</div>

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Key stage</label>

						<div className="col-12 col-md-8 col-lg-6">
						<input onChange={this.selectKeystageCheckboxes} 
								id="key0" 
								className="border mr-1" 
								type="checkbox" 
								name="key0" 
								value={0} 
						/>
						<label htmlFor="key0" className="mr-3">EY</label>

						<input onChange={this.selectKeystageCheckboxes} 
							id="key1" 
							className="border mr-1" 
							type="checkbox" 
							name="key1" 
							value={1} 
						/>
						<label htmlFor="key1" className="mr-3">1</label>

						<input onChange={this.selectKeystageCheckboxes} 
							id="key2" 
							className="border mr-1" 
							type="checkbox" 
							name="key2" 
							value={2} 
						/>
						<label htmlFor="key2" className="mr-3">2</label>
						
						<input onChange={this.selectKeystageCheckboxes} 
							id="key3" 
							className="border mr-1" 
							type="checkbox" 
							name="key3" 
							value={3} 
						/>
						<label htmlFor="key3" className="mr-3">3</label>
						
						<input onChange={this.selectKeystageCheckboxes} 
							id="key4" 
							className="border mr-1" 
							type="checkbox" 
							name="key4" 
							value={4} 
						/>
						<label htmlFor="key4" className="mr-3">4</label>
						
						<input onChange={this.selectKeystageCheckboxes} 
							id="key5" 
							className="border mr-1" 
							type="checkbox" 
							name="key5" 
							value={5} />
						<label htmlFor="key5">5</label>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="npo" className="text-primary col-12 col-md-3">NPO</label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.npo} 
							id="npo" 
							type="checkbox" 
							name="npo" 
							className="border" />
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="offerSupport" className="text-primary col-12 col-md-3">Is your offer designed / adaptable for disabled students?</label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.support_send} 
							id="offerSupport" 
							type="checkbox" 
							name="support_send" 
							className="border" />
					</div>
				</div>

				<div className="form-group row">
					<label 
						htmlFor="artsmarkPartner" 
						className="col-12 col-md-3 text-primary">Artsmark Partner</label>

					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.artsmark_partner} 
							id="artsmarkPartner" 
							type="checkbox" 
							name="artsmark_partner" 
							className="border mr-1" 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="artsAwardSupporter" 
						className="text-primary col-12 col-md-3">Arts Award Supporter</label>
					
					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.arts_award_supporter} 
							id="artsAwardSupporter" 
							type="checkbox" 
							name="arts_award_supporter" 
							className="border mr-1" 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="artsAwardPartnerOffer" className="col-12 col-md-3 text-primary">Your Artsmark Partner offer</label>

					<div className="col-12 col-md-8 col-lg-6">
						<textarea 
							onChange={this.onInputChange} 
							value={this.state.org.artsmark_partner_offer}
							id="artsAwardPartnerOffer"
							name="artsmark_partner_offer" 
							className="form-control border"
							rows="6"
							placeholder="Please provide a 200 word description"></textarea>
					</div>
				</div>

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Contact details</label>

					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.contact_first_name} 
							type="text" 
							name="contact_first_name" 
							className="form-control border mb-1" 
							placeholder="Contact First name*" 
							required 
						/>
	
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.contact_last_name} 
							type="text" 
							name="contact_last_name" 
							className="form-control border mb-1" 
							placeholder="Contact Last name*" 
							required 
						/>

						<input 
							onChange={this.onInputChange} 
							value={this.state.org.contact_email} 
							type="email" 
							name="contact_email" 
							className="form-control border" 
							placeholder="Contact email*" 
							required 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Address</label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.address_1} 
							type="text" 
							name="address_1" 
							className="form-control border mb-1" 
							placeholder="Address 1"
						/>

						<input 
							onChange={this.onInputChange} 
							value={this.state.org.address_2} 
							type="text" 
							name="address_2" 
							className="form-control border mb-1" 
							placeholder="Address 2" />
						
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.borough} 
							type="text" 
							name="borough" 
							className="form-control border mb-1" 
							placeholder="Borough*" 
							required 
						/>
						
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.postcode} 
							type="text" 
							name="postcode" 
							className="form-control border" 
							placeholder="Postcode*" 
							required 
						/>
					</div>
				</div>

                <div className="form-group row">
                    <label 
                    	htmlFor="status" 
                    	className="form-label text-primary col-12 col-md-3">Status</label>

                    <div className="col-12 col-md-8 col-lg-6">
	                    <select 
	                    	id="status"
	                    	onChange={this.onInputChange} 
	                    	value={this.state.org.status}
	                    	name="status"
	                    	className="form-control tab-filter-select border"
	                    >
	                        <option value="active">Active</option>
	                        <option value="event_only">Event only</option>
						    <option value="rejected">Rejected</option>
							<option value="inactive">Inactive</option>
	                    </select>
	                </div>
                </div>

				<div className="form-group row mt-4">
					<div className="col-12 col-lg-3 offset-md-3">
						<button className="btn btn-primary btn-lg">Submit</button>
						<button className="btn text-primary" onClick={() => history.goBack() }>Cancel</button>
					</div>
				</div>
			</form>
		)
	}
}

export default OrgCreate;