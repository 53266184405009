import React from 'react';
import { NavLink } from 'react-router-dom';

const QuickSearchLinks = () => (
    <div className="bg-primary pt-5 pb-5">

        <div className="container">
            <h2 className="text-white mb-3">QUICK SEARCH LINKS</h2>
            <div className="row">
                <div className="col-md-6 mb-5">
                    <NavLink to="/artsmark-partner/organisations">
                        <div className="quick-search-link">
                            <div className="title">Browse Artsmark Partner organisations</div>
                            <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </NavLink>
                </div>

                <div className="col-md-6 mb-5">
                    <NavLink to="/free/resources">
                        <div className="quick-search-link">
                            <div className="title">Free online resources</div>
                            <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    </div>
);


export default QuickSearchLinks;