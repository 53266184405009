import React, { Component } from 'react';
import axios from 'axios';
import './OrgFilter.css';
import { 
	isNumericBoolean
} from '../Utilities/adapter';
import ReactGA from 'react-ga';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
let keystages = [];
const INITIAL_STATE = {
	org: {
		title: '',
		keyword: '',
		arts_award_supporter: 0,
		artsmark_partner: 0,
		support_send: 0,
		artform: 0,
		postcode: '',
		sort: 'title',
		distance: 2,
		keystages: []
	},
	organisations: [],
	next_page: '',
	allKeystages: false,
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	artforms: [],
	loading: false,
	page: 1
};

class OrganisationFilter extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.getArtformTypes();

		this.setState( prevState => ({
			...prevState,
			org: {
				...prevState.org,
				artsmark_partner: (this.props.artsmarkPartner && this.props.artsmarkPartner === 1) ? this.props.artsmarkPartner : ''
			}
		}));
	}

	logEvent = (org) => {
		ReactGA.event({
			category: 'Organisation filter',
			action: 'Filter type: ' + org.filterType,
			label: 'Filter value: ' + org.filterLabel
		});
	}

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	};

	onInputChange = (event) => {
		let fieldName = event.target.name;
		let fieldValue = event.target.value;

		if (event.target.type === 'checkbox') {
			let boolean = event.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					org: { ...prevState.org, [fieldName] : numericBoolean }
				}),
				() => this.handleSubmit
			);
		} else {
			this.setState(
				prevState => ({
					org: { ...prevState.org, [fieldName] : fieldValue }
				})
			);
		}
	}

	onPostcodeChange = (event) => {
		let fieldValue = event.target.value;

		this.setState(
			prevState => ({
				org: { 
					...prevState.org, 
					postcode : fieldValue,
					sort: 'distance'
				}
			})
		);
	};

	selectArtform = (e) => {
		let fieldValue = e.target.value;

		this.setState(
			prevState => ({
				org: { ...prevState.org, artform : fieldValue }
			}),
			() => this.handleSubmit
		);
	};

	selectKeystage = (e) => {
		let fieldValue = e.target.value;
		let keyName = e.target.id;

		if (e.target.checked) {
			keystages.push(parseInt(fieldValue));

			this.setState(
				prevState => ({
					org: { ...prevState.org, keystages: keystages },
					[keyName]: true
				}),
				() => this.handleSubmit
			);				
		} else {
			keystages.pop();

			this.setState(
				prevState => ({
					org: { ...prevState.org, keystages: keystages },
					[keyName]: false
				}),
				() => this.handleSubmit
			);	
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();

		if (this.state.org.keyword !== '') {
			this.logEvent({
				filterType: 'Search by keyword',
				filterLabel: 'Keyword: ' + this.state.org.keyword 					
			});
		}

		if (this.state.org.postcode !== '') {
			this.logEvent({
				filterType: 'Enter postcode',
				filterLabel: 'Near you: ' + this.state.org.postcode 					
			});
		}

		if (this.state.org.keystages.length) {
			this.logEvent({
				filterType: 'Specific key stage',
				filterLabel: 'key stage: ' + this.state.org.keystages
			});
		}

		if (this.state.org.arts_award_supporter !== 0) {
			this.logEvent({
				filterType: 'Arts award supporter',
				filterLabel: this.state.org.arts_award_supporter
			});
		}

		if (this.state.org.artsmark_partner !== 0) {
			this.logEvent({
				filterType: 'Artsmark partner',
				filterLabel: this.state.org.artsmark_partner
			});
		}

		if (this.state.org.support_send !== 0) {
			this.logEvent({
				filterType: 'Accessible for SEND students',
				filterLabel: this.state.org.support_send
			});
		}

		if (this.state.org.artform !== 0) {
			const artName = this.state.artforms.filter(art => {
				return art.id === parseInt(this.state.org.artform);
			});

			this.logEvent({
				filterType: 'Artform',
				filterLabel: artName[0].name
			});
		}

		this.props.onFilteredResults(this.state);
	};

	onClearFilter = (e) => {
		e.preventDefault();

		this.setState(INITIAL_STATE, () => {
			keystages = [];
			this.props.onClearFilter();			
		});
	};

	renderArtformSelection() {
		return (
			<select 
				onChange={this.onInputChange} 
				value={this.state.org.artform} 
				name="artform" 
				id="artform" 
				className="form-control tab-filter-select"
			>
				<option value={0}>CHOOSE</option>
				{this.state.artforms.map(art =>
					<option key={art.id} value={art.id}>{art.name}</option>
				)}
			</select>
		);
	}

	renderForm () {
		const postcodeAdded = this.state.org.postcode === '';

		return (
			<form onSubmit={this.handleSubmit} className="tab-filter-form">
				<div className="row">
					<div className="col-12 col-md-6 order-md-1">
						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3">
								<label htmlFor="artform" className="tab-filter-label">Artform</label>
							</div>

							<div className="col">
								{ this.renderArtformSelection() }
							</div>
						</div>

						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3 d-flex align-items-center">
			    				<label htmlFor="keystage" className="tab-filter-label specific-keystage">Specific key stage</label>
			    			</div>

			    			<div className="col">
				    			<div id="keystage" className="d-flex justify-content-between text-white">
									<div>
					    				<label htmlFor="key0" className="mx-1 mb-0">EY</label>
					    				<input onChange={this.selectKeystage} id="keystage0" type="checkbox" name="keystages" value={0} checked={this.state.keystage0} />
					    			</div>
					    			<div>
					    				<label htmlFor="key1" className="mx-1 mb-0">1</label>
					    				<input onChange={this.selectKeystage} id="keystage1" type="checkbox" name="keystages" value={1} checked={this.state.keystage1} />
					    			</div>
					    			<div>
					    				<label htmlFor="key2" className="mx-1 mb-0">2</label>
					    				<input onChange={this.selectKeystage} id="keystage2" type="checkbox" name="keystages" value={2} checked={this.state.keystage2} />
					    			</div>
					    			<div>
					    				<label htmlFor="key3" className="mx-1 mb-0">3</label>
					    				<input onChange={this.selectKeystage} id="keystage3" type="checkbox" name="keystages" value={3} checked={this.state.keystage3} />
					    			</div>
					    			<div>
					    				<label htmlFor="key4" className="mx-1 mb-0">4</label>
					    				<input onChange={this.selectKeystage} id="keystage4" type="checkbox" name="keystages" value={4} checked={this.state.keystage4} />
					    			</div>
					    			<div>
					    				<label htmlFor="key5" className="mx-1 mb-0">5</label>
					    				<input onChange={this.selectKeystage} id="keystage5" type="checkbox" name="keystages" value={5} checked={this.state.keystage5} />
					    			</div>
				    			</div>
				    		</div>
			    		</div>
		    		</div>

					<div className="col-12 col-md-6 order-md-2 mb-2 mb-md-0">
						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3">
								<label 
									htmlFor="orgbyname" 
									className="tab-filter-label">Keyword</label>
							</div>

							<div className="col">
								<input 
									id="orgbyname" 
									onChange={this.onInputChange} 
									value={this.state.org.keyword} 
									type="text" 
									name="keyword" 
									placeholder="SEARCH BY KEYWORD" 
									className="form-control" 
								/>
							</div>
						</div>

			    		<div className="row mb-2 flex-column flex-lg-row">
			    			<div className="col-12 col-lg-3">
			    				<label className="tab-filter-label">Near you</label>
			    			</div>

			    			<div className="col">
								<input onChange={this.onPostcodeChange} type="text" name="postcode" placeholder="ENTER POSTCODE" className="form-control" value={this.state.org.postcode} />
							</div>
			    		</div>

			    		<div className="row mb-2 flex-column flex-lg-row">
			    			<div className="col-12 col-lg-3 pr-0">
			    				<label className="tab-filter-label">Distance: {this.state.org.distance} mi</label>
			    			</div>

			    			<div className="col d-flex align-items-center">
								<input 
									id="distance"
									onChange={this.onInputChange}
									type="range" 
									name="distance" 
									min="1" 
									max="25" 
									value={this.state.org.distance} 
									className="form-control-range tab-filter-select"
									disabled={ postcodeAdded }
								/>
			    			</div>
			    		</div>
					</div>
				</div>

				<div className="row mb-2">
	    			<div className="col-12 col-md d-flex align-items-center mb-2 mb-lg-0" style={{whiteSpace: 'nowrap'}}>
	    				<label 
	    					htmlFor="artsmark_partner" 
	    					className="mr-auto mr-lg-2 mb-0"
	    				>
	    					Artsmark Partner
	    				</label>

	    				<input 
	    					onChange={this.onInputChange} 
	    					id="artsmark_partner" 
	    					type="checkbox" 
	    					checked={this.state.org.artsmark_partner} 
	    					name="artsmark_partner"
	    				/>
	    			</div>

			    	<div className="col-12 col-md d-flex align-items-center mb-2 mb-lg-0" style={{whiteSpace: 'nowrap'}}>
	    				<label 
	    					htmlFor="sendapp" 
	    					className="mr-auto mr-lg-2 mb-0"
	    				>
	    					Accessible for SEND students
	    				</label>

	    				<input 
	    					onChange={this.onInputChange} 
	    					id="sendapp" 
	    					type="checkbox" 
	    					checked={this.state.org.support_send} 
	    					name="support_send"
	    				/>
	    			</div>

    				<div className="col-12 col-md-6 col-lg d-flex align-items-center mb-2 mb-lg-0 justify-content-end" style={{whiteSpace: 'nowrap'}}>
    					<label 
    						htmlFor="arts_award_supporter" 
    						className="mr-auto mr-lg-2 mb-0"
    					>
    						Arts Award Supporter
    					</label>

	    				<input 
	    					onChange={this.onInputChange} 
	    					id="arts_award_supporter" 
	    					type="checkbox" 
	    					checked={this.state.org.arts_award_supporter} 
	    					name="arts_award_supporter"
	    				/>
    				</div>
				</div>

	    		<div className="row">
					<div className="col offset-lg-7">
						<button onClick={this.handleSubmit} className="btn btn-dark btn-lg font-weight-bold text-white">SHOW RESULTS</button>
						<button onClick={this.onClearFilter} className="btn text-light font-weight-bold text-underline">CLEAR FILTERS</button>
					</div>
				</div>
			</form>
		);
	}

	render () {
		return (
			<section className="bg-primary">
				<div className="tab-filters container">
					{this.renderForm()}
				</div>
			</section>
		)
	}
}

export default OrganisationFilter;