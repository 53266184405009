import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { 
	isNumericBoolean
} from '../../Utilities/adapter';
import ReactGA from 'react-ga';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
let keystages = [];
const INITIAL_STATE = {
	project: {
		title: '',
		keyword: '',
		organisation_title: '',
		artsmark_partner: 0,
		support_send: 0,
		keystages: [],
		artform: 0,
		venue_name: '',
		postcode: '',
		free: '',
		distance: 2,
		status: ''
	},
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	artforms: [],
	projects: [],
	page: 1
};

class ProjectFilter extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.getArtformTypes();
	}

	logEvent = (event) => {
		ReactGA.event({
			category: 'Projects filter',
			action: 'Filter type: ' + event.filterType,
			label: 'Filter value: ' + event.filterLabel
		});
	}

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	};

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					project: { ...prevState.project, [fieldName] : numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					project: { ...prevState.project, [fieldName] : fieldValue }
				})
			);
		}
	}

	onPostcodeChange = (event) => {
		let fieldValue = event.target.value;

		this.setState(
			prevState => ({
				project: { 
					...prevState.project, 
					postcode : fieldValue,
					sort: 'distance'
				}
			})
		);
	};

	selectOption = (e) => {
		let fieldValue = e.target.value;
		let fieldName = e.target.name;

		this.setState(
			prevState => ({
				project: { ...prevState.project, [fieldName] : fieldValue }
			})
		);
	};

	selectKeystage = (e) => {
		let fieldValue = e.target.value;
		let keyName = e.target.id;

		if (e.target.checked) {
			keystages.push(parseInt(fieldValue));

			this.setState(
				prevState => ({
					project: { ...prevState.project, keystages: keystages },
					[keyName]: true
				})
			);				
		} else {
			keystages.pop();
			console.log(keystages);

			this.setState(
				prevState => ({
					project: { ...prevState.project, keystages: keystages },
					[keyName]: false
				})
			);	
		}
	};
	
	handleSubmit = (e) => {
		e.preventDefault();

		console.log(this.state);

		if (this.state.project.keyword !== '') {
			this.logEvent({
				filterType: 'Search by keyword',
				filterLabel: 'Keyword: ' + this.state.project.keyword 					
			});
		}

		if (this.state.project.organisation_title !== '') {
			this.logEvent({
				filterType: 'Search by organisation',
				filterLabel: 'Organisation: ' + this.state.project.organisation_title 					
			});
		}

		if (this.state.project.postcode !== '') {
			this.logEvent({
				filterType: 'Enter postcode',
				filterLabel: 'Near you: ' + this.state.project.postcode 					
			});
		}

		if (this.state.project.keystages.length) {
			this.logEvent({
				filterType: 'Specific key stage',
				filterLabel: 'key stage: ' + this.state.project.keystages
			});
		}

		if (this.state.project.free !== '') {
			this.logEvent({
				filterType: 'Free',
				filterLabel: this.state.project.free
			});
		}

		if (this.state.project.artsmark_partner !== 0) {
			this.logEvent({
				filterType: 'Artsmark partner',
				filterLabel: this.state.project.artsmark_partner
			});
		}

		if (this.state.project.support_send !== 0) {
			this.logEvent({
				filterType: 'Accessible for SEND students',
				filterLabel: this.state.project.support_send
			});
		}

		if (this.state.project.artform !== 0) {
			const artName = this.state.artforms.filter(art => {
				return art.id === parseInt(this.state.project.artform);
			});

			this.logEvent({
				filterType: 'Artform',
				filterLabel: artName[0].name
			});
		}

		this.props.onFilteredResults(this.state);
	};

	onClearFilter = (e) => {
		e.preventDefault();

		this.setState(INITIAL_STATE, () => {
			keystages = [];
			this.props.onClearFilter();
		});
	};

	renderArtformSelection() {
		return (
			<select 
				onChange={this.onInputChange} 
				value={this.state.project.artform} 
				name="artform" 
				id="artform" 
				className="form-control tab-filter-select"
			>
				<option value={0}>CHOOSE</option>
				{this.state.artforms.map(art =>
					<option key={art.id} value={art.id}>{art.name}</option>
				)}
			</select>
		);
	}

	renderForm () {
		const postcodeAdded = this.state.project.postcode === '';

		return (
			<form className="tab-filter-form">
				<div className="row">
					<div className="col-12 col-md-6 order-md-1">
						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3">
								<label htmlFor="artform" className="tab-filter-label">Artform</label>
							</div>

							<div className="col">
								{ this.renderArtformSelection() }
							</div>
						</div>

						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3 d-flex align-items-center">
			    				<label htmlFor="keystage" className="tab-filter-label specific-keystage">Specific key stage</label>
			    			</div>

			    			<div className="col">
				    			<div id="keystage" className="d-flex justify-content-between text-white">
									<div>
					    				<label htmlFor="keystage0" className="mx-1 mb-0">EY</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage0} 
					    					id="keystage0" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={0} 
					    				/>
					    			</div>
					    			<div>
					    				<label htmlFor="keystage1" className="mx-1 mb-0">1</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage1} 
					    					id="keystage1" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={1} 
					    				/>
					    			</div>
					    			<div>
					    				<label htmlFor="keystage2" className="mx-1 mb-0">2</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage2} 
					    					id="keystage2" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={2} 
					    				/>
					    			</div>
					    			<div>
					    				<label htmlFor="keystage3" className="mx-1 mb-0">3</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage3} 
					    					id="keystage3" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={3} 
					    				/>
					    			</div>
					    			<div>
					    				<label htmlFor="keystage4" className="mx-1 mb-0">4</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage4} 
					    					id="keystage4" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={4} 
					    				/>
					    			</div>
					    			<div>
					    				<label htmlFor="keystage5" className="mx-1 mb-0">5</label>
					    				<input 
					    					onChange={this.selectKeystage} 
					    					checked={this.state.keystage5} 
					    					id="keystage5" 
					    					type="checkbox" 
					    					name="keystages" 
					    					value={5} 
					    				/>
					    			</div>
				    			</div>
				    		</div>
			    		</div>

			    		<div className="d-flex row">
				    		<div className="col-12 col-md-8 d-flex mb-2 mb-md-0 align-items-center">
				    			<label className="mr-auto mr-lg-2 mb-0 tab-filter-label" htmlFor="eventSuitable">Accessible for SEND students</label>
								<input 
									onChange={this.onInputChange} 
									value={this.state.project.support_send} 
									checked={this.state.project.support_send} 
									id="eventSuitable" 
									type="checkbox" 
									name="support_send" 
								/>
				    		</div>

				    		<div className="col-12 col-md d-flex mb-2 mb-md-0 justify-content-end align-items-center">
				    			<label className="mr-auto mr-lg-2 mb-0 tab-filter-label" htmlFor="eventFree">Free</label>
								<input 
									onChange={this.onInputChange} 
									value={this.state.project.free} 
									checked={this.state.project.free} 
									id="eventFree" 
									type="checkbox" 
									name="free" 
								/>
				    		</div>
				    	</div>
		    		</div>

					<div className="col-12 col-md-6 order-md-2 mb-2 mb-md-0">
						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3">
								<label htmlFor="eventByName" className="tab-filter-label">Keyword</label>
							</div>

							<div className="col">
								<input 
									onChange={this.onInputChange} 
									value={this.state.project.keyword} 
									id="eventByName" 
									type="text" 
									name="keyword" 
									placeholder="SEARCH BY KEYWORD" 
									className="form-control" 
								/>
							</div>
						</div>

						<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-md-3">
								<label htmlFor="eventByOrg" className="tab-filter-label">Organisation</label>
							</div>

							<div className="col">
								<input 
									onChange={this.onInputChange} 
									value={this.state.project.organisation_title} 
									id="eventByOrg" 
									type="text" 
									name="organisation_title" 
									placeholder="SEARCH BY ORGANISATION" 
									className="form-control" 
								/>
							</div>
						</div>

			    		<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-lg-3">
				    			<label className="tab-filter-label">Near you</label>
							</div>

							<div className="col">
								<input 
									onChange={this.onPostcodeChange} 
									type="text" 
									value={this.state.project.postcode} 
									name="postcode" 
									placeholder="ENTER POSTCODE" 
									className="form-control" 
								/>
							</div>
			    		</div>

			    		<div className="row mb-2 flex-column flex-lg-row">
							<div className="col-12 col-lg-3 pr-0">
				    			<label className="tab-filter-label">Distance: {this.state.project.distance} mi</label>
							</div>

							<div className="col d-flex align-items-center">
								<input 
									id="distance"
									onChange={this.onInputChange}
									type="range" 
									name="distance" 
									min="1" 
									max="25" 
									value={this.state.project.distance} 
									className="form-control-range tab-filter-select"
									disabled={postcodeAdded}
								/>
							</div>
			    		</div>

			    		<div className="row">
							<div className="col offset-lg-3">
								<button 
									onClick={this.handleSubmit} 
									className="btn btn-dark btn-lg font-weight-bold text-white">SHOW RESULTS</button>
								<button 
									onClick={this.onClearFilter} 
									className="btn text-light font-weight-bold text-underline">CLEAR FILTERS</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		);
	}

	render () {
		return (
			<Fragment>
				<section className="bg-primary">
					<div className="tab-filters container">
						{ this.renderForm() }
					</div>
				</section>
			</Fragment>
		)
	}
}

export default ProjectFilter;