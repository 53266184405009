import React, { Component, Fragment } from 'react';
import { PulseLoader, BarLoader } from 'react-spinners';
import axios from 'axios';
import ProjectItem from './ProjectItem';
import ProjectFilter from './ProjectFilter';

const API_URL = process.env.REACT_APP_API_URL+'api/projects';
const ORG_API_URL = process.env.REACT_APP_API_URL+'api/organisations';
const INITIAL_STATE = {
	projects: [],
	next_page: API_URL,
	loading: false,
	init_load_more: false,
	pagination: [],
	project: {
		title: '',
		type: 0,
		organisation_title: '',
		artsmark_partner: 0,
		support_send: 0,
		keystages: [],
		artform: 0,
		venue_name: '',
		postcode: '',
		status: 'active',
		sort: 'end',
		page: 1
	}
};

class ProjectSearchList extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.projectFilter = React.createRef();
		
		if (this.props.id && !this.state.loading) {

			this.setState({
				loading: true
			});

			axios.get(ORG_API_URL+'/'+this.props.id)
				.then(response => {
					let orgTitle = response.data.data.title;

					this.setState(
						prevState => ({
							project: {
								organisation_title: orgTitle,
								visible: 1,
								status: 'active'
							},
							loading: false,
						}), () => console.log(this.state)
					);

					this.getFilteredResults();
				});
		} else {
			this.init();
		}
	}

	init() {
		if(!this.state.loading) {
			this.setState({
				loading: true
			});

			axios.get(this.state.next_page)
				.then(response => {
					const paginator = response.data.meta.pagination;
					const projects = response.data.data;

					if(projects.length){
						this.setState({
							projects: [...this.state.projects, ...projects],
							next_page: paginator.links.next,
							loading: false
						});					
					}

					if(paginator.links.next){
						this.setState({ init_load_more: true });
					}
				});
		}
	}

	onClearFilter = () => {
		this.setState(INITIAL_STATE, () => {
			this.init();
			this.projectFilter.current.getArtformTypes();
		});
	};

	onFilteredResults = (props) => {
		this.setState({
			projects: [],
			init_load_more: false,
			project: {
				title: props.project.title,
				type: props.project.type,
				organisation_title: props.project.organisation_title,
				support_send: props.project.support_send,
				keystages: props.project.keystages,
				postcode: props.project.postcode,
				artform: props.project.artform,
				sort: props.project.sort,
				status: props.project.status,
				page: props.page,				
			}
		}, () => this.getFilteredResults()
		);
	}

	getFilteredResults = () => {
		console.log(this.state);

		axios.post(API_URL+'/filter', {
			title: this.state.project.title,
			type: this.state.project.type,
			organisation_title: this.state.project.organisation_title,
			support_send: this.state.project.support_send,
			keystages: this.state.project.keystages,
			postcode: this.state.project.postcode,
			artform: this.state.project.artform,
			sort: this.state.project.sort,
			status: this.state.project.status,
			page: this.state.project.page,
			visible: this.state.project.visible
		})
		.then(response => {
			const projects = response.data.data;
			const pagination = response.data.meta.pagination;

			if (this.state.pagination.total_pages > this.state.pagination.current_page) {
				this.setState({
					projects: [...this.state.projects, ...projects],
					loading: false,
					pagination: pagination,
				});
			} else {
				this.setState({
					projects: projects,
					loading: false,
					pagination: pagination,
				});				
			}
		});
	};

	onLoadMore = () => {
		if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
			this.setState(
				prevState => ({
					project: {
						...prevState.project,
						page: this.state.project.page + 1					
					}
				}), () => this.getFilteredResults()
			);
		}

		if (this.state.init_load_more) {
			this.init();
		}
	};

	renderLoadmore() {
		if (this.state.pagination.total_pages > this.state.pagination.current_page || this.state.init_load_more) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">						
						<button onClick={this.onLoadMore} className="btn btn-primary btn-lg">
							Load More <div className="loading-spinner">
							<BarLoader
								color={'#FFF'}
								loading={this.state.loading}
							/>
						</div></button>
					</div>
				</div>
			);	
		}
	};

	renderResults() {
		if (this.state.projects.length) {
			return (
				this.state.projects.map(project => 
					<ProjectItem key={project.id} project={project}/>
				)
			);
		}

		if (!this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);			
		}
	}

	renderLoadStatus() {
		if (this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<PulseLoader
								color={'#28A2FB'}
								loading={this.state.loading}
							/>
						</div>
					</div>
				</div>
			);
		}
	}

	render() {
		return (
			<Fragment>
				<ProjectFilter 
					ref={this.projectFilter}
					onClearFilter={this.onClearFilter} 
					onFilteredResults={this.onFilteredResults}
				/>

				<section id="tabResults" className="tab-results">
					{ this.renderResults() }
					{ this.renderLoadmore() }
					{ this.renderLoadStatus() }
				</section>
			</Fragment>
		)
	}
}

export default ProjectSearchList;