import React, { Component, Fragment } from 'react';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import TagItem from './TagItem';

const TAG_URL = process.env.REACT_APP_API_URL+'api/tags';
const INITIAL_STATE = {
	tags: []
};

class TagList extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		
			this.init();
		
	}

	init() {
		if(!this.state.loading) {
			this.setState({
				loading: true
			});

			// API endpoints for tags
			axios.get(TAG_URL)
				.then(response => {
					
					const tags = response.data.data;

					console.log(tags);

					if (tags.length) {
						this.setState({
					
								tags: tags,
								loading: false,
								
					
						});
					}
				});
		}
	}

	renderResults() {
		if (this.state.tags.length) {
			return (
				this.state.tags.map(tag => 
					<TagItem key={tag.id} tag={tag} match={this.props.props.match} />
				)
			);
		}
	}

	renderLoadStatus() {
		if (this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<PulseLoader
								color={'#28A2FB'}
								loading={this.state.loading}
							/>
						</div>
					</div>
				</div>
			);
		}

		if (!this.state.loading && this.state.tags.length === 0) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);			
		}
	}



	render () {
		return (
			<Fragment>
				<section className="dashboard-results py-0">
				    <div className="dashboard-results-orgs container">
				        <div className="table-responsive">
							<table className="table dashboard-results-table">
								<thead>
									<tr>
										<th style={{borderTop: '0'}}>Name</th>
										<th style={{borderTop: '0'}}>Slug</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>Public</th>
										<th style={{borderTop: '0'}}>Created At</th>
									</tr>
								</thead>

								<tbody>
									{ this.renderResults() }
								</tbody>
							</table>
						</div>
					</div>
				</section>
			</Fragment>
		)
	}
}
	
export default TagList;