import React, { Component } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import FrontNav from '../../../components/FrontNav';
import TabNav from '../../../components/TabNav';
import ResourceSearchList from '../../../components/Activity/Resource/ResourceSearchList';

class ScreenResourcesSearchList extends Component {
	render () {
		return (
			<section>
				<Header />
				<FrontNav match={this.props.match} />
				<TabNav match={this.props.match} />
			    <ResourceSearchList id={this.props.match.params.id} props={this.props} />
			    <Footer />
			</section>
		)
	}
}

export default ScreenResourcesSearchList;