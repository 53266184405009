import React from 'react';
import { NavLink } from 'react-router-dom';

const AreaLinks = () => (

    <div className="container">
        <h2 className="text-primary mb-3">FOR TEACHERS AND SCHOOLS</h2>
        <div className="row">
            <div className="col-md-6 mb-5">
                <NavLink to="/resources">
                    <div className="area-link">
                        <div className="title">Resources for schools</div>
                        <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        <div className="description">Find resources for classroom teaching, home schooling and remote teaching</div>
                        <span className="icon">
                            <svg width="81px" height="91px" viewBox="0 0 81 91">
                                <g id="Resources-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M15,16 L15,21.142 L10.1666667,21.1428571 L10.1666667,82.8571429 L61.8333333,82.8571429 L61.833,75 L67,75 L67,84.1428571 C67,86.2057757 65.3711077,87.8916277 63.3243383,87.9949789 L63.125,88 L8.875,88 C6.73567708,88 5,86.2723214 5,84.1428571 L5,84.1428571 L5,19.8571429 C5,17.7276786 6.73567708,16 8.875,16 L8.875,16 L15,16 Z" id="Combined-Shape" fill="#6D6D6D"></path>
                                    <path d="M74.2552083,18.2678571 L61.6614583,5.73214286 C60.1679688,4.24553571 57.1809896,3 55.0416667,3 L18.875,3 C16.7356771,3 15,4.72767857 15,6.85714286 L15,71.1428571 C15,73.2723214 16.7356771,75 18.875,75 L73.125,75 C75.2643229,75 77,73.2723214 77,71.1428571 L77,24.8571429 C77,22.7276786 75.7486979,19.7544643 74.2552083,18.2678571 Z M56.3333333,8.46428571 C57.0195312,8.70535714 57.7057292,9.06696429 57.9882812,9.34821429 L70.6223958,21.9241071 C70.9049479,22.2053571 71.2682292,22.8883929 71.5104167,23.5714286 L56.3333333,23.5714286 L56.3333333,8.46428571 Z M71.8333333,69.8571429 L20.1666667,69.8571429 L20.1666667,8.14285714 L51.1666667,8.14285714 L51.1666667,24.8571429 C51.1666667,26.9866071 52.9023437,28.7142857 55.0416667,28.7142857 L71.8333333,28.7142857 L71.8333333,69.8571429 Z M30.5,35.1428571 L30.5,37.7142857 C30.5,38.4375 31.0651042,39 31.7916667,39 L60.2083333,39 C60.9348958,39 61.5,38.4375 61.5,37.7142857 L61.5,35.1428571 C61.5,34.4196429 60.9348958,33.8571429 60.2083333,33.8571429 L31.7916667,33.8571429 C31.0651042,33.8571429 30.5,34.4196429 30.5,35.1428571 Z M60.2083333,44.1428571 L31.7916667,44.1428571 C31.0651042,44.1428571 30.5,44.7053571 30.5,45.4285714 L30.5,48 C30.5,48.7232143 31.0651042,49.2857143 31.7916667,49.2857143 L60.2083333,49.2857143 C60.9348958,49.2857143 61.5,48.7232143 61.5,48 L61.5,45.4285714 C61.5,44.7053571 60.9348958,44.1428571 60.2083333,44.1428571 Z M60.2083333,54.4285714 L31.7916667,54.4285714 C31.0651042,54.4285714 30.5,54.9910714 30.5,55.7142857 L30.5,58.2857143 C30.5,59.0089286 31.0651042,59.5714286 31.7916667,59.5714286 L60.2083333,59.5714286 C60.9348958,59.5714286 61.5,59.0089286 61.5,58.2857143 L61.5,55.7142857 C61.5,54.9910714 60.9348958,54.4285714 60.2083333,54.4285714 Z" id="" fill="#6D6D6D"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                </NavLink>
            </div>

            <div className="col-md-6 mb-5">
                <NavLink to="/events">
                    <div className="area-link">
                        <div className="title">Events for schools</div>
                        <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        <div className="description">Find events for teachers and schools including workshops, performances and CPDs</div>
                        <span className="icon">
                            <svg width="76px" height="80px" viewBox="0 0 76 80">
                                <g id="Events-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M9.15384615,70.8571429 L9.15384615,29.7142857 L65.8461538,29.7142857 L65.8461538,70.8571429 L9.15384615,70.8571429 Z M24.6153846,22 C24.6153846,22.7232143 24.0516827,23.2857143 23.3269231,23.2857143 L20.75,23.2857143 C20.0252404,23.2857143 19.4615385,22.7232143 19.4615385,22 L19.4615385,10.4285714 C19.4615385,9.70535714 20.0252404,9.14285714 20.75,9.14285714 L23.3269231,9.14285714 C24.0516827,9.14285714 24.6153846,9.70535714 24.6153846,10.4285714 L24.6153846,22 Z M55.5384615,22 C55.5384615,22.7232143 54.9747596,23.2857143 54.25,23.2857143 L51.6730769,23.2857143 C50.9483173,23.2857143 50.3846154,22.7232143 50.3846154,22 L50.3846154,10.4285714 C50.3846154,9.70535714 50.9483173,9.14285714 51.6730769,9.14285714 L54.25,9.14285714 C54.9747596,9.14285714 55.5384615,9.70535714 55.5384615,10.4285714 L55.5384615,22 Z M71,19.4285714 C71,16.6160714 68.6646635,14.2857143 65.8461538,14.2857143 L60.6923077,14.2857143 L60.6923077,10.4285714 C60.6923077,6.89285714 57.7932692,4 54.25,4 L51.6730769,4 C48.1298077,4 45.2307692,6.89285714 45.2307692,10.4285714 L45.2307692,14.2857143 L29.7692308,14.2857143 L29.7692308,10.4285714 C29.7692308,6.89285714 26.8701923,4 23.3269231,4 L20.75,4 C17.2067308,4 14.3076923,6.89285714 14.3076923,10.4285714 L14.3076923,14.2857143 L9.15384615,14.2857143 C6.33533654,14.2857143 4,16.6160714 4,19.4285714 L4,70.8571429 C4,73.6696429 6.33533654,76 9.15384615,76 L65.8461538,76 C68.6646635,76 71,73.6696429 71,70.8571429 L71,19.4285714 Z" id="" fill="#6D6D6D"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                </NavLink>
            </div>

            <div className="col-md-6 mb-5">
                <NavLink to="/projects">
                    <div className="area-link">
                        <div className="title">Projects for schools</div>
                        <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        <div className="description">Find long term and ongoing projects for teachers and schools</div>
                        <span className="icon">
                            <svg width="91px" height="101px" viewBox="0 0 91 101">
                                <g id="Programmes-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M12,36 L12,51.5 L7.15384615,51.5005382 L7.15384615,92.8333931 L63.8461538,92.8333931 L63.846,86.949 L69,86.949 L69,92.8333931 C69,95.5864328 66.7828889,97.8796242 64.0619781,97.9954137 L63.8461538,98 L7.15384615,98 C4.33533654,98 2,95.6588813 2,92.8333931 L2,92.8333931 L2,41.1673245 C2,38.3418363 4.33533654,36.0007176 7.15384615,36.0007176 L7.15384615,36.0007176 L12,36 Z" id="Combined-Shape" fill="#6D6D6D"></path>
                                    <path d="M22,25 L22,40.5 L17.1538462,40.5005382 L17.1538462,81.8333931 L73.8461538,81.8333931 L73.846,75.949 L79,75.949 L79,81.8333931 C79,84.5864328 76.7828889,86.8796242 74.0619781,86.9954137 L73.8461538,87 L17.1538462,87 C14.3353365,87 12,84.6588813 12,81.8333931 L12,81.8333931 L12,30.1673245 C12,27.3418363 14.3353365,25.0007176 17.1538462,25.0007176 L17.1538462,25.0007176 L22,25 Z" id="Combined-Shape" fill="#6D6D6D"></path>
                                    <path d="M27.1538462,70.8571429 L27.1538462,29.7142857 L83.8461538,29.7142857 L83.8461538,70.8571429 L27.1538462,70.8571429 Z M42.6153846,22 C42.6153846,22.7232143 42.0516827,23.2857143 41.3269231,23.2857143 L38.75,23.2857143 C38.0252404,23.2857143 37.4615385,22.7232143 37.4615385,22 L37.4615385,10.4285714 C37.4615385,9.70535714 38.0252404,9.14285714 38.75,9.14285714 L41.3269231,9.14285714 C42.0516827,9.14285714 42.6153846,9.70535714 42.6153846,10.4285714 L42.6153846,22 Z M73.5384615,22 C73.5384615,22.7232143 72.9747596,23.2857143 72.25,23.2857143 L69.6730769,23.2857143 C68.9483173,23.2857143 68.3846154,22.7232143 68.3846154,22 L68.3846154,10.4285714 C68.3846154,9.70535714 68.9483173,9.14285714 69.6730769,9.14285714 L72.25,9.14285714 C72.9747596,9.14285714 73.5384615,9.70535714 73.5384615,10.4285714 L73.5384615,22 Z M89,19.4285714 C89,16.6160714 86.6646635,14.2857143 83.8461538,14.2857143 L78.6923077,14.2857143 L78.6923077,10.4285714 C78.6923077,6.89285714 75.7932692,4 72.25,4 L69.6730769,4 C66.1298077,4 63.2307692,6.89285714 63.2307692,10.4285714 L63.2307692,14.2857143 L47.7692308,14.2857143 L47.7692308,10.4285714 C47.7692308,6.89285714 44.8701923,4 41.3269231,4 L38.75,4 C35.2067308,4 32.3076923,6.89285714 32.3076923,10.4285714 L32.3076923,14.2857143 L27.1538462,14.2857143 C24.3353365,14.2857143 22,16.6160714 22,19.4285714 L22,70.8571429 C22,73.6696429 24.3353365,76 27.1538462,76 L83.8461538,76 C86.6646635,76 89,73.6696429 89,70.8571429 L89,19.4285714 Z" id="-copy-3" fill="#6D6D6D"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                </NavLink>
            </div>

            <div className="col-md-6 mb-5">
                <NavLink to="/organisations">
                    <div className="area-link">
                        <div className="title">Organisations that work with schools</div>
                        <span className="arrow">
                                <svg width="81px" height="76px" viewBox="0 0 81 76">
                                    <g id="Arrow-blue-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path d="M70.786882,46.2619287 C71.177786,45.3315251 71.0214244,44.3235879 70.3959781,43.5482516 L42.6417966,13.7753363 C42.1727119,13.3101345 41.4690848,13 40.7654576,13 C40.0618305,13 39.4363841,13.3101345 38.9672994,13.7753363 L11.6040219,43.5482516 C10.9785756,44.3235879 10.822214,45.3315251 11.213118,46.2619287 C11.6040219,47.1923323 12.4640106,47.7350678 13.4803609,47.7350678 L30.9928585,47.7350678 L30.9928585,61.5189237 C30.9928585,62.9145291 32.0873896,64 33.4946439,64 L48.5053561,64 C49.9126104,64 51.0071415,62.9145291 51.0071415,61.5189237 L51.0071415,47.7350678 L68.5196391,47.7350678 C69.5359894,47.7350678 70.3959781,47.1147987 70.786882,46.2619287 Z" id="-copy-2" fill="#4BA7FB" transform="translate(41.000000, 38.500000) rotate(20.000000) translate(-41.000000, -38.500000) "></path>
                                    </g>
                                </svg>
                            </span>
                        <div className="description">Search LookUp organisations for details of their schools offers.</div>
                        <span className="icon">
                            <svg width="70px" height="85px" viewBox="0 0 70 85">
                                <g id="Organisations-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M67,70.4103486 C67,64.2533462 57.7727273,61.3559334 50.0454545,60.0430432 C48.4545455,59.7714107 46.9545455,60.8126685 46.6818182,62.3971911 C46.4090909,63.9817138 47.4545455,65.4756923 49.0454545,65.7473247 C58.4090909,67.3771195 61.0454545,69.9123557 61.1818182,70.3650765 C60.7272727,71.9948712 52,76.2051743 35,76.2051743 C18,76.2051743 9.27272727,71.9948712 8.81818182,70.4556206 C8.95454545,69.9123557 11.5909091,67.3771195 20.9545455,65.7473247 C22.5454545,65.4756923 23.5909091,63.9817138 23.3181818,62.3971911 C23.0454545,60.8126685 21.5454545,59.7714107 19.9545455,60.0430432 C12.2272727,61.3559334 3,64.2533462 3,70.4103486 C3,78.3782339 19.5909091,82 35,82 C50.4090909,82 67,78.3782339 67,70.4103486 Z M50,28.875 C50,25.6162109 47.4171875,23 44.2,23 L26.8,23 C23.5828125,23 21,25.6162109 21,28.875 L21,46.5 C21,48.1064453 22.3140625,49.4375 23.9,49.4375 L26.8,49.4375 L26.8,67.0625 C26.8,68.6689453 28.1140625,70 29.7,70 L41.3,70 C42.8859375,70 44.2,68.6689453 44.2,67.0625 L44.2,49.4375 L47.1,49.4375 C48.6859375,49.4375 50,48.1064453 50,46.5 L50,28.875 Z M45,12 C45,6.46428571 40.5357143,2 35,2 C29.4642857,2 25,6.46428571 25,12 C25,17.5357143 29.4642857,22 35,22 C40.5357143,22 45,17.5357143 45,12 Z" id="-copy" fill="#6D6D6D"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                </NavLink>
            </div>
        </div>
    </div>
);


export default AreaLinks;