import React, { Component, Fragment } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Button from '../../components/Form/Button';
import axios from 'axios';
import { toast } from 'react-toastify';

const RESET_LINK = process.env.REACT_APP_API_URL+'api/password/create';
class ForgotPassword extends Component {
	state = {
		email: '',
		error: '',
		success: ''
	};

	handleSubmit = (e) => {
		e.preventDefault();

		axios.post(RESET_LINK, {
			email: this.state.email
		})
		.then(response => {
			this.setState({
				success: response.data.message
			});
		})
		.catch(errorMsg => {
			console.log(errorMsg);
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					this.setState({
						error: message
					});
				});				
			} else {
				console.log(eData);

				this.setState({
					error: eData.message
				});
			}
		});
	}

	renderSuccess() {
		if (this.state.success !== '') {
			return (
				<div className="form-group row">
					<div className="col offset-md-4">
						<p className="text-success mb-0">{this.state.success}</p>
					</div>
				</div>
			);
		}
	}

	renderError() {
		if (this.state.error !== '') {
			return (
				<div className="form-group row">
					<div className="col offset-md-4">
						<p className="text-danger mb-0">{this.state.error}</p>
					</div>
				</div>
			);
		}
	}

	render() {
		return (
			<Fragment>
				<Header />

				<section className="container py-9">
					<div className="row justify-content-center align-items-center py-9">
						<div className="col">
					        <form method="POST" onSubmit={this.handleSubmit}>
					        	{this.renderSuccess()}
					        	{this.renderError()}

					        	<div className="form-group row">
					        		<div className="col offset-md-4">
					        			<p className="mb-0 font-weight-bold text-primary">Forgot password</p>
					        		</div>
					        	</div>

						        <div className="form-group row">
						            <label 
						            	htmlFor="email" 
						            	className="col-sm-4 col-form-label text-md-right">
						            	Email Address
						            </label>

						            <div className="col-md-4">
						                <input 
						                	id="email"
						                	type="email" 
						                	className="form-control border" 
						                	name="email"
						                	value={this.state.email}
						                	onChange={(e) => this.setState({ email: e.target.value }) }
						                	placeholder={'Enter your email address'}
						                	required={true}
						                	autoFocus={true}
						                />
						            </div>
						        </div>

					            <div className="form-group row mb-0">
					                <div className="col-md-8 offset-md-4">
					                	<Button
					                		title={'Submit'}
					                		type={'submit'}
					                		className={'btn btn-primary btn-lg'}
					                	/>
					                </div>
					            </div>
					        </form>
						</div>
					</div>
				</section>
				
				<Footer />
			</Fragment>
		);
	}
}

export default ForgotPassword;