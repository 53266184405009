import React, { Component, Fragment } from 'react';
import axios from 'axios';
import history from '../../../history';
import { toast } from 'react-toastify';
import moment from 'moment';
import Keystages from '../../Form/Keystages';
import { 
	orgPutToApi,
	isNumericBoolean
} from '../../Utilities/adapter';

import ShowMoreText from 'react-show-more-text';

const ROOT_URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_API_URL+'api';
const API_URL = process.env.REACT_APP_API_URL+'api/tags';

const INITIAL_STATE = {
	name: '',
	public: false,
	accessToken: '',
};

let URL = '';

class TagEdit extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.setState({ accessToken: localStorage.getItem('accessToken') });
		this.init();
	}

	init() {
		URL = API_URL+'/'+this.props.id;
		axios.get(API_URL+'/'+this.props.id)
			.then(response => {
				let tag = response.data.data;

				this.setState({
							name: tag.name,
							public: tag.public,
					});
		
			})
			.catch(e => {
				//history.push('/dashboard');
			});
	}


	handleSubmit = (e) => {
		e.preventDefault();

		const formData = {
			name: this.state.name,
			public: this.state.public,
			_method: 'PUT'
		}

		axios.post(URL, formData, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					onClose: () => window.location.reload()
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				console.log(eData);
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	};

	onDelete = () => {
		axios.delete(URL, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken,
				'Content-Type': 'application/x-www-form-urlencoded'				
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					onClose: () => history.push('/admin/tags')
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				console.log(eData);
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	}

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					[fieldName] : numericBoolean 
				})
			);
		} else {
			this.setState(
				prevState => ({
					[fieldName] : fieldValue 
				})
			);
		}
	}






	render () {
		return (
			<form onSubmit={this.handleSubmit}>
				

				<div className="form-group row">
					<label 
						className="form-label text-primary col-12 col-md-3">Tag: </label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.name} 
							type="text" 
							name="name" 
							placeholder="Tag" 
							className="form-control border" 
							required />
					</div>
				</div>

				<div className="form-group row">
					<label 
						htmlFor="isPublic" 
						className="col-12 col-md-3 text-primary">Public</label>

					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.public}
							checked={this.state.public}
							id="isPublic" 
							type="checkbox" 
							name="public" 
							className="border mr-1" 
						/>
					</div>
				</div>

     

				<div className="form-group row mt-5">
					<div className="col-12 col-lg-3 offset-md-3">
						<button type="submit" className="btn btn-primary btn-lg">Update</button>
						<button 
							className="btn text-primary"
							type="button" 
							onClick={
								(e) => {
									e.preventDefault()
									history.goBack();
								}
							}>Cancel</button>
					</div>
				</div>
			</form>
		)
	}
}

export default TagEdit;