import React, { Component, Fragment } from "react";
import axios from "axios";
import history from "../../../history";
import { toast } from "react-toastify";
import moment from "moment";
import ReactTags from "react-tag-autocomplete";
import { isNumericBoolean } from "../../Utilities/adapter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import '../../../sass/modules/date-picker.scss';

const BASE_URL = process.env.REACT_APP_API_URL + "api";
const ADMIN_URL = process.env.REACT_APP_API_URL + "api/activities/admin";
const ORG_URL = process.env.REACT_APP_API_URL + "api/organisations/filter";

class ActivityCreateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity: {
        type: this.props.activity_type,
        event_type: 1,
        resource_type: 1,
        title: "",
        organisation_title: "",
        organisation_id: null,
        info: "",
        info_link: "",
        has_multi_dates: 0,
        dates: [],
        start_date: moment(new Date(new Date().setHours(0, 0))).format(),
        end_date: moment(new Date(new Date().setHours(0, 0))).format(),
        support_send: 0,
        delivered_in_school: 0,
        webinar: 0,
        artsmark_partner: 0,
        arts_award_supporter: 0,
        status: "active",
        artforms: [],
		keystages: [],
		tags: [],
        venue_name: "",
        first_name: "",
        last_name: "",
        email: "",
        address_1: "",
        address_2: "",
        borough: "",
        postcode: "",
        free: 0,
        cost: "",
      },
      organisation_search: "",
      selected_organisation: "",
      organisations: [],
	  artforms: [],
	  suggestions: [],
      startDate: new Date(new Date().setHours(0, 0)),
      endDate: new Date(new Date().setHours(0, 0)),
      calendarDate: new Date(new Date().setHours(0, 0)),
      loading: false,
    };

    this.reactTags = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getArtformTypes();
    this.getAvailableTags();
  }

  /* tag */
  onTagDelete(i) {
    const tags = this.state.activity.tags.slice(0);
    tags.splice(i, 1);
	this.setState((prevState) => ({
		activity: {
		  ...prevState.activity,
		  tags: tags
		}
	  }));
  }

  onTagAddition(tag) {
    const tags = [].concat(this.state.activity.tags, tag);
    this.setState((prevState) => ({
		activity: {
		  ...prevState.activity,
		  tags: tags
		}
	  }));
  }

  onSearchOrg = (e) => {
    let fieldValue = e.target.value;

    this.setState(
      (prevState) => ({
        ...prevState,
        activity: {
          ...prevState.activity,
          organisation_title: fieldValue,
        },
        organisation_search: fieldValue,
      }),
      () => this.getOrganisation()
    );
  };

  getOrganisation = () => {
    axios
      .post(ORG_URL, {
        title: this.state.organisation_search,
      })
      .then((response) => {
        let organisations = response.data.data;

        this.setState((prevState) => ({
          ...prevState,
          organisations: organisations,
        }));
      });

    if (this.state.organisation_search === "") {
      this.setState((prevState) => ({
        ...prevState,
        activity: {
          ...prevState.activity,
          organisation_id: null,
        },
        selected_organisation: null,
      }));
    }
  };

  onSelectExistingOrganisation = (e) => {
    const orgId = parseInt(e.target.value);
    const title = e.target.options[e.target.selectedIndex].dataset.title;

    this.setState((prevState) => ({
      activity: {
        ...prevState.activity,
        organisation_id: orgId,
        organisation_title: title,
      },
      organisation_search: title,
      selected_organisation: orgId,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

	const accessToken = localStorage.getItem("accessToken");
	
	console.log(this.state.activity);

    axios
      .post(ADMIN_URL, this.state.activity, {
        headers: { Authorization: "Bearer " + accessToken },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            onClose: () => history.push("/dashboard"),
          });
        }
      })
      .catch((errorMsg) => {
        let eData = errorMsg.response.data;
        let errM = errorMsg.response.data.errors;

        if (errorMsg.response.data.hasOwnProperty("errors")) {
          let errors = Object.entries(errM);

          if (errors.length) {
            errors.map(([field, index]) => {
              let message = "Error: " + index;

              toast.error(message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
              });
            });
          }
        } else {
          console.log(eData);
          toast.error(eData.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          });
        }

        this.setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
      });
  };

  handleProjectDateType = () => {
    let has_multi_dates = this.state.activity.has_multi_dates;

    this.setState((prevState) => ({
      ...prevState,
      activity: {
        ...prevState.activity,
        has_multi_dates: has_multi_dates === 0 ? 1 : 0,
      },
    }));
  };

  handleRemoveProjectDate = (date) => {
    let dates = this.state.activity.dates;

    dates.map((prevDate) => {
      if (moment(date).isSame(moment(prevDate))) {
        console.log(date);
        const index = dates.indexOf(prevDate);
        if (index > -1) {
          dates.splice(index, 1);
        }
      }
    });

    this.setState((prevState) => ({
      ...prevState,
      activity: {
        ...prevState.activity,
        dates: dates,
      },
    }));
  };

  handleProjectDates = (date) => {
    let dates = this.state.activity.dates;

    dates.map((prevDate) => {
      if (
        moment(date).format("MM-DD-YYYY") ===
        moment(prevDate).format("MM-DD-YYYY")
      ) {
        console.log(date);
        const index = dates.indexOf(prevDate);
        if (index > -1) {
          dates.splice(index, 1);
        }
      }
    });

    dates.push(new Date(date));

    this.setState((prevState) => ({
      ...prevState,
      activity: {
        ...prevState.activity,
        dates: dates,
      },
      calendarDate: date,
    }));
  };

  handleStartDate = (date) => {
    let activity_type = this.state.activity.type;
    let start = moment(date, "DD-MM-YYYY");
    let end = moment(this.state.endDate, "DD-MM-YYYY");
    let difference = end.diff(start, "days");

    if (Math.abs(difference) > 14 && activity_type === "event") {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          start_date: moment(date).format(),
          end_date: null,
        },
        startDate: date,
        endDate: null,
        event_date_selection_error:
          "Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.",
      }));
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          startDate: date,
        }),
        () => {
          if (
            moment(this.state.startDate).isSameOrAfter(this.state.endDate) ||
            this.state.endDate === ""
          ) {
            this.setState((prevState) => ({
              activity: {
                ...prevState.activity,
                start_date: moment(date).format(),
                end_date: moment(date).format(),
              },
              startDate: date,
              endDate: date,
              event_date_selection_error: "",
            }));
          } else {
            this.setState((prevState) => ({
              activity: {
                ...prevState.activity,
                start_date: moment(date).format(),
              },
              startDate: date,
              event_date_selection_error: "",
            }));
          }
        }
      );
    }
  };

  handleEndDate = (date) => {
    let activity_type = this.state.activity.type;
    let start = moment(this.state.startDate, "DD-MM-YYYY");
    let end = moment(date, "DD-MM-YYYY");
    let difference = end.diff(start, "days");

    if (Math.abs(difference) > 14 && activity_type === "event") {
      this.setState((prevState) => ({
        ...prevState,
        event_date_selection_error:
          "Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.",
      }));
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          endDate: date,
        }),
        () => {
          if (moment(this.state.endDate).isBefore(this.state.startDate)) {
            this.setState((prevState) => ({
              activity: {
                ...prevState.activity,
                start_date: moment(date).format(),
                end_date: moment(date).format(),
              },
              startDate: date,
              endDate: date,
              event_date_selection_error: "",
            }));
          } else {
            this.setState((prevState) => ({
              activity: {
                ...prevState.activity,
                end_date: moment(date).format(),
              },
              endDate: date,
              event_date_selection_error: "",
            }));
          }
        }
      );
    }
  };

  handleDate = (e) => {
    let fieldValue = e.target.value;
    let fieldName = e.target.name;

    if (fieldName === "visible_from") {
      if (
        moment(this.state.activity.visible_from).isSameOrAfter(
          this.state.activity.visible_until
        ) ||
        this.state.activity.visible_until === ""
      ) {
        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            visible_from: fieldValue,
            visible_until: fieldValue,
          },
        }));
      } else {
        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            visible_from: fieldValue,
          },
        }));
      }
    } else if (fieldName === "visible_until") {
      if (
        moment(this.state.activity.visible_until).isBefore(
          this.state.activity.visible_from
        )
      ) {
        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            visible_from: fieldValue,
            visible_until: fieldValue,
          },
        }));
      } else {
        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            visible_until: fieldValue,
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          [fieldName]: fieldValue,
        },
      }));
    }
  };

  onInputChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;

    if (e.target.type === "checkbox") {
      let boolean = e.target.checked;
      let numericBoolean = isNumericBoolean(boolean);

      this.setState((prevState) => ({
        activity: { ...prevState.activity, [fieldName]: numericBoolean },
      }));
    } else {
      this.setState((prevState) => ({
        activity: { ...prevState.activity, [fieldName]: fieldValue },
      }));
    }
  };

  getArtformTypes = () => {
    axios.get(BASE_URL + "/artform-types").then((response) => {
      this.setState({
        artforms: response.data,
      });
    });
  };

  getAvailableTags = () => {
    axios.get(BASE_URL + "/get-available-tags").then((response) => {
      this.setState({
        suggestions: response.data.data,
      });
    });
  };

  selectArtformCheckboxes = (e) => {
    let fieldValue = parseInt(e.target.value);

    if (e.target.checked) {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          artforms: [...prevState.activity.artforms, fieldValue],
        },
      }));
    } else {
      let artforms = [...this.state.activity.artforms];
      let index = artforms.indexOf(fieldValue);

      if (index !== -1) {
        artforms.splice(index, 1);

        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            artforms: artforms,
          },
        }));
      }
    }
  };

  selectKeystageCheckboxes = (e) => {
    let fieldValue = parseInt(e.target.value);

    if (e.target.checked) {
      this.setState((prevState) => ({
        activity: {
          ...prevState.activity,
          keystages: [...prevState.activity.keystages, fieldValue],
        },
      }));
    } else {
      let keystages = [...this.state.activity.keystages];
      let index = keystages.indexOf(fieldValue);

      if (index !== -1) {
        keystages.splice(index, 1);

        this.setState((prevState) => ({
          activity: {
            ...prevState.activity,
            keystages: keystages,
          },
        }));
      }
    }
  };

  renderEventDates() {
    const startDate = moment(this.state.startDate);

    return (
      <div className="form-group row">
        <label htmlFor="eventDates" className="col-12 col-md-3 text-primary">
          Dates
        </label>
        <div className="col-12 col-md-8 col-lg-6">
          <p className="mb-0 text-primary">
            Date
            {this.state.startDate
              ? ": " + startDate.format("ddd, D MMM YYYY, h:mm A")
              : ""}
          </p>
          <DatePicker
            inline
            selectsStart
            showTimeSelect
            selected={this.state.startDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleStartDate}
            name="start_date"
            timeFormat="HH:mm"
            timeIntervals={5}
            className="form-control border mb-2"
            placeholderText="Event date *"
            required
          />

          <p className="mb-0 text-primary">
            End date
            {this.state.endDate
              ? ": " +
                moment(this.state.endDate).format("ddd, D MMM YYYY, h:mm A")
              : ""}
          </p>
          <DatePicker
            inline
            selectsEnd
            showTimeSelect
            selected={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleEndDate}
            name="end_date"
            timeFormat="HH:mm"
            timeIntervals={5}
            className="form-control border mb-2"
            placeholderText="End date *"
            required
          />
          <div className="text-danger">
            {this.state.event_date_selection_error}
          </div>
        </div>
      </div>
    );
  }

  renderProjectPeriod() {
    const startDate = moment(this.state.startDate);

    const activity_type = this.state.activity.type;

    if (activity_type === "project") {
      return (
        <div className="form-group row">
          <label
            htmlFor="projectDates"
            className="col-12 col-md-3 text-primary"
          >
            Dates
          </label>
          <div className="col-12 col-md-8 col-lg-6">
            <p className="mb-0">
              Start Date *<br />
              {this.state.startDate
                ? startDate.format("ddd, D MMM YYYY, h:mm A")
                : ""}
            </p>

            <DatePicker
              inline
              selectsStart
              showTimeSelect
              selected={this.state.startDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleStartDate}
              name="start_date"
              timeFormat="HH:mm"
              timeIntervals={5}
              className="form-control border mb-2"
              placeholderText="Date *"
              required
            />
            <p className="mb-0 mt-2">
              End date *<br />
              {this.state.endDate
                ? moment(this.state.endDate).format("ddd, D MMM YYYY, h:mm A")
                : ""}
            </p>
            <DatePicker
              inline
              selectsEnd
              showTimeSelect
              selected={this.state.endDate}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleEndDate}
              name="end_date"
              timeFormat="HH:mm"
              timeIntervals={5}
              className="form-control border mb-2"
              placeholderText="End date *"
              required
            />

            <button
              type="button"
              onClick={this.handleProjectDateType}
              className="btn btn-outline-primary btn-block"
            >
              Add multiple dates instead
            </button>
          </div>
        </div>
      );
    }

    return false;
  }

  renderProjectDates() {
    let dates = [];

    this.state.activity.dates.map((date, index) => {
      dates.push(
        <li key={index} className="mb-2">
          <div className="row">
            <div className="col-md-7">
              {moment(date).format("dddd, D MMM YYYY, h:mm A")}
            </div>
            <div className="col-md-5">
              <button
                type="button"
                className="btn btn-sm btn-outline-primary float-left"
                date={date}
                onClick={(e) => this.handleRemoveProjectDate(date, e)}
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          </div>
        </li>
      );
    });

    const activity_type = this.state.activity.type;

    if (activity_type === "project") {
      return (
        <div className="form-group row">
          <label
            htmlFor="projectDates"
            className="col-12 col-md-3 text-primary"
          >
            Dates
          </label>
          <div className="col-12 col-md-8 col-lg-6">
            <ul className="list-unstyled m-0 p-0">{dates}</ul>

            <DatePicker
              inline
              selected={this.state.calendarDate}
              highlightDates={[...this.state.activity.dates]}
              showTimeSelect
              onChange={this.handleProjectDates}
              timeFormat="HH:mm"
              timeIntervals={60}
              minTime={new Date(new Date().setHours(8, 0))}
              maxTime={new Date(new Date().setHours(23, 0))}
            />
            <button
              type="button"
              onClick={this.handleProjectDateType}
              className="btn btn-outline-primary btn-block"
            >
              Add a period instead
            </button>
          </div>
        </div>
      );
    }
  }

  renderActivityCost() {
    if (this.state.activity.cost !== "") {
      return (
        <Fragment>
          <div className="form-group row">
            <label htmlFor="eventCost" className="col-12 col-md-3 text-primary">
              Cost
            </label>
            <div className="col-12 col-md-8 col-lg-6">
              <input
                onChange={this.onInputChange}
                value={this.state.activity.cost}
                id="eventCost"
                type="text"
                name="cost"
                placeholder="Cost"
                className="form-control border"
              />

              <div className="mt-2">
                <input
                  onChange={this.onInputChange}
                  value={this.state.activity.free}
                  id="freeEvent"
                  type="checkbox"
                  name="free"
                  className="form-checkbox-disabled"
                  disabled
                />
                <label htmlFor="freeEvent">
                  <strike>Free </strike>
                </label>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (this.state.activity.free) {
      return (
        <Fragment>
          <div className="form-group row">
            <label htmlFor="eventCost" className="col-12 col-md-3 text-primary">
              Cost
            </label>
            <div className="col-12 col-md-8 col-lg-6">
              <input
                onChange={this.onInputChange}
                value={this.state.activity.cost}
                id="eventCost"
                type="text"
                name="cost"
                placeholder="Cost"
                className="form-control border"
                disabled
              />

              <div className="mt-2">
                <input
                  onChange={this.onInputChange}
                  value={this.state.activity.free}
                  id="freeEvent"
                  type="checkbox"
                  name="free"
                  className="border mr-1"
                />
                <label htmlFor="freeEvent">Free </label>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div className="form-group row">
            <label htmlFor="eventCost" className="col-12 col-md-3 text-primary">
              Cost
            </label>
            <div className="col-12 col-md-8 col-lg-6">
              <input
                onChange={this.onInputChange}
                value={this.state.activity.cost}
                id="eventCost"
                type="text"
                name="cost"
                placeholder="Cost"
                className="form-control border"
              />

              <div className="mt-2">
                <input
                  onChange={this.onInputChange}
                  value={this.state.activity.free}
                  id="freeEvent"
                  type="checkbox"
                  name="free"
                  className="border mr-1"
                />
                <label htmlFor="freeEvent" className="text-primary">
                  Free{" "}
                </label>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }

  renderEventAddress() {
    return (
      <div className="form-group row">
        <p className="col-12 col-md-3 text-primary">Address</p>

        <div className="col-12 col-md-8 col-lg-6">
          <input
            onChange={this.onInputChange}
            value={this.state.activity.venue_name}
            type="text"
            name="venue_name"
            className="form-control border mb-1"
            placeholder="Venue name"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.address_1}
            type="text"
            name="address_1"
            className="form-control border mb-1"
            placeholder="Address 1"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.address_2}
            type="text"
            name="address_2"
            className="form-control border mb-1"
            placeholder="Address 2"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.borough}
            type="text"
            name="borough"
            className="form-control border mb-1"
            placeholder="Borough"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.postcode}
            type="text"
            name="postcode"
            className="form-control border"
            placeholder="Postcode"
          />
        </div>
      </div>
    );
  }

  renderProjectAddress() {
    return (
      <div className="form-group row">
        <p className="col-12 col-md-3 text-primary">Address</p>

        <div className="col-12 col-md-8 col-lg-6">
          <input
            onChange={this.onInputChange}
            value={this.state.activity.venue_name}
            type="text"
            name="venue_name"
            className="form-control border mb-1"
            placeholder="Venue name"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.address_1}
            type="text"
            name="address_1"
            className="form-control border mb-1"
            placeholder="Address 1"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.address_2}
            type="text"
            name="address_2"
            className="form-control border mb-1"
            placeholder="Address 2"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.borough}
            type="text"
            name="borough"
            className="form-control border mb-1"
            placeholder="Borough"
          />
          <input
            onChange={this.onInputChange}
            value={this.state.activity.postcode}
            type="text"
            name="postcode"
            className="form-control border"
            placeholder="Postcode"
          />
        </div>
      </div>
    );
  }

  renderEventTypes() {
    return (
      <div className="form-group row">
        <label className="col-12 col-md-3 text-primary mb-0">Type *</label>

        <div className="col-12 col-md-8 col-lg-6">
          <p className="mb-0">
            Please select a category to help users find your event
          </p>
          <select
            onChange={this.onInputChange}
            className="form-control tab-filter-select border"
            value={this.state.activity.event_type}
            name="event_type"
          >
            <option value={1}>CPD</option>
						<option value={2}>Performance</option>
						<option value={3}>Workshop</option>
						<option value={4}>Networking</option>
						<option value={5}>Other</option>
          </select>
        </div>
      </div>
    );
  }

  renderResourceTypes() {
    return (
      <div className="form-group row">
        <label className="col-12 col-md-3 text-primary mb-0">Type *</label>

        <div className="col-12 col-md-8 col-lg-6">
          <p className="mb-0">Please select a category</p>
          <select
            onChange={this.onInputChange}
            className="form-control tab-filter-select border"
            value={this.state.activity.resource_type}
            name="resource_type"
          >
            <option value={1}>Activities and worksheets</option>
            <option value={2}>Audio resources</option>
            <option value={3}>Lesson plans</option>
            <option value={4}>Online platforms</option>
            <option value={5}>Teacher CPD</option>
            <option value={6}>Video resources</option>
            <option value={7}>Virtual tours</option>
          </select>
        </div>
      </div>
    );
  }

  renderDeliveredInSchoolCheckbox() {
    let activity_type = this.state.activity.type;

    if (activity_type === "event" || activity_type === "project") {
      return (
        <div className="form-group row my-4">
          <label
            htmlFor="suitableEvent"
            className="col-12 col-md-3 text-primary"
          >
            Delivered in your school
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.delivered_in_school}
              id="suitableEvent"
              type="checkbox"
              name="delivered_in_school"
              className="border mr-1"
            />
          </div>
        </div>
      );
    }
  }

  renderWebinarCheckbox() {
    let activity_type = this.state.activity.type;

    if (activity_type === "event" || activity_type === "project") {
      return (
        <div className="form-group row my-4">
          <label
            htmlFor="suitableEvent"
            className="col-12 col-md-3 text-primary"
          >
            Webinar{" "}
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.webinar}
              id="suitableEvent"
              type="checkbox"
              name="webinar"
              className="border mr-1"
            />
          </div>
        </div>
      );
    }
  }

  renderOrgSearch() {
    const activity = this.state.activity;

    return (
      <div className="form-group row">
        <div className="col-12 col-md-3"></div>
        <div className="col-12 col-md-8 col-lg-6">
          <label htmlFor="orgIfName" className="text-primary">
            If not what is the name of your organisation?
          </label>

          <input
            id="orgIfName"
            onChange={this.onInputChange}
            value={
              activity.organisation_search
                ? activity.organisation_search
                : activity.organisation_title
            }
            type="text"
            name="organisation_title"
            placeholder="Organisation name"
            className="form-control border"
          />
        </div>
      </div>
    );
  }

  renderOrgResults() {
    if (this.state.organisations.length) {
      return (
        <div className="form-group row">
          <div className="col-12 col-md-3"></div>
          <div className="col-12 col-md-8 col-lg-6">
            <label htmlFor="orgResults" className="text-primary">
              Organisations
            </label>
            <select
              onChange={this.onSelectExistingOrganisation}
              name="orgResults"
              id="orgResults"
              multiple
              className="form-control border"
            >
              {this.state.organisations.map((org) => (
                <option key={org.id} value={org.id} data-title={org.title}>
                  {org.title} -{" "}
                  {org.contacts.postcode
                    ? org.contacts.postcode
                    : "Activity Only"}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    }
  }

  renderArtformSelection() {
    return this.state.artforms.map((art) => (
      <div
        key={art.id}
        className="form-group"
        style={{ display: "inline-block", width: "50%" }}
      >
        <input
          id={art.id}
          onChange={this.selectArtformCheckboxes}
          type="checkbox"
          name="artforms"
          className="border mr-1"
          value={art.id}
        />
        <label htmlFor={art.id}>{art.name}</label>
      </div>
    ));
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group row">
          <div className="col-12 col-md-3 text-primary">
            <p>Name of organisation *</p>
          </div>

          <div className="col-12 col-md-8 col-lg-6">
            <label htmlFor="checkOrgName" className="text-primary">
              If you have an organisation profile on LookUp please find it here
            </label>
            <input
              onChange={this.onSearchOrg}
              value={this.state.organisation_search}
              id="checkOrgName"
              type="text"
              name="organisation_search"
              placeholder="Type here to find organisation"
              className="form-control border"
            />
          </div>
        </div>

        {this.renderOrgResults()}
        {this.renderOrgSearch()}

        <div className="form-group row">
          <label htmlFor="eventTitle" className="col-12 col-md-3 text-primary">
            Title
          </label>
          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.title}
              id="eventTitle"
              type="text"
              name="title"
              placeholder="Title*"
              className="form-control border"
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="eventInfo" className="col-12 col-md-3 text-primary">
            Information
          </label>
          <div className="col-12 col-md-8 col-lg-6">
            <textarea
              onChange={this.onInputChange}
              value={this.state.activity.info}
              id="eventInfo"
              className="form-control border"
              name="info"
              maxLength="350"
              placeholder="(Description 350 characters max)*"
              rows="8"
              required
            ></textarea>
          </div>
        </div>

        {this.state.activity.type === "event" ? this.renderEventDates() : ""}

        {this.state.activity.has_multi_dates === 0
          ? this.renderProjectPeriod()
          : this.renderProjectDates()}

        {this.renderActivityCost()}

        <div className="form-group row">
          <label htmlFor="moreInfo" className="col-12 col-md-3 text-primary">
            More information
          </label>
          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.info_link}
              id="moreInfo"
              type="url"
              name="info_link"
              className="form-control border"
              placeholder="Add link for more event information or booking *"
            />
          </div>
        </div>

        {this.state.activity.type === "event" ? this.renderEventAddress() : ""}
        {this.state.activity.type === "project"
          ? this.renderProjectAddress()
          : ""}

        {this.state.activity.type === "event" ? this.renderEventTypes() : ""}
        {this.state.activity.type === "resource"
          ? this.renderResourceTypes()
          : ""}

        <div className="form-group row">
          <p className="col-12 col-md-3 text-primary mb-0">Optional criteria</p>
        </div>

        <div className="form-group row">
          <p className="col-12 col-md-3 mb-0 text-primary">Artforms</p>
          <div className="col-12 col-md-8 col-lg-6">
            {this.renderArtformSelection()}
          </div>
        </div>

        <div className="form-group row">
          <label className="text-primary col-12 col-md-3">Key stage</label>

          <div className="col-12 col-md-8 col-lg-6">
		  <input
              onChange={this.selectKeystageCheckboxes}
              id="key0"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={0}
            />
            <label htmlFor="key0" className="mr-3">
              EY
            </label>

            <input
              onChange={this.selectKeystageCheckboxes}
              id="key1"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={1}
            />
            <label htmlFor="key1" className="mr-3">
              1
            </label>

            <input
              onChange={this.selectKeystageCheckboxes}
              id="key2"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={2}
            />
            <label htmlFor="key2" className="mr-3">
              2
            </label>

            <input
              onChange={this.selectKeystageCheckboxes}
              id="key3"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={3}
            />
            <label htmlFor="key3" className="mr-3">
              3
            </label>

            <input
              onChange={this.selectKeystageCheckboxes}
              id="key4"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={4}
            />
            <label htmlFor="key4" className="mr-3">
              4
            </label>

            <input
              onChange={this.selectKeystageCheckboxes}
              id="key5"
              className="border mr-1"
              type="checkbox"
              name="keystages"
              value={5}
            />
            <label htmlFor="key5" className="mr-3">
              5
            </label>
          </div>
        </div>

        <div className="form-group row my-4">
          <label
            htmlFor="suitableEvent"
            className="col-12 col-md-3 text-primary"
          >
            Is your offer designed / adaptable for disabled students?{" "}
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.support_send}
              id="suitableEvent"
              type="checkbox"
              name="support_send"
              className="border mr-1"
            />
          </div>
        </div>

        {this.renderDeliveredInSchoolCheckbox()}

        {this.renderWebinarCheckbox()}

        <div className="form-group row my-4">
          <label
            htmlFor="suitableEvent"
            className="col-12 col-md-3 text-primary"
          >
            Tags
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <ReactTags
              tags={this.state.activity.tags}
              suggestions={this.state.suggestions}
              onDelete={this.onTagDelete.bind(this)}
              onAddition={this.onTagAddition.bind(this)}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-12 col-md-3 mb-0 text-primary">
            Contact first name
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.first_name}
              type="text"
              name="first_name"
              className="form-control border mb-1"
              placeholder="First name*"
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-12 col-md-3 mb-0 text-primary">
            Contact last name
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.last_name}
              type="text"
              name="last_name"
              className="form-control border mb-1"
              placeholder="Last name*"
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-12 col-md-3 mb-0 text-primary">
            Contact Email
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <input
              onChange={this.onInputChange}
              value={this.state.activity.email}
              type="email"
              name="email"
              className="form-control border mb-1"
              placeholder="Email address*"
              required
            />
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="status" className="text-primary col-12 col-md-3">
            Status
          </label>

          <div className="col-12 col-md-8 col-lg-6">
            <select
              id="status"
              onChange={this.onInputChange}
              value={this.state.activity.status}
              name="status"
              className="form-control tab-filter-select border"
            >
              <option value="active">Active</option>
              <option value="rejected">Rejected</option>
              <option value="completed">Completed</option>
            </select>
          </div>
        </div>

        <div className="form-group row mt-4">
          <div className="col-12 col-lg-3 offset-md-3">
            <button
              type="submit"
              className="btn btn-primary btn-lg"
              disabled={this.state.loading ? true : false}
            >
              {" "}
              {this.state.loading ? (
                <span className="fa fa-spinner fa-spin"></span>
              ) : (
                ""
              )}{" "}
              Submit
            </button>
            <button
              className="btn text-primary"
              onClick={() => history.goBack()}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default ActivityCreateForm;
