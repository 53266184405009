import React, { Component } from 'react';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import OrgCreateForm from '../../../components/Auth/Organisation/OrgCreate';
import requireAuth from '../../../components/Auth/requireAuth';

class ScreenAdminCreateNewOrganisation extends Component {
	render () {
		return (
			<section>
				<Header />

				<section className="bg-primary">
					<section className="container py-3">
						<div className="row">
							<div className="col d-flex justify-content-between">
								<h2 className="mb-0 text-light text-uppercase">Organisation</h2>
							</div>
						</div>
					</section>
				</section>

				<section className="container py-5">
					<OrgCreateForm />
				</section>

				<Footer />
			</section>
		)
	}
}

export default requireAuth(ScreenAdminCreateNewOrganisation);