import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from 'axios';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL + 'api';

class ContactModal extends Component {
    state = {
        name: '',
        email: '',
        message: '',
        submitted: false,
        modal: false,
        errors: [],
        button: 'contact us'
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.setState({
            submitted: true
        });

        axios.post(API_URL + '/contact', this.state)
            .then(response => {
                if (response.status === 200) {
                    toast.success('Thank you. Contact form submitted successfully.', {
                        position: toast.POSITION.TOP_RIGHT,
                        onClose: () => window.location.reload()
                    });

                }
            })
            .catch(errorMsg => {
                let eData = errorMsg.response.data;
                let errM = errorMsg.response.data.errors;

                if (errorMsg.response.data.hasOwnProperty('errors')) {

                    let errors = Object.entries(errM);

                    let validation_errors = [];
                    if (errors.length) {
                        errors.map(([field, error]) => {
                            validation_errors[field] = error;

                            // toast.error(message, {
                            // 	position: toast.POSITION.TOP_CENTER,
                            // 	autoClose: false
                            // });
                        });
                    }

                    this.setState({
                        errors: validation_errors
                    });

                } else {
                    console.log(eData);
                    toast.error(eData.message, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false
                    });
                }

                this.setState({
                    submitted: false
                });
            });
    };

    onInputChange = (e) => {
        let fieldName = e.target.name;
        let fieldValue = e.target.value;

        this.setState({
            [fieldName]: fieldValue
        });
    };

    render() {
        return (
            <span>
                <a href="#" className={this.props.classes} role="button" onClick={this.toggle}>{ this.props.name }</a>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className} style={{ maxWidth: '720px' }}>
                    <ModalHeader toggle={this.toggle}>LookUp Help</ModalHeader>
                    <form onSubmit={this.handleSubmit}>
                        <ModalBody>
                            <p>Please include details of your query and a member of the team will get back to you.</p>

                            <div className="mb-2">
                                <input onChange={this.onInputChange} value={this.state.name} className={"form-control border " + (this.state.errors.name ? ' is-invalid' : '')} type="text" name="name" placeholder="Name *" required />
                                <div className="invalid-feedback mb-2">{this.state.errors.name ? this.state.errors.name : ''}</div>
                            </div>

                            <div className="mb-2">
                                <input onChange={this.onInputChange} value={this.state.email} className={"form-control border " + (this.state.errors.email ? ' is-invalid' : '')} type="email" name="email" placeholder="Email *" required />
                                <div className="invalid-feedback mb-2">{this.state.errors.email ? this.state.errors.email : ''}</div>
                            </div>

                            <div className="mb-2">
                                <textarea onChange={this.onInputChange} value={this.state.message} name="message" className={"form-control border " + (this.state.errors.message ? ' is-invalid' : '')} placeholder="Your message *" rows="10" required></textarea>
                                <div className="invalid-feedback">{this.state.errors.message ? this.state.errors.message : ''}</div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" className="btn-lg" disabled={this.state.submitted}>{this.state.submitted ? 'Sending...' : 'Submit'}</Button>
                            <Button color="link" className="btn-lg" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </form>
                </Modal>
            </span>
        );
    }
}

export default ContactModal;