import React, { Component, Fragment } from 'react';
import LoginForm from '../../components/Auth/LoginForm';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import redirectAuth from '../../components/Auth/redirectAuth';

class LoginScreen extends Component {
	render() {
		return (
			<Fragment>
				<Header />

				<section className="container">
					<div className="row justify-content-center align-items-center py-9">
						<div className="col">
							<LoginForm />
						</div>
					</div>
				</section>
				
				<Footer />
			</Fragment>
		);
	}
}

export default redirectAuth(LoginScreen);