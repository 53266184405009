import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import history from '../../history';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../actions';

import Input from '../Form/Input';
import Button from '../Form/Button';

const INITIAL_STATE = {
	user: {
		username: '',
		password: '',
	},
	error: {
		message: ''
	}
};

class LoginForm extends Component {
	state = INITIAL_STATE;

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.signin(this.state.user, () => history.push('/dashboard'));
	};

	handleInput = (e) => {
		let value = e.target.value;
		let name = e.target.name;

		this.setState(
			prevState => ({
				user: {
					...prevState.user,
					[name]: value
				}
			})
		);
	};

	handleError() {
		if (this.props.errorMessage) {
			return (
				<div className="row">
					<div className="col-md-6 offset-md-4">
						<span className="invalid-feedback d-block">
		                    <strong>{ this.props.errorMessage }</strong>
		                </span>
		            </div>
	            </div>
			);
		}
	};

	render() {
		return (
	        <form method="POST" onSubmit={this.handleSubmit}>
	        	{this.handleError()}
				<Input 
					inputType={'email'}
					title={"Email Address"}
					name={'username'}
					value={this.state.user.username}
					placeholder={'Enter your email address'}
					handleChange={this.handleInput}
					required={true}
				/>

				<Input
					inputType={'password'}
					title={'Password'}
					name={'password'}
					value={this.state.user.password}
					placeholder={'***********'}
					handleChange={this.handleInput}
					required={true}
				/>

	            <div className="form-group row mb-0">
	                <div className="col-md-8 offset-md-4">
	                	<Button
	                		title={'Login'}
	                		type={'submit'}
	                		className={'btn btn-primary btn-lg'}
	                	/>

	                    <NavLink className="btn btn-link" to="/password">
	                        Forgot your password
	                    </NavLink>
	                </div>
	            </div>
	        </form>

		);		
	}
}

function mapStateToProps(state) {
	return { 
		errorMessage: state.auth.errorMessage
	};
}

export default connect(mapStateToProps, actions)(LoginForm);