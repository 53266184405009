import React, { Component, Fragment } from 'react';
import { ClipLoader, BarLoader } from 'react-spinners';
import axios from 'axios';
import OrgItem from './OrgItem';
import OrgFilter from './OrgFilter';
import Pagination from 'react-js-pagination';
// import '../../sass/modules/pagination.scss';
import { Scrollbars } from 'react-custom-scrollbars';

const ORG_URL = process.env.REACT_APP_API_URL + 'api/organisations';
const INITIAL_STATE = {
	organisations: [],
	org_url: ORG_URL,
	next_page: ORG_URL,
	init_load_more: false,
	loading: false,
	filtered: false,
	pagination: [],
	org: {
		title: '',
		arts_award_supporter: 0,
		artsmark_partner: 0,
		support_send: 0,
		artform: 0,
		postcode: '',
		sort: 'title',
		status: 'active',
		keystages: [],
		page: 1
	}
};

class OrganisationList extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.init();
		this.orgFilter = React.createRef();
		this.scrollInto = React.createRef();
	}

	init() {
		if (!this.state.loading) {
			this.setState({
				loading: true
			});

			let conditions = {
				status: 'active',
				page: this.state.org.page,
			};

			let artsmark_partner_orgs = this.props.artsmarkPartner;
			if(artsmark_partner_orgs && artsmark_partner_orgs === 1) {
				conditions = {
					status: 'active',
				artsmark_partner: 1,
				page: this.state.org.page,
				}
			}

			// API endpoints for organisation list
			axios.post(ORG_URL + '/filter', conditions)
				.then(response => {
					const organisations = response.data.data;
					const pagination = response.data.meta.pagination;

					if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
						this.setState({
							organisations: [...this.state.organisations, ...organisations],
							loading: false,
							pagination: pagination,
							init_load_more: true
						}, () => {
							const url = window.location.href;

							this.saveState(url, this.state.organisations, this.state.org.page, this.state.pagination);
							this.events();
						});
					} else {
						this.setState({
							organisations: organisations,
							loading: false,
							pagination: pagination,
							init_load_more: false
						}, () => {
							const url = window.location.href;

							this.saveState(url, this.state.organisations, this.state.org.page, this.state.pagination);
							this.events();
						});
					}
				});
		}
	}

	events() {
		if (window.location.search.indexOf('page') > 0) {
			const url = new URL(window.location.href);
			const page = url.searchParams.get('page');

			axios.post(ORG_URL + '/filter', {
				status: 'active',
				page: page,
			})
				.then(response => {
					const organisations = response.data.data;
					const pagination = response.data.meta.pagination;

					this.setState(
						prevState => ({
							organisations: organisations,
							loading: false,
							pagination: pagination,
							init_load_more: false,
							org: {
								...prevState.org,
								page: parseInt(page)
							}
						}), () => {
							const url = window.location.href;

							this.saveState(url, this.state.organisations, this.state.org.page, this.state.pagination);
						}
					);
				});
		}

		window.addEventListener("popstate", (e) => {
			if (e.state !== null) {
				this.setState(
					prevState => ({
						...prevState,
						organisations: e.state.filtersHtml,
						org: {
							...prevState.org,
							page: e.state.page
						},
						pagination: e.state.pagination
					})
				);
			}
		});
	}

	onClearFilter = () => {
		this.setState(INITIAL_STATE, () => {
			this.init();
			this.orgFilter.current.getArtformTypes();
		});
	};

	onFilteredResults = (props) => {
		this.setState({
			organisations: [],
			init_load_more: false,
			loading: true,
			filtered: true,
			org: {
				keyword: props.org.keyword,
				arts_award_supporter: props.org.arts_award_supporter,
				artsmark_partner: props.org.artsmark_partner,
				support_send: props.org.support_send,
				postcode: props.org.postcode,
				keystages: props.org.keystages,
				sort: props.org.sort,
				distance: props.org.distance,
				artform: props.org.artform,
				status: this.state.org.status,
				page: props.page,
			}
		}, () => this.getFilteredResults());
	}

	getFilteredResults = () => {
		axios.post(ORG_URL + '/filter', {
			keyword: this.state.org.keyword,
			arts_award_supporter: this.state.org.arts_award_supporter,
			artsmark_partner: this.state.org.artsmark_partner,
			support_send: this.state.org.support_send,
			postcode: this.state.org.postcode,
			keystages: this.state.org.keystages,
			distance: this.state.org.distance,
			sort: this.state.org.sort,
			artform: this.state.org.artform,
			status: this.state.org.status,
			page: this.state.org.page,
		})
			.then(response => {
				const organisations = response.data.data;
				const pagination = response.data.meta.pagination;

				if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
					this.setState({
						organisations: organisations,
						loading: false,
						pagination: pagination,
					}, () => {
						const href = window.location.href;
						let url = href + '?page=' + this.state.org.page;

						if (window.location.search.indexOf('page') > 0) {
							const page = this.state.org.page;
							url = '?page=' + page;
						}

						this.saveState(url, this.state.organisations, this.state.org.page, this.state.pagination);
					});
				} else {
					this.setState({
						organisations: organisations,
						loading: false,
						pagination: pagination,
					});
				}
			});
	};

	onLoadMore = () => {
		if (this.state.pagination.total_pages >= this.state.pagination.current_page && !this.state.init_load_more) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						page: this.state.org.page + 1
					}
				}),
				() => this.getFilteredResults()
			);
		}

		if (this.state.init_load_more) {
			this.setState(
				prevState => ({
					...prevState,
					org: {
						...prevState.org,
						page: this.state.org.page + 1
					}
				}), () => this.init()
			);
		}
	};

	handleSortOrderChange = (e, sort) => {
		this.setState(
			prevState => ({
				...prevState,
				org: {
					...prevState.org,
					sort: sort
				}
			}), () => {
				this.getFilteredResults();
				this.scrollInto.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}
		)
	}

	handlePageChange = (pageNumber) => {
		this.setState(
			prevState => ({
				...prevState,
				org: {
					...prevState.org,
					page: pageNumber
				},
				pagination: {
					...prevState.pagination,
					current_page: pageNumber
				}
			}), () => {
				this.getFilteredResults();
				this.scrollInto.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}
		)
	}

	saveState(url, container, page, pagination) {
		window.history.pushState({
			path: url,
			filtersHtml: container,
			page: page,
			pagination: pagination,
		}, 'Org list', url);
	}

	renderSorting() {
		return (
			<section className="bg-grey border-bottom">
				<div className="container py-2">
					<div className="row">
						<div className="col">
							<label className="pr-2">SORT</label>
							<button className={'btn btn-sort mr-1' + ((this.state.org.sort === 'title') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'title')}>A-Z</button>
							<button className={'btn btn-sort mr-1' + ((this.state.org.sort === 'published') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'published')}>MOST RECENT</button>
							<button className={'btn btn-sort mr-1' + ((this.state.org.sort === 'random') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'random')}>RANDOM</button>
							{this.renderNumberResults()}
						</div>

					</div>
				</div>
			</section>
		);
	}

	renderLoadmore() {
		if (this.state.pagination.total_pages > this.state.pagination.current_page || this.state.init_load_more) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-1 py-md-4">
						<button onClick={this.onLoadMore} className="btn btn-primary btn-lg">
							Load More <div className="loading-spinner">
								<BarLoader
									color={'#FFF'}
									loading={this.state.loading}
								/>
							</div></button>
					</div>
				</div>
			);
		}
	};

	renderResults() {
		if (this.state.organisations.length) {
			return (
				this.state.organisations.map(org =>
					<OrgItem key={org.id} organisation={org} />
				)
			);
		}

		if (!this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);
		}
	}

	renderLoadFinalResult() {
		if (this.state.loading) {
			return (
				<div className="container py-10">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<ClipLoader
								color={'#28A2FB'}
								size={100}
								loading={true}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<Fragment>
						{this.renderSorting()}
						<section id="tabResults" className="tab-results" ref={this.scrollInto}>
						{this.renderPagination()}
						{this.renderResults()}
						{this.renderPagination()}
					</section>
				</Fragment>
			);
		}

	}

	renderNumberResults() {
		const results = this.state.pagination;

		if (this.state.filtered && this.state.org.postcode.length > 0 && this.state.org.sort === 'distance') {
			return (
				<span>
					{results.total} organisations found by closest to postcode
				</span>
			)
		} else {
			return (
				<span>
					{results.total} organisations found
				</span>
			)
		}
	}

	renderPagination() {
		const pagination = this.state.pagination;

		if (pagination.total_pages > 1) {
			return (
				<section className="bg-grey">
					<div className="container">
						<nav aria-label="Pagination nav">
							<Scrollbars autoHide autoHeight>
								<Pagination
									activePage={pagination.current_page}
									itemsCountPerPage={pagination.per_page}
									totalItemsCount={pagination.total}
									pageRangeDisplayed={pagination.total_pages}
									onChange={this.handlePageChange}
									itemClass="page-item"
									linkClass="page-link"
									firstPageText={<span className="fa fa-angle-double-left"></span>}
									lastPageText={<span className="fa fa-angle-double-right"></span>}
									nextPageText={<span className="fa fa-angle-right"></span>}
									prevPageText={<span className="fa fa-angle-left"></span>}
								/>
							</Scrollbars>
						</nav>
					</div>
				</section>
			)
		}
	}

	render() {
		return (
			<Fragment>
				<OrgFilter
					ref={this.orgFilter}
					onFilterSubmit={this.filterSubmit}
					onClearFilter={this.onClearFilter}
					onFilteredResults={this.onFilteredResults}
					artsmarkPartner={this.props.artsmarkPartner}
				/>

				{this.renderLoadFinalResult()}

			</Fragment>
		)
	}
}

export default OrganisationList;