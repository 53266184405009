import React from 'react';
import { NavLink } from 'react-router-dom';

const FormLinks = () => (

    <div className="container pt-5 pb-5">
        <h2 className="text-success mb-3">FOR ARTS AND CULTURAL ORGANISATIONS</h2>
        <div className="row">
            <div className="col-md-6 mb-3">Add details of your organisation's schools offer along with details of upcoming opportunities and resources to LookUp</div>
        </div>
        <div className="row">
            <div className="col-md-6 mb-5">
                <NavLink to="/activities/create">
                    <div className="form-link">
                        <div className="title">Add your event, project or resource to LookUp</div>
                        <span className="plus">
                            <svg width="81px" height="76px" viewBox="0 0 81 76">
                                <g id="Cross-black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M69,34.6136364 C69,32.4680398 67.259233,30.7272727 65.1136364,30.7272727 L48.2727273,30.7272727 L48.2727273,13.8863636 C48.2727273,11.740767 46.5319602,10 44.3863636,10 L36.6136364,10 C34.4680398,10 32.7272727,11.740767 32.7272727,13.8863636 L32.7272727,30.7272727 L15.8863636,30.7272727 C13.740767,30.7272727 12,32.4680398 12,34.6136364 L12,42.3863636 C12,44.5319602 13.740767,46.2727273 15.8863636,46.2727273 L32.7272727,46.2727273 L32.7272727,63.1136364 C32.7272727,65.259233 34.4680398,67 36.6136364,67 L44.3863636,67 C46.5319602,67 48.2727273,65.259233 48.2727273,63.1136364 L48.2727273,46.2727273 L65.1136364,46.2727273 C67.259233,46.2727273 69,44.5319602 69,42.3863636 L69,34.6136364 Z" id="-copy" fill="#292527"></path>
                                </g>
                            </svg>
                        </span>
                        <div className="description">Add details of any current or upcoming opportunities for teachers and schools</div>
                    </div>
                </NavLink>
            </div>

            <div className="col-md-6 mb-5">
                <NavLink to="/organisations/create">
                    <div className="form-link">
                        <div className="title">Add your organisation to LookUp</div>
                        <span className="plus">
                            <svg width="81px" height="76px" viewBox="0 0 81 76">
                                <g id="Cross-black" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <path d="M69,34.6136364 C69,32.4680398 67.259233,30.7272727 65.1136364,30.7272727 L48.2727273,30.7272727 L48.2727273,13.8863636 C48.2727273,11.740767 46.5319602,10 44.3863636,10 L36.6136364,10 C34.4680398,10 32.7272727,11.740767 32.7272727,13.8863636 L32.7272727,30.7272727 L15.8863636,30.7272727 C13.740767,30.7272727 12,32.4680398 12,34.6136364 L12,42.3863636 C12,44.5319602 13.740767,46.2727273 15.8863636,46.2727273 L32.7272727,46.2727273 L32.7272727,63.1136364 C32.7272727,65.259233 34.4680398,67 36.6136364,67 L44.3863636,67 C46.5319602,67 48.2727273,65.259233 48.2727273,63.1136364 L48.2727273,46.2727273 L65.1136364,46.2727273 C67.259233,46.2727273 69,44.5319602 69,42.3863636 L69,34.6136364 Z" id="-copy" fill="#292527"></path>
                                </g>
                            </svg>
                        </span>
                        <div className="description">Join over 700 arts and cultural organisations currently profiled on LookUp and share details of your schools offer</div>
                    </div>
                </NavLink>
            </div>
        </div>
    </div>
);


export default FormLinks;