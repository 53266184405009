import React, { Component, Fragment } from 'react';
import axios from 'axios';
import history from '../../../history';
import { toast } from 'react-toastify';
import Keystages from '../../Form/Keystages';
import moment from 'moment';
import parseISO from 'date-fns/parseISO';
import ReactTags from "react-tag-autocomplete";
import {
	activityFromApi,
	isNumericBoolean
} from '../../Utilities/adapter';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const BASE_URL = process.env.REACT_APP_API_URL + 'api';
const API_URL = process.env.REACT_APP_API_URL + 'api/activities';
const ORG_URL = process.env.REACT_APP_API_URL + 'api/organisations/filter';
const INITIAL_STATE = {
	activity: {
		type: "",
		event_type: 1,
		resource_type: 1,
		title: '',
		organisation_title: '',
		organisation_id: null,
		info: '',
		info_link: '',
		has_multi_dates: 0,
		dates: [],
		start_date: moment(new Date(new Date().setHours(0, 0))).format(),
		end_date: moment(new Date(new Date().setHours(0, 0))).format(),
		support_send: 0,
		delivered_in_school: 0,
		webinar: 0,
		status: '',
		artforms: [],
		keystages: [],
		tags: [],
		venue_name: '',
		first_name: '',
		last_name: '',
		email: '',
		address_1: '',
		address_2: '',
		borough: '',
		postcode: '',
		free: 0,
		cost: '',
		_method: 'PUT'
	},
	artforms: [],
	keystages: [],
	suggestions: [],
	dates:[],
	organisations: [],
	selected_organisation: '',
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	accessToken: '',
	startDate: new Date(new Date().setHours(0, 0)),
	endDate: new Date(new Date().setHours(0, 0)),
	calendarDate: new Date(new Date().setHours(0, 0)),
	loading: false
};

let URL = '';

class ActivityEditForm extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getArtformTypes();
		this.getAvailableTags();
		this.setState({ accessToken: localStorage.getItem('accessToken') });
	}

	init() {
		URL = API_URL + '/' + this.props.id;
		axios.get(API_URL + '/' + this.props.id)
			.then(response => {
				
				const activity = response.data.data;
				const stateObject = activityFromApi(activity);
				const stateObjectActivity = stateObject.activity;

				this.setState(
					prevState => ({
						...prevState,
						...stateObject,
						activity: {
							...prevState.activity,
							...stateObjectActivity
						}
					}),
					() => {
						this.mapProjectDates();
						this.mapArtforms();
						this.mapKeystages();
					}
				);
			})
			.catch(e => {
				console.log(e);
			});
	}

	handleSubmit = (e) => {
		e.preventDefault();

		this.setState(
			prevState => ({
				...prevState,
				loading: true
			})
		);

		let URL = API_URL + '/' + this.props.id;

		axios.post(URL, this.state.activity, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken
			}
		})
			.then(response => {
				if (response.status === 200) {
					toast.success(response.data.message, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 3000,
						onClose: () => window.location.reload()
					});
				}
			})
			.catch(errorMsg => {
				let eData = errorMsg.response.data;
				let errM = errorMsg.response.data.errors;

				if (errorMsg.response.data.hasOwnProperty('errors')) {
					Object.entries(errM).map(([field, index]) => {
						let message = 'Error: ' + index;

						toast.error(message, {
							position: toast.POSITION.TOP_CENTER,
							autoClose: false
						});
					});
				} else {
					console.log(eData);
					toast.error(eData.message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				}

				this.setState(
					prevState => ({
						...prevState,
						loading: false
					})
				);
			});
	};

	handleActivityType = (e, activity_type) => {
		e.preventDefault();

		let event_type = this.state.activity.event_type;
		let resource_type = this.state.activity.resource_type;

		this.setState(
			prevState => ({
				...prevState,
				 activity: {
				 	...prevState.activity,
				 	type: activity_type,
				 	event_type: (event_type)? event_type : 1,
					 resource_type: (resource_type)? resource_type : 1,
					start_date: this.state.activity.start_date ? this.state.activity.start_date : moment(new Date(new Date().setHours(0, 0))).format(),
					end_date: this.state.activity.end_date ? this.state.activity.end_date : moment(new Date(new Date().setHours(0, 0))).format(),
				 }
			})
		)

		console.log(this.state);
	}

	handleProjectDateType = () => {

		let has_multi_dates = this.state.activity.has_multi_dates;

		this.setState(
			prevState => ({
				activity: {
					...prevState.activity,
					has_multi_dates: (has_multi_dates === 0) ? 1 : 0
				}
			})
		);
	}

	handleRemoveProjectDate = (date) => {

		let activity_dates = this.state.activity.dates;

		activity_dates.map(prevDate => {
			if (moment(date).isSame(moment(prevDate))) {
				const index = activity_dates.indexOf(prevDate);
				if (index > -1) {
					activity_dates.splice(index, 1);
				}
			}
		});

		let dates = this.state.dates;

		dates.map(prevDate => {
			if (moment(date).isSame(moment(prevDate))) {
				const index = dates.indexOf(prevDate);
				if (index > -1) {
					dates.splice(index, 1);
				}
			}
		});

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					dates: activity_dates
				},
				dates: dates
			})
		);
	}

	handleProjectDates = (date) => {

		let activity_dates = this.state.activity.dates;

		activity_dates.map(prevDate => {
			if (moment(date).format('MM-DD-YYYY') === moment(prevDate).format('MM-DD-YYYY')) {
				const index = activity_dates.indexOf(prevDate);
				if (index > -1) {
					activity_dates.splice(index, 1);
				}
			}
		});

		activity_dates.push(moment(new Date(date)).format());


		let dates = this.state.dates;
		
		dates.map(prevDate => {
			if (moment(date).format('MM-DD-YYYY') === moment(prevDate).format('MM-DD-YYYY')) {
				const index = dates.indexOf(prevDate);
				if (index > -1) {
					dates.splice(index, 1);
				}
			}
		});

		dates.push(new Date(date));

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					dates: activity_dates
				},
				calendarDate: date,
				dates: dates
			})
		);
	}

	handleStartDate = (date) => {

		let activity_type = this.state.activity.type;
		let start = moment(date, 'DD-MM-YYYY');
		let end = moment(this.state.endDate, 'DD-MM-YYYY');
		let difference = end.diff(start, 'days');

		if (Math.abs(difference) > 14 && activity_type === 'event') {			 
			this.setState(
				prevState => ({
					activity: {
						...prevState.activity,
						start_date: moment(date).format(),
					},
					startDate: date,
					event_date_selection_error: 'Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.'
				}));
		} else {

		   this.setState(
			   prevState => ({
				   ...prevState,
				   startDate: date
			   }), () => {
				   if (moment(this.state.startDate).isSameOrAfter(this.state.endDate) || this.state.endDate === '') {
					   this.setState(
						   prevState => ({
							   activity: {
								   ...prevState.activity,
								   start_date: moment(date).format(),
								   end_date: moment(date).format()
							   },
							   startDate: date,
							   endDate: date,
							   event_date_selection_error: '',
						   })
					   )
				   } else {
					   this.setState(
						   prevState => ({
							   activity: {
								   ...prevState.activity,
								   start_date: moment(date).format()
							   },
							   startDate: date,
							   event_date_selection_error: '',
						   })
					   )
				   }
			   }
		   );
		}
   }

   handleEndDate = (date) => {

	let activity_type = this.state.activity.type;
	let start = moment(this.state.startDate, 'DD-MM-YYYY');
	let end = moment(date, 'DD-MM-YYYY');
	let difference = end.diff(start, 'days');

	if (Math.abs(difference) > 14 && activity_type === 'event') {

		this.setState(
			prevState => ({
				...prevState,
				event_date_selection_error:  'Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.'

			}));
			
	} else {
		this.setState(
			prevState => ({
				...prevState,
				endDate: date
			}), () => {
				if (moment(this.state.endDate).isBefore(this.state.startDate)) {
					this.setState(
						prevState => ({
							activity: {
								...prevState.activity,
								start_date: moment(date).format(),
								end_date: moment(date).format()
							},
							startDate: date,
							endDate: date,
							event_date_selection_error: '',
						})
					)
				} else {
					this.setState(
						prevState => ({
							activity: {
								...prevState.activity,
								end_date: moment(date).format()
							},
							endDate: date,
							event_date_selection_error: '',
						})
					)
				}
			}
		);
	}
}

	checkIfEventExists = () => {
		URL = API_URL + '/' + this.props.id;
		axios.get(API_URL + '/' + this.props.id)
			.then(response => {
				if (response.status === 200) {
					return true;
				}
			})
			.catch(e => {
				console.log(e);
				return false;
			});
	}

	onDelete = () => {
		axios.delete(URL, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken,
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		})
			.then(response => {
				if (response.status === 200) {
					toast.success(response.data.message, {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 2000,
						onClose: () => history.goBack()
					});
				}
			})
			.catch(errorMsg => {
				let eData = errorMsg.response.data;
				let errM = errorMsg.response.data.errors;

				if (errorMsg.response.data.hasOwnProperty('errors')) {
					Object.entries(errM).map(([field, index]) => {
						let message = 'Error: ' + index;

						toast.error(message, {
							position: toast.POSITION.TOP_CENTER,
							autoClose: false
						});
					});
				} else {
					console.log(eData);
					toast.error(eData.message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				}
			});
	}

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({
					activity: { ...prevState.activity, [fieldName]: numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					activity: { ...prevState.activity, [fieldName]: fieldValue }
				})
			);
		}
	}

	mapArtforms = () => {
		let arraySelected = [];
		const { artforms } = this.state;

		if (this.state.activity.artforms.length > 0) {
			this.state.artforms.map((key, selectionIndex) => {
				this.state.activity.artforms.map((selected, index) => {
					if (key.name === selected) {
						artforms[selectionIndex].isChecked = true;
						arraySelected.push(key.id);
					}
				});
			});
		}

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					artforms: arraySelected
				},
				artforms: artforms
			})
		)
	}

	mapKeystages = () => {
		if (this.state.keystages.length > 0) {
			this.state.keystages.map((key, index) => {
				let keyname = 'keystage' + key;

				this.setState(
					prevState => ({
						...prevState,
						[keyname]: true
					})
				);
			});
		}
	}

	/* tag */
	onTagDelete(i) {
		const tags = this.state.activity.tags.slice(0);
		tags.splice(i, 1);
		this.setState((prevState) => ({
			activity: {
			  ...prevState.activity,
			  tags: tags
			}
		  }));
	  }
	
	  onTagAddition(tag) {
		const tags = [].concat(this.state.activity.tags, tag);
		this.setState((prevState) => ({
			activity: {
			  ...prevState.activity,
			  tags: tags
			}
		  }));
	 }

	mapProjectDates = () => {
		let moment_dates = [];
		const type = this.state.activity.type;

		if(type === 'project') {

			if (this.state.activity.dates.length > 0) {
				this.state.activity.dates.map((date, selectionIndex) => {
					moment_dates.push(new Date(date));
				});
			}
	
			this.setState(
				prevState => ({
					...prevState,
						dates: moment_dates
				})
			)
		}
	}

	getArtformTypes() {
		axios.get(BASE_URL + '/artform-types')
			.then(response => {
				let data = response.data.map((key) => {
					let art = Object.assign({}, key);
					art.isChecked = false;

					return art;
				});

				this.setState(
					prevState => ({
						...prevState,
						artforms: data
					}), () => this.init()
				);
			})
			.catch(errorMsg => {
				let eData = errorMsg.response.data;
				let errM = errorMsg.response.data.errors;

				if (errorMsg.response.data.hasOwnProperty('errors')) {
					Object.entries(errM).map(([field, index]) => {
						let message = 'Error: ' + index;

						toast.error(message, {
							position: toast.POSITION.TOP_CENTER,
							autoClose: false
						});
					});
				} else {
					console.log(eData);
					toast.error(eData.message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				}
			});
	}

	getAvailableTags = () => {
		axios.get(BASE_URL + "/get-available-tags").then((response) => {
		  this.setState({
			suggestions: response.data.data,
		  });
		});
	};

	onSearchOrg = (e) => {
		let fieldValue = e.target.value;

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					organisation_title: fieldValue
				},
				organisation_search: fieldValue
			}),
			() => this.getOrganisation()
		);
	};

	getOrganisation = () => {
		axios.post(ORG_URL, {
			title: this.state.organisation_search
		})
			.then(response => {
				let organisations = response.data.data;

				this.setState(
					prevState => ({
						...prevState,
						organisations: organisations
					})
				);
			});

		if (this.state.organisation_search === '') {
			this.setState(
				prevState => ({
					...prevState,
					activity: {
						...prevState.activity,
						organisation_id: null
					},
					selected_organisation: null
				})
			);
		}
	};

	onSelectExistingOrganisation = (e) => {
		const orgId = parseInt(e.target.value);
		const title = e.target.options[e.target.selectedIndex].dataset.title;

		this.setState(
			prevState => ({
				activity: {
					...prevState.activity,
					organisation_id: orgId,
					organisation_title: title,
				},
				organisation_search: title,
				selected_organisation: orgId
			})
		);
	};

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);
		const { artforms } = this.state;

		if (e.target.checked) {
			this.state.artforms.map((key, index) => {
				if (parseInt(key.id) === fieldValue) {
					artforms[index].isChecked = true;

					this.setState(
						prevState => ({
							...prevState,
							activity: {
								...prevState.activity,
								artforms: [...prevState.activity.artforms, fieldValue]
							},
							artforms: artforms
						})
					);
				}
			});
		} else {
			let selectedArtforms = [...this.state.activity.artforms];
			let selectedIndex = selectedArtforms.indexOf(fieldValue);

			this.state.artforms.map((key, index) => {
				if (parseInt(key.id) === fieldValue) {
					artforms[index].isChecked = false;

					if (selectedIndex !== -1) {
						selectedArtforms.splice(selectedIndex, 1);

						this.setState(
							prevState => ({
								...prevState,
								activity: {
									...prevState.activity,
									artforms: selectedArtforms
								},
								artforms: artforms
							})
						);
					}
				}
			});
		}
	}

	selectKeystageCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);
		let fieldName = e.target.name;

		if (e.target.checked) {
			this.setState(
				prevState => ({
					activity: {
						...prevState.activity,
						keystages: [...prevState.activity.keystages, fieldValue]
					},
					keystages: [...prevState.activity.keystages, fieldValue],
					[fieldName]: true
				})
			);

		} else {
			let keystages = [...this.state.activity.keystages];
			let index = keystages.indexOf(fieldValue);

			if (index !== -1) {
				keystages.splice(index, 1);

				this.setState(
					prevState => ({
						activity: {
							...prevState.activity,
							keystages: keystages
						},
						keystages: keystages,
						[fieldName]: false
					})
				);
			}
		}
	}

	renderArtformSelection() {
		return (
			this.state.artforms.map(art =>
				<div key={art.id}
					className="form-group"
					style={{ display: 'inline-block', width: '50%' }}>
					<input
						id={art.id}
						onChange={this.selectArtformCheckboxes}
						type="checkbox"
						name="artforms"
						className="border mr-1"
						value={art.id}
						checked={art.isChecked}
					/>
					<label htmlFor={art.id}>{art.name}</label>
				</div>
			)
		);
	}

	renderEventDates() {
		
		return (
			<div className="form-group row">
				<label htmlFor="eventDates" className="col-12 col-md-3 text-primary">Dates</label>
				<div className="col-12 col-md-8 col-lg-6">
					<p className="mb-0 text-primary">
						Event date<span className="text-dark">{this.state.activity.start_date ?
							': ' + moment(this.state.activity.start_date).format("ddd, D MMM YYYY, h:mm A")
							: ''}</span>
					</p>
					<DatePicker
						inline
						selectsStart
						showTimeSelect
						selected={parseISO(this.state.activity.start_date)}
						startDate={parseISO(this.state.activity.start_date)}
						endDate={parseISO(this.state.activity.end_date)}
						onChange={this.handleStartDate}
						name="start_date"
						timeFormat="HH:mm"
						timeIntervals={5}
						className="form-control border mb-2"
						placeholderText="Event date *"
						required
					/>

					<p className="mb-0 text-primary">
						End date<span className="text-dark">{parseISO(this.state.activity.end_date) ?
							': ' + moment(parseISO(this.state.activity.end_date)).format("ddd, D MMM YYYY, h:mm A")
							: ''}</span>
					</p>
					<DatePicker
						inline
						selectsEnd
						showTimeSelect
						selected={parseISO(this.state.activity.end_date)}
						startDate={parseISO(this.state.activity.start_date)}
						endDate={parseISO(this.state.activity.end_date)}
						onChange={this.handleEndDate}
						name="end_date"
						timeFormat="HH:mm"
						timeIntervals={5}
						className="form-control border mb-2"
						placeholderText="End date *"
						required
					/>

					<div className="text-danger">{this.state.event_date_selection_error}</div>
				</div>
			</div>
		);
	}

	renderProjectPeriod() {
		
		const activity_type = this.state.activity.type;
	
		if(activity_type === "project") {
			return (
				<div className="form-group row">
					<label htmlFor="projectDates" className="col-12 col-md-3 text-primary">Dates</label>
					<div className="col-12 col-md-8 col-lg-6">
						<p className="mb-0">
							Project Date *<br />
							{this.state.activity.start_date ?
								moment(this.state.activity.start_date).format("ddd, D MMM YYYY, h:mm A")
								: ''}
						</p>
	
						<DatePicker
							inline
							selectsStart
							showTimeSelect
							selected={parseISO(this.state.activity.start_date)}
							startDate={parseISO(this.state.activity.start_date)}
							endDate={this.state.endDate}
							onChange={this.handleStartDate}
							name="start_date"
							timeFormat="HH:mm"
							timeIntervals={5}
							className="form-control border mb-2"
							placeholderText="Date *"
							required
						/>
						<p className="mb-0 mt-2">
							End date *<br />
							{this.state.activity.end_date ?
								moment(this.state.activity.end_date).format("ddd, D MMM YYYY, h:mm A")
								: ''}
						</p>
						<DatePicker
							inline
							selectsEnd
							showTimeSelect
							selected={parseISO(this.state.activity.end_date)}
							startDate={parseISO(this.state.activity.start_date)}
							endDate={parseISO(this.state.activity.end_date)}
							onChange={this.handleEndDate}
							name="end_date"
							timeFormat="HH:mm"
							timeIntervals={5}
							className="form-control border mb-2"
							placeholderText="End date *"
							required
						/>
	
						<button type="button" onClick={this.handleProjectDateType} className="btn btn-outline-primary btn-block">Add multiple dates instead</button>
					</div>
				</div>
			);
		} 

		return false;

	}

	renderProjectDates() {
		let dates = [];

		this.state.activity.dates.map((date, index) => {
			dates.push(<li key={index} className="mb-2">
				<div className="row">
					<div className="col-md-7">{moment(date).format("dddd, D MMM YYYY, h:mm A")}</div>
					<div className="col-md-5"><button type="button" className="btn btn-sm btn-outline-primary float-left" date={date} onClick={(e) => this.handleRemoveProjectDate(date, e)}><i className="fa fa-times"></i></button></div>
				</div>
			</li>);
		})

		const activity_type = this.state.activity.type;
	
		if(activity_type === "project") {
			return (
				<div className="form-group row">
					<label htmlFor="projectDates" className="col-12 col-md-3 text-primary">Dates</label>
					<div className="col-12 col-md-8 col-lg-6">
						<ul className="list-unstyled m-0 p-0">
							{dates}
						</ul>
	
						<DatePicker
							inline
							selected={this.state.calendarDate}
							highlightDates={[...this.state.dates]}
							showTimeSelect
							onChange={this.handleProjectDates}
							timeFormat="HH:mm"
							timeIntervals={60}
							minTime={new Date(new Date().setHours(8, 0))}
							maxTime={new Date(new Date().setHours(23, 0))}
						/>
						<button type="button" onClick={this.handleProjectDateType} className="btn btn-outline-primary btn-block">Add a period instead</button>
					</div>
				</div>
			);
		}
	}


	renderActivityCost() {
		if (this.state.activity.cost !== '' && this.state.activity.free === 0) {
			return (
				<Fragment>
					<div className="form-group row">
						<label htmlFor="eventCost" className="col-12 col-md-3 text-primary">Cost</label>
						<div className="col-12 col-md-8 col-lg-6">
							<div>
								<input
									onChange={this.onInputChange}
									value={this.state.activity.cost}
									id="eventCost"
									type="text"
									name="cost"
									placeholder="Cost"
									className="form-control border"
								/>
							</div>

							<div className="mt-2">
								<input
									onChange={this.onInputChange}
									value={this.state.activity.free}
									id="freeEvent"
									type="checkbox"
									name="free"
									className="form-checkbox-disabled"
									disabled
								/>
								<label htmlFor="freeEvent"><strike>Free </strike></label>
							</div>
						</div>
					</div>
				</Fragment>
			);
		} else if (this.state.activity.free === 1 && this.state.activity.cost === '') {
			return (
				<Fragment>
					<div className="form-group row">
						<label htmlFor="eventCost" className="col-12 col-md-3 text-primary">Cost</label>
						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.cost}
								id="eventCost"
								type="text"
								name="cost"
								placeholder="Cost"
								className="form-control border"
								disabled
							/>

							<div className="mt-2">
								<input
									onChange={this.onInputChange}
									value={this.state.activity.free}
									id="freeEvent"
									type="checkbox"
									name="free"
									checked={true}
									className="border mr-1"
								/>
								<label htmlFor="freeEvent">Free </label>
							</div>
						</div>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div className="form-group row">
						<label htmlFor="eventCost" className="col-12 col-md-3 text-primary">Cost</label>
						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.cost}
								id="eventCost"
								type="text"
								name="cost"
								placeholder="Cost"
								className="form-control border"
							/>

							<div className="mt-2">
								<input
									onChange={this.onInputChange}
									value={this.state.activity.free}
									id="freeEvent"
									type="checkbox"
									name="free"
									className="border mr-1"
								/>
								<label htmlFor="freeEvent" className="text-primary">Free </label>
							</div>
						</div>
					</div>
				</Fragment>
			);
		}
	}

	renderEventTypes() {
		return (
			<div className="form-group row">
				<label className="col-12 col-md-3 text-primary mb-0">Type *</label>

				<div className="col-12 col-md-8 col-lg-6">
					<p className="mb-0">Please select a category to help users find your event</p>
					<select
						onChange={this.onInputChange}
						className="form-control tab-filter-select border"
						value={this.state.activity.event_type}
						name="event_type">
						<option value={1}>CPD</option>
						<option value={2}>Performance</option>
						<option value={3}>Workshop</option>
						<option value={4}>Networking</option>
						<option value={5}>Other</option>
					</select>
				</div>
			</div>
		);
	}

	renderResourceTypes() {
		return (
			<div className="form-group row">
				<label className="col-12 col-md-3 text-primary mb-0">Type *</label>

				<div className="col-12 col-md-8 col-lg-6">
					<p className="mb-0">Please select a category</p>
					<select
						 onChange={this.onInputChange} className="form-control tab-filter-select border" value={this.state.activity.resource_type} name="resource_type">
							<option value={1}>Activities and worksheets</option>
							<option value={2}>Audio resources</option>
							<option value={3}>Lesson plans</option>
							<option value={4}>Online platforms</option>
							<option value={5}>Teacher CPD</option>
							<option value={6}>Video resources</option>
							<option value={7}>Virtual tours</option>
						</select>
					</div>
				</div>
		);
	}

	renderEventAddress() {
		return (
			<div className="form-group row">
				<p className="col-12 col-md-3 text-primary">Address</p>

				<div className="col-12 col-md-8 col-lg-6">
					<input onChange={this.onInputChange} value={this.state.activity.venue_name} type="text" name="venue_name" className="form-control border mb-1" placeholder="Venue name" />
					<input onChange={this.onInputChange} value={this.state.activity.address_1} type="text" name="address_1" className="form-control border mb-1" placeholder="Address 1" />
					<input onChange={this.onInputChange} value={this.state.activity.address_2} type="text" name="address_2" className="form-control border mb-1" placeholder="Address 2" />
					<input onChange={this.onInputChange} value={this.state.activity.borough} type="text" name="borough" className="form-control border mb-1" placeholder="Borough" />
					<input onChange={this.onInputChange} value={this.state.activity.postcode} type="text" name="postcode" className="form-control border" placeholder="Postcode" />
				</div>
			</div>
		);
	}

	renderProjectAddress() {
		return (
			<div className="form-group row">
				<p className="col-12 col-md-3 text-primary">Address</p>

				<div className="col-12 col-md-8 col-lg-6">
					<input onChange={this.onInputChange} value={this.state.activity.venue_name} type="text" name="venue_name" className="form-control border mb-1" placeholder="Venue name" />
					<input onChange={this.onInputChange} value={this.state.activity.address_1} type="text" name="address_1" className="form-control border mb-1" placeholder="Address 1" />
					<input onChange={this.onInputChange} value={this.state.activity.address_2} type="text" name="address_2" className="form-control border mb-1" placeholder="Address 2" />
					<input onChange={this.onInputChange} value={this.state.activity.borough} type="text" name="borough" className="form-control border mb-1" placeholder="Borough" />
					<input onChange={this.onInputChange} value={this.state.activity.postcode} type="text" name="postcode" className="form-control border" placeholder="Postcode" />
				</div>
			</div>
		);
	}



	renderDeliveredInSchoolCheckbox() {
		let activity_type = this.state.activity.type;

		if(activity_type === 'event' || activity_type === 'project') {
			return (
				<div className="form-group row my-4">
					<label htmlFor="suitableEvent" className="col-12 col-md-3 text-primary">Delivered in your school</label>

					<div className="col-12 col-md-8 col-lg-6">
						<input
							onChange={this.onInputChange}
							value={this.state.activity.delivered_in_school}
							checked={this.state.activity.delivered_in_school}
							id="suitableEvent"
							type="checkbox"
							name="delivered_in_school"
							className="border mr-1"
						/>
					</div>
				</div>
			);
		}
	}

	renderWebinarCheckbox() {
		let activity_type = this.state.activity.type;

		if(activity_type === 'event' || activity_type === 'project') {
			return (
				<div className="form-group row my-4">
					<label htmlFor="suitableEvent" className="col-12 col-md-3 text-primary">Webinar </label>

					<div className="col-12 col-md-8 col-lg-6">
						<input
							onChange={this.onInputChange}
							value={this.state.activity.webinar}
							checked={this.state.activity.webinar}
							id="suitableEvent"
							type="checkbox"
							name="webinar"
							className="border mr-1"
						/>
					</div>
				</div>
			);
		}
	}

	renderStatusDate() {
		if (this.state.activity.published_at) {
			return (
				<div className="form-group row">
					<label
						htmlFor="status"
						className="form-label text-primary col-12 col-md-3">Published date:</label>

					<div className="col-12 col-md-8 col-lg-6">
						<p>{moment(this.state.activity.published_at).format("dddd, Do MMMM YYYY")}</p>
					</div>
				</div>
			);
		}
	}

	renderOrgSearch() {
		const event = this.state.activity;

		return (
			<div className="form-group row">
				<div className="col-12 col-md-3"></div>
				<div className="col-12 col-md-8 col-lg-6">
					<label htmlFor="orgIfName" className="text-primary">If not what is the name of your organisation?</label>

					<input
						id="orgIfName"
						onChange={this.onInputChange}
						value={event.organisation_search ? event.organisation_search : event.organisation_title}
						type="text"
						name="organisation_title"
						placeholder="Organisation name"
						className="form-control border"
					/>
				</div>
			</div>
		);
	}

	renderOrgResults() {
		if (this.state.organisations.length) {
			return (
				<div className="form-group row">
					<div className="col-12 col-md-3"></div>
					<div className="col-12 col-md-8 col-lg-6">
						<label htmlFor="orgResults" className="text-primary">Organisations</label>
						<select onChange={this.onSelectExistingOrganisation} name="orgResults" id="orgResults" multiple className="form-control border">
							{this.state.organisations.map(org =>
								<option key={org.id} value={org.id} data-title={org.title}>{org.title} - {org.contacts.postcode ? org.contacts.postcode : 'Activity Only'}</option>
							)}
						</select>
					</div>
				</div>
			);
		}
	};

	renderOrganisation() {
		return (
			<div className="form-group row">
				<label className="text-primary col-12 col-md-3">Organisation Name: </label>
				<div className="col-12 col-md-8 col-lg-6">
					<p className="font-weight-bold">
						{this.state.activity.organisation_title}
					</p>
				</div>
			</div>
		)
	}

	render() {


		console.log(this.state);


		return (
			<form onSubmit={this.handleSubmit} className="">

				<div className="py-5">
					<div className="border-bottom border-bottom-primary">
						<div className="container py-5">
							<div className="form-group row">
								<div className="col-12 col-md-3 text-primary">
									<p>Section</p>
								</div>

								<div className="col-12 col-md-8 col-lg-6">
									<div className="row mb-2">
										<div className="col-md-4"> <button className={"btn btn-activity-selector"+((this.state.activity.type === 'event')? " active" : "")} onClick={(e) => this.handleActivityType(e, 'event')}>Event</button></div>
										<div className="col-md-8"><strong>A single date or on consequtive days lasting no longer than two weeks</strong></div>
									</div>

									<div className="row mb-1">
										<div className="col-md-4"><button className={"btn btn-activity-selector"+((this.state.activity.type === 'project')? " active" : "")} onClick={(e) => this.handleActivityType(e, 'project')}>Project</button></div>
										<div className="col-md-8"><strong>A recoccuring activity on a specific day or days or over a longer period like a term</strong></div>
									</div>

									<div className="row">
										<div className="col-md-4"><button className={"btn btn-activity-selector"+((this.state.activity.type === 'resource')? " active" : "")} onClick={(e) => this.handleActivityType(e, 'resource')}>Resource</button></div>
										<div className="col-md-8"><strong>Something that is not date related</strong></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container">

					<div className="form-group row">
						<label htmlFor="status"
							className="text-primary col-12 col-md-3">Status</label>

						<div className="col-12 col-md-8 col-lg-6">
							<p className="font-weight-bold text-uppercase mb-0">{this.state.activity.status}</p>
						</div>
					</div>

					{this.renderOrganisation()}

					<div className="form-group row">
						<div className="col-12 col-md-3 text-primary">
							<p>Update organisation:</p>
						</div>

						<div className="col-12 col-md-8 col-lg-6">
							<label htmlFor="checkOrgName" className="text-primary">Find existing organisation here</label>
							<input onChange={this.onSearchOrg} value={this.state.organisation_search} id="checkOrgName" type="text" name="organisation_search" placeholder="Type here to find organisation" className="form-control border" />
						</div>
					</div>

					{this.renderOrgSearch()}
					{this.renderOrgResults()}

					<div className="form-group row">
						<label htmlFor="eventTitle" className="text-primary col-12 col-md-3">Event title</label>
						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.title}
								id="eventTitle"
								type="text"
								name="title"
								placeholder="Event title*"
								className="form-control border" required
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="eventInfo" className="text-primary col-12 col-md-3">Information about your event</label>
						<div className="col-12 col-md-8 col-lg-6">
							<textarea
								onChange={this.onInputChange}
								value={this.state.activity.info}
								id="eventInfo"
								className="form-control border"
								name="info"
								placeholder="Event description (300 characters max)*"
								rows="8" required>
							</textarea>
						</div>
					</div>

					{(this.state.activity.type === "event") ? this.renderEventDates() : ""}

					{(this.state.activity.has_multi_dates === 0) ? this.renderProjectPeriod() : this.renderProjectDates()}

					{this.renderActivityCost()}

					<div className="form-group row">
						<label htmlFor="moreInfo" className="text-primary col-12 col-md-3">More information</label>

						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.info_link}
								id="moreInfo"
								type="url"
								name="info_link"
								className="form-control border"
								placeholder="Add link for more event information or booking *" />
						</div>
					</div>

					{(this.state.activity.type === "event") ? this.renderEventAddress() : ""}
					{(this.state.activity.type === "project") ? this.renderProjectAddress() : ""}
					{(this.state.activity.type === "event") ? this.renderEventTypes() : ""}
					{(this.state.activity.type === "resource") ? this.renderResourceTypes() : ""}

					<div className="form-group row mt-4 mb-1">
						<div className="col-12 col-md-3">
							<p className="text-primary">Artforms</p>
						</div>

						<div className="col-12 col-md-8 col-lg-6">
							<p className="text-primary">Optional event criteria</p>
							{this.renderArtformSelection()}
						</div>
					</div>

					<Keystages
						onChange={this.selectKeystageCheckboxes}
						state={this.state}
					/>

					<div className="form-group row my-4">
						<label htmlFor="suitableEvent" className="text-primary col-12 col-md-3">Is your offer designed / adaptable for disabled students? </label>
						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.support_send}
								checked={this.state.activity.support_send}
								id="suitableEvent"
								type="checkbox"
								name="support_send"
								className="border mr-1"
							/>
						</div>
					</div>

					{this.renderDeliveredInSchoolCheckbox()}

					{this.renderWebinarCheckbox()}

					<div className="form-group row my-4">
						<label
							htmlFor="suitableEvent"
							className="col-12 col-md-3 text-primary"
						>
							Tags
						</label>

						<div className="col-12 col-md-8 col-lg-6">
							<ReactTags
							tags={this.state.activity.tags}
							suggestions={this.state.suggestions}
							onDelete={this.onTagDelete.bind(this)}
							onAddition={this.onTagAddition.bind(this)}
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="contactFirstName" className="text-primary col-12 col-md-3">Contact first name</label>

						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.first_name}
								type="text"
								id="contactFirstName"
								name="first_name"
								className="form-control border mb-1"
								placeholder="First name*"
								required
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="contactLastName" className="text-primary col-12 col-md-3">Contact last name</label>

						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.last_name}
								type="text"
								id="contactLastName"
								name="last_name"
								className="form-control border mb-1"
								placeholder="Last name*"
								required
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="contactEmail" className="text-primary col-12 col-md-3">Contact Email</label>

						<div className="col-12 col-md-8 col-lg-6">
							<input
								onChange={this.onInputChange}
								value={this.state.activity.email}
								type="text"
								id="contactEmail"
								name="email"
								className="form-control border mb-1"
								placeholder="Email address*"
								required
							/>
						</div>
					</div>

					<div className="form-group row">
						<label htmlFor="status"
							className="text-primary col-12 col-md-3">Status</label>

						<div className="col-12 col-md-8 col-lg-6">
							<div className="tab-filter-select-wrap">
								<select
									id="status"
									onChange={this.onInputChange}
									value={this.state.activity.status}
									name="status"
									className="form-control tab-filter-select border"
								>
									<option value="draft">Draft</option>
									<option value="active">Active</option>
									<option value="rejected">Rejected</option>
									<option value="completed">Completed</option>
								</select>
							</div>
						</div>
					</div>

					{this.renderStatusDate()}

					<div className="form-group row mt-5">
						<div className="col-12 col-lg-3 offset-md-3">
							<button type="submit" className="btn btn-primary btn-lg" disabled={((this.state.loading)? true : false)}> {((this.state.loading)? <span className="fa fa-spinner fa-spin"></span> : '')} Update</button>
							<button
								className="btn text-primary"
								type="button"
								onClick={
									(e) => {
										e.preventDefault()
										history.goBack();
									}
								}>Cancel</button>
						</div>
					</div>
				</div>
			</form>


		)
	}
}

export default ActivityEditForm;