import React, { Component } from 'react';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import EventList from '../../../components/Auth/Activity/Event/EventList';
import ProjectList from '../../../components/Auth/Activity/Project/ProjectList';
import ResourceList from '../../../components/Auth/Activity/Resource/ResourceList';
import requireAuth from '../../../components/Auth/requireAuth';

class ScreenAdminActivity extends Component {

	state = {
		type: "event"
	}

	handleActivityType = (e, activity_type) => {
		this.setState(
			prevState => ({
				type: activity_type
			})
		);
	}

	renderActivity () {
		let activity_type = this.state.type;

		if(activity_type === 'event') {
			return <EventList/>;
		} else if(activity_type === 'project') {
			return <ProjectList/>
		} else if(activity_type === 'resource') {
			return <ResourceList/>
		}
	}

	render () {
		return (
			<section>
				<Header props={this.props} />

				<div className="activity-selector">
					<div className="container">
						<div className="row py-2">
							<div className="col">
								<label htmlFor="artform" className="info text-white">SHOW</label>
								<button className={"btn btn-activity mr-1" + ((this.state.type === "event") ? " active" : "")} onClick={(e) => this.handleActivityType(e, 'event')}>EVENTS</button>
								<button className={"btn btn-activity mr-1" + ((this.state.type === "project") ? " active" : "")}  onClick={(e) => this.handleActivityType(e, 'project')}>PROJECTS</button>
								<button className={"btn btn-activity" + ((this.state.type === "resource") ? " active" : "")} value="resource" onClick={(e) => this.handleActivityType(e, 'resource')}>RESOURCES</button>
							</div>
						</div>
					</div>
				</div>

				{this.renderActivity()}
			    <Footer />
			</section>
		)
	}
}

export default requireAuth(ScreenAdminActivity);