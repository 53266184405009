import React, { Component } from "react";
import Logo from "./Logo";
import MegaLinks from "./MegaLinks";
import SocialLinks from "./SocialLinks";
import TopLinks from "./TopLinks";

class Header extends Component {
  state = {
    isOpen: false,
  };

  toggleMenu = () => {
    console.log("test");
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => console.log(this.state)
    );
  };

  render() {
    return (
      <header>
        <div className="container header-container">
          <div className="row">
            <div className="col col-md-3">
              <a
                href="https://www.anewdirection.org.uk"
                id="logo"
                className="logo"
              >
                <Logo />
              </a>
            </div>
            <div className="col col-md-9 top-nav-container">
              <SocialLinks />
              <TopLinks />
              <MegaLinks />
            </div>
          </div>
        </div>

        <div className="mobile-menu-container">
          <button
            className={`mobile-menu-toggler ${
              this.state.isOpen ? " active" : ""
            }`}
            type="button"
            onClick={this.toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-plus-lg"
              viewBox="0 0 16 16"
            >
              <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
            </svg>
          </button>

          <div className={`mobile-menu ${this.state.isOpen ? " open" : ""}`}>
            <MegaLinks />
            <SocialLinks />
            <TopLinks />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
