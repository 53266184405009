import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import OrgList from '../../../components/Auth/Organisation/OrgList';
import requireAuth from '../../../components/Auth/requireAuth';
import ScreenAdminEditOrganisation from './Edit';
import ScreenAdminCreateNewOrganisation from './Create';

class ScreenAdminOrganisationsList extends Component {
	render () {
		const { match } = this.props;

		return (
			<section>
				<Header props={this.props} />
				<OrgList props={this.props} />
	    		<Footer />

				<Route path={`${match.path}/:id`} component={ScreenAdminEditOrganisation} />
				<Route path={`${match.path}/create`} component={ScreenAdminCreateNewOrganisation} />
			</section>
		);
	}
}

export default requireAuth(ScreenAdminOrganisationsList);