import React, { Component } from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';

const API_URL = process.env.REACT_APP_BASE_URL;

class ProjectItem extends Component {

	logEvent = (project) => {
		ReactGA.project({
			category: 'Project',
			action: 'More Information (' + project.title + ')',
			label: project.title + ' - ' + project.links.info
		});
	}

	renderAddress() {
		const address = this.props.project.contacts;

		const venue = address.venue_name ? address.venue_name + '<br/>' : '';
		const address1 = address.address_1 ? address.address_1 + '<br/>' : '';
		const address2 = address.address_2 ? address.address_2 + '<br/>' : '';
		const borough = address.borough ? address.borough + ', ' : '';

		if (venue !== '') {
			return (
				<div className="mt-2">
					<label className="mb-0 font-weight-bold" htmlFor="address">VENUE</label>
					<address id="address">
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: venue }} />
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: address1 }} />
						<p className="mb-0" dangerouslySetInnerHTML={{ __html: address2 }} />
						{borough}{address.postcode}
					</address>
				</div>
			);
		}
	}

	renderCost() {
		const project = this.props.project;

		return (
			<div className="mt-2">
				{project.cost !== null ? <p className="mb-0"><span className="font-weight-bold">COST: </span> £{project.cost}</p> : <p className="font-weight-bold mb-0">FREE</p>}
			</div>
		);
	}

	renderProjectDates() {
		const projectDate = moment(this.props.project.start_date).format("ddd, D MMM YYYY");
		const endDate = moment(this.props.project.end_date).format("ddd, D MMM YYYY");
		const projectTime = moment(this.props.project.start_date).format("H:mm");
		const endTime = moment(this.props.project.end_date).format("H:mm");
		const dates = this.props.project.dates;
		const has_multi_dates = this.props.project.has_multi_dates;

		if (has_multi_dates === 1 && dates.length > 1) {
			let project_dates = [];
			dates.map((date, index) => {
				project_dates.push(<li key={index}>{moment(date).format("ddd, D MMM YYYY H:mm A")}</li>);
			})

			return (<ul className="list-unstyled m-0 p-0">
				<li><strong>project DATES</strong></li>
				{project_dates}
			</ul>);
		}


		if (projectDate === endDate) {
			if (projectTime !== '0:00' && endTime !== '0:00') {
				return (
					<div className="mt-2">
						{projectDate}
						<br />
						{projectTime} - {endTime}
					</div>
				);
			} else {
				return (
					<div className="mt-2">
						{projectDate}
					</div>
				);
			}
		} else if (projectTime === '0:00' && endTime === '0:00') {
			return (
				<div className="mt-2">
					<span className="font-weight-bold">START </span> {projectDate}
					<br />
					<span className="font-weight-bold">FINISH </span> {endDate}
				</div>
			);
		} else {
			return (
				<div className="mt-2">
					<span className="font-weight-bold">START </span> {projectDate}  ({projectTime})
					<br />
					<span className="font-weight-bold">FINISH </span> {endDate}  ({endTime})
				</div>
			);
		}
	}

	renderArtformAndKeystages () {
		const project = this.props.project;
		const artforms = [];

		project.artforms.map((art, index) => {
			if (index === 0) {
				artforms.push(art.charAt(0).toUpperCase() + art.slice(1));
			} else {
				artforms.push(art.toLowerCase());
			}
		});

		return (
			<div className="mb-2">
				<div className="mb-0">
					{project.artforms.length !== 0 ?
						<span className="font-weight-bold">Artform</span>
						: <div><span className="font-weight-bold">Artform</span> - not specific</div>
					}
					{project.artforms.length !== 0 ? ' - ' + artforms.join(', ') : ''}
				</div>
				<div>
					{project.keystages.length !== 0 ?
						<span className="font-weight-bold">Key stage</span>
						: <div><span className="font-weight-bold">Key stage</span> - not specific</div>
					}

					{project.keystages.length !== 0 ? ' - ' + project.keystages.join(', ').replace('0', 'EY') : ''} 
				</div>
			</div>
		);
	}


	render() {
		const project = this.props.project;
		const projectLogo = (project.links.logo) ? <img className="p-1" src={API_URL + 'storage/' + project.links.logo} alt="" style={{ maxHeight: '200px', float: 'right' }} /> : '';

		return (
			<div className="event-detail border-bottom">
				<div className="container">
					<div className="row no-gutters mb-2 mb-md-0">
						<div className="col-12 col-md-8">
							<div className="event-detail-border tab-filter-item my-2">
								<div className="row">
									<div className="col-12 col-md-8">
										<h2 className="mb-1">{project.title}</h2>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-8">
									    <p className="font-weight-bold mb-0 mt-1">
											<a href={'/search/'+ project.organisation.id + '/projects'}>{project.organisation.title}</a>
										</p>
										<p>{project.info}</p>

										{this.renderArtformAndKeystages()} 
										
										{(project.support_send) ? <p className="font-weight-bold">Accessible for SEND students</p> : ''}
										{(project.links.info) ? <a onClick={() => this.logEvent(project)} href={project.links.info} className="font-weight-bold" target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right" aria-hidden="true"></i> MORE INFORMATION</a> : ''}
									</div>

									<div className="col-12 col-md">
										{projectLogo}
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 pl-0">
							<div className="p-md-2 tab-filter-item-border h-100 d-flex flex-column">
								<div className="d-flex">
									<div className="ml-auto">
										{(project.organisation.artsmark_partner === true) ? <a href="https://www.anewdirection.org.uk/artsmark" className="ml-1 d-inline-block"> <img src="/artsmark-logo.png" alt="artsmark logo" style={{ height: '30px' }} /></a> : ''}
										{(project.organisation.arts_award_supporter === true) ? <a href="https://www.artsawardsupporter.com/" className="ml-1 d-inline-block"> <img src="/arts-award-logo.png" alt="arts award logo" style={{ height: '30px' }} /></a> : ''}
									</div>
								</div>

								{this.renderProjectDates()}
								{this.renderCost()}
								{this.renderAddress()}

								{(project.delivered_in_school) ? <div className="font-weight-bold mt-2">Delivered in your school</div> : ''}
								{(project.webinar) ? <div className="font-weight-bold mt-2">Webinar</div> : ''}	
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default ProjectItem;