import React, { Component, Fragment } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

class Notfound extends Component {
	render() {
		return(
			<Fragment>
				<Header />
				<div className="container">
					<div className="row my-9">
						<div className="col">
							<h1>Sorry, that page can't be found</h1>
							<h2 className="lead mt-2">404 error, the page may have been deleted or otherwise lost along the way</h2>
						</div>
					</div>
				</div>
				<Footer />
			</Fragment>
		);		
	}
}

export default Notfound;