import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const EventItem = (props) => {
    const event = props.event;

	return (
		<tr>
			<th><NavLink to={'/admin/activity/'+event.id}>{ event.title }</NavLink></th>
			<th>{ event.status }</th>
			<th>{ event.published_at ? moment(event.published_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ event.created_at ? moment(event.created_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ event.start_date ? moment(event.start_date).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ event.organisation.title }</th>
			<th>{ event.event_type_name }</th>
		</tr>
	);
};

export default EventItem;