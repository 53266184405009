import React from 'react';

const TopLinks = () => (
	<ul class="top-nav">
  <li><a class="" href="https://www.anewdirection.org.uk/subscribe">Subscribe</a></li>
  <li><a class="" href="https://www.anewdirection.org.uk/about-us">About</a></li>
  <li><a class="" href="https://www.anewdirection.org.uk/blog">Blog</a></li>
  <li><a class="" href="https://www.anewdirection.org.uk/events">Events</a></li>
</ul>
)

export default TopLinks;