import React, { Component, Fragment } from 'react';
import axios from 'axios';
import history from '../../../history';
import { toast } from 'react-toastify';
import moment from 'moment';
import Keystages from '../../Form/Keystages';
import { 
	orgPutToApi,
	isNumericBoolean
} from '../../Utilities/adapter';

import ShowMoreText from 'react-show-more-text';

const ROOT_URL = process.env.REACT_APP_API_URL;
const BASE_URL = process.env.REACT_APP_API_URL+'api';
const API_URL = process.env.REACT_APP_API_URL+'api/organisations';

const INITIAL_STATE = {
	org: {
		title: '',
		summary: '',
		website: '',
		type: 1,
		artforms: [],
		arts_award_supporter: 0,
		artsmark_partner: 0,
		contact_email: '',
		address_1: '',
		address_2: '',
		borough: '',
		postcode: '',
		support_send: 0,
		keystages: [],
		contact_first_name: '',
		contact_last_name: '',
		artsmark_partner_offer: '',
		npo: 0
	},
	artforms: [],
	keystages: [],
	organisations: [],
	organisation_id: null,
	organisation_title: '',
	organisation_search: '',
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	chosenArtforms: [],
	accessToken: '',
	displayLogo: '',
	expanded: false
};

let URL = '';

class OrgEdit extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getArtformTypes();
		this.setState({ accessToken: localStorage.getItem('accessToken') });
	}

	init() {
		URL = API_URL+'/'+this.props.id;
		axios.get(API_URL+'/'+this.props.id)
			.then(response => {
				let org = response.data.data;
				let orgType = org.type ? org.type : 1;

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							title: org.title,
							type: orgType,
							summary: org.summary,
							description: org.description,
							website: org.links.website ? org.links.website : '',
							support_send: isNumericBoolean(org.support_send),
							artsmark_partner: isNumericBoolean(org.artsmark_partner),
							arts_award_supporter: isNumericBoolean(org.arts_award_supporter),
							artsmark_partner_offer: org.artsmark_partner_offer ? org.artsmark_partner_offer : '',
							contact_first_name: (org.contacts && org.contacts.first_name) ? org.contacts.first_name : '',
							contact_last_name: (org.contacts && org.contacts.last_name) ? org.contacts.last_name : '',
							contact_email: (org.contacts && org.contacts.email) ? org.contacts.email : '',
							address_1: (org.contacts && org.contacts.address_1) ? org.contacts.address_1 : '',
							address_2: (org.contacts && org.contacts.address_2) ? org.contacts.address_2 : '',
							borough: (org.contacts && org.contacts.borough) ? org.contacts.borough : '',
							postcode: (org.contacts && org.contacts.postcode) ? org.contacts.postcode : '',
							status: org.status,
							keystages: org.keystages,
							artforms: org.artforms,
							npo: org.npo,
							published_at: org.published_at
						},
						displayLogo: org.links.logo ? org.links.logo : '',
						keystages: org.keystages
					}),
					() => {
						this.mapArtforms();
						this.mapKeystages();
					}
				);
			})
			.catch(e => {
				history.push('/dashboard');
			});
	}

	mapArtforms = () => {
		let arraySelected = [];
		const { artforms } = this.state;

		if (this.state.org.artforms.length > 0) {
			this.state.artforms.map((key, selectionIndex) => {
				this.state.org.artforms.map((selected, index) => {
					if (key.name === selected) {
						artforms[selectionIndex].isChecked = true;
						arraySelected.push(key.id);
					}
				});
			});
		}

		this.setState(
			prevState => ({
				...prevState,
				org: {
					...prevState.org,
					artforms: arraySelected
				},
				artforms: artforms
			})
		)
	}

	mapKeystages = () => {
		if (this.state.keystages.length > 0) {
			this.state.keystages.map((key, index) => {
				let keyname = 'keystage'+key;

				this.setState(
					prevState => ({
						...prevState,
						[keyname]: true
					})
				);
			});
		}		
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const formData = orgPutToApi(this.state.org);

		axios.post(URL, formData, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					onClose: () => window.location.reload()
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				console.log(eData);
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	};

	onDelete = () => {
		axios.delete(URL, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken,
				'Content-Type': 'application/x-www-form-urlencoded'				
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false,
					onClose: () => history.push('/admin/organisations')
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				console.log(eData);
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	}

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					org: { ...prevState.org, [fieldName] : numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					org: { ...prevState.org, [fieldName] : fieldValue }
				})
			);
		}
	}

	onUploadPhoto = (e) => {
		const file = e.target.files[0];

		this.setState(
			prevState => ({
				org: {
					...prevState.org,
					logo: file
				}
			})
		);
	}

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);
		const { artforms } = this.state;

		if (e.target.checked) {
			this.state.artforms.map((key, index) => {
				if (parseInt(key.id) === fieldValue) {
					artforms[index].isChecked = true;

					this.setState(
						prevState => ({
							...prevState,
							org: {
								...prevState.org,
								artforms: [...prevState.org.artforms, fieldValue]
							},
							artforms: artforms
						})
					);
				}
			});
		} else {
			let selectedArtforms = [...this.state.org.artforms];
			let selectedIndex = selectedArtforms.indexOf(fieldValue);

			this.state.artforms.map((key, index) => {
				if (parseInt(key.id) === fieldValue) {
					artforms[index].isChecked = false;

					if (selectedIndex !== -1) {
						selectedArtforms.splice(selectedIndex, 1);

						this.setState(
							prevState => ({
								...prevState,
								org: {
									...prevState.org,
									artforms: selectedArtforms
								},
								artforms: artforms
							})
						);						
					}
				}
			});
		}
	}

	selectKeystageCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);
		let fieldName = e.target.name;

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						keystages: [...prevState.org.keystages, fieldValue]
					},
					keystages: [...prevState.org.keystages, fieldValue],
					[fieldName]: true
				})
			);

		} else {
			let keystages = [...this.state.org.keystages];

console.log(keystages);

			let index = keystages.indexOf(fieldValue);

			if (index !== -1) {
				keystages.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							keystages: keystages
						},
						keystages: keystages,
						[fieldName]: false
					})
				);				
			}
		}
	}

	getArtformTypes() {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			let data = response.data.map((key) => {
				let art = Object.assign({}, key);
				art.isChecked = false;

				return art;
			});

			this.setState(
				prevState => ({
					...prevState,
					artforms: data
				}), () => {
					this.init();
				}
			);
		});
	}

	readMore = () => {
		this.setState({ expanded: !this.state.expanded })
	}

	renderArtformSelection() {
		return (
			this.state.artforms.map(art =>
				<div key={art.id} 
					className="form-group" 
					style={{display: 'inline-block', width: '50%'}}>
					<input 
						id={art.id} 
						onChange={this.selectArtformCheckboxes} 
						type="checkbox" 
						name="artforms" 
						className="border mr-1" 
						value={art.id}
						checked={art.isChecked}
					/>
					<label htmlFor={art.id}>{art.name}</label>
				</div>
			)
		);
	}

	renderUploadPhoto() {
		if (this.state.displayLogo !== '') {
			return (
				<Fragment>
					<div className="form-group row">
						<label className="text-primary col-12 col-md-3">Logo</label>
							
						<div className="col-12 col-md-8 col-lg-6">
							<img src={ ROOT_URL+'storage/'+this.state.displayLogo} className="mw-100" alt="Logo" />
						</div>
					</div>

					<div className="form-group row">
						<label className="text-primary col-12 col-md-3">Upload Photo</label>

						<div className="col-12 col-md-8 col-lg-6">
							<small><i>Format accepted: PNG, JPG, GIF and BMP</i></small>
							<input onChange={this.onUploadPhoto} type="file" name="logo" className="form-control border mb-1" placeholder="Logo" />
						</div>
					</div>
				</Fragment>
			);
		}

		return (
			<div className="form-group row">
				<label className="text-primary col-12 col-md-3">Upload Photo</label>

				<div className="col-12 col-md-6">
					<input onChange={this.onUploadPhoto} type="file" name="logo" className="form-control border mb-1" placeholder="Logo" />
				</div>
			</div>
		);
	}

	renderStatusDate() {
		if (this.state.org.published_at) {
			return (
                <div className="form-group row">
                    <label 
                    	htmlFor="status" 
                    	className="form-label text-primary col-12 col-md-3">Published date:</label>

                    <div className="col-12 col-md-6">
                    	<p>{ moment(this.state.org.published_at).format("dddd, Do MMMM YYYY") }</p>
	                </div>
                </div>
			);
		}
	}

	renderDescription () {
		if (this.state.org.description !== null) {
			return (
				<div className="form-group row">
					<label htmlFor="orgDescription"
						className="text-primary col-12 col-md-3">Organisation Description: </label>
					<div className="col-12 col-md-8 col-lg-6">
						<ShowMoreText 
							lines={3}
							more="Read more"
							less="Show less"
							onClick={this.readMore}
						>
							{this.state.org.description}
						</ShowMoreText>
					</div>
				</div>
			);
		}
	}

	render () {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="form-group row">
					<label 
						className="form-label text-primary col-12 col-md-3">Status: </label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<p className="font-weight-bold text-uppercase">{this.state.org.status}</p>
					</div>
				</div>

				<div className="form-group row">
					<label 
						className="form-label text-primary col-12 col-md-3">Organisation name: </label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.org.title} 
							type="text" 
							name="title" 
							placeholder="Organisation name" 
							className="form-control border" 
							required />
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="orgSummary"
						className="text-primary col-12 col-md-3">Organisation Summary: </label>
					<div className="col-12 col-md-8 col-lg-6">
						<textarea 
							onChange={this.onInputChange} 
							value={this.state.org.summary} 
							id="orgSummary" 
							className="form-control border" 
							name="summary"
							rows="8"
							placeholder="Enter a brief summary of the organisation here" 
							required></textarea>
					</div>
				</div>

				{this.renderDescription()}

				<div className="form-group row">
					<label htmlFor="websiteURL" className="text-primary col-12 col-md-3">Website URL</label>
					<div className="col-12 col-md-8 col-lg-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.website}
							type="url"
							name="website" 
							className="form-control border" 
							placeholder="http://www.yourwebsiteaddress.com" 
						/>
					</div>
				</div>

				{ this.renderUploadPhoto() }

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Artforms</label>

					<div className="col-12 col-md-8 col-lg-6">
						{ this.renderArtformSelection() }
					</div>
				</div>

				<Keystages 
					onChange={this.selectKeystageCheckboxes}
					state={this.state}
				/>

				<div className="form-group row">
					<label htmlFor="npo" className="text-primary col-12 col-md-3">NPO</label>
					
					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.npo}
							checked={this.state.org.npo === 1}
							id="npo" 
							type="checkbox" 
							name="npo" 
							className="border" />
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="offerSupport" className="text-primary col-12 col-md-3">Is your offer designed / adaptable for disabled students?</label>
					
					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.support_send}
							checked={this.state.org.support_send}
							id="offerSupport" 
							type="checkbox" 
							name="support_send" 
							className="border" />
					</div>
				</div>

				<div className="form-group row">
					<label 
						htmlFor="artsmarkPartner" 
						className="col-12 col-md-3 text-primary">Artsmark Partner</label>

					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.artsmark_partner}
							checked={this.state.org.artsmark_partner}
							id="artsmarkPartner" 
							type="checkbox" 
							name="artsmark_partner" 
							className="border mr-1" 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="artsAwardSupporter" 
						className="text-primary col-12 col-md-3">Arts Award Supporter</label>
					
					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.org.arts_award_supporter}
							checked={this.state.org.arts_award_supporter}
							id="artsAwardSupporter" 
							type="checkbox" 
							name="arts_award_supporter" 
							className="border mr-1" 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label htmlFor="artsAwardPartnerOffer" className="col-12 col-md-3 text-primary">Your Artsmark Partner offer</label>

					<div className="col-12 col-md-8 col-lg-6">
						<textarea 
							onChange={this.onInputChange} 
							value={this.state.org.artsmark_partner_offer}
							id="artsAwardPartnerOffer"
							name="artsmark_partner_offer" 
							className="form-control border"
							rows="6"
							placeholder="Please provide a 200 word description"></textarea>
					</div>
				</div>

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Contact details</label>

					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange}
							id="contacts"
							value={this.state.org.contact_first_name} 
							type="text" 
							name="contact_first_name" 
							className="form-control border mb-1" 
							placeholder="Contact First name*" 
							required 
						/>
	
						<input 
							onChange={this.onInputChange} 
							id="contacts"
							value={this.state.org.contact_last_name} 
							type="text" 
							name="contact_last_name" 
							className="form-control border mb-1" 
							placeholder="Contact Last name*" 
							required 
						/>

						<input 
							onChange={this.onInputChange} 
							id="contacts"
							value={this.state.org.contact_email} 
							type="email" 
							name="contact_email" 
							className="form-control border" 
							placeholder="Contact email*" 
							required 
						/>
					</div>
				</div>

				<div className="form-group row">
					<label className="text-primary col-12 col-md-3">Address</label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange}
							id="contacts"
							value={this.state.org.address_1} 
							type="text" 
							name="address_1" 
							className="form-control border mb-1" 
							placeholder="Address 1"
						/>

						<input 
							onChange={this.onInputChange}
							id="contacts"
							value={this.state.org.address_2} 
							type="text" 
							name="address_2" 
							className="form-control border mb-1" 
							placeholder="Address 2" />

						<input 
							onChange={this.onInputChange}
							id="contacts" 
							value={this.state.org.borough} 
							type="text" 
							name="borough" 
							className="form-control border mb-1" 
							placeholder="Borough*" 
							required 
						/>

						<input 
							onChange={this.onInputChange}
							id="contacts" 
							value={this.state.org.postcode} 
							type="text" 
							name="postcode" 
							className="form-control border" 
							placeholder="Postcode*" 
							required 
						/>
					</div>
				</div>

                <div className="form-group row">
                    <label 
                    	htmlFor="status" 
                    	className="form-label text-primary col-12 col-md-3">Status</label>

                    <div className="col-12 col-md-8 col-lg-6">
                    	<div className="tab-filter-select-wrap">
		                    <select 
		                    	id="status"
		                    	onChange={this.onInputChange} 
		                    	value={this.state.org.status}
		                    	name="status"
								className="form-control tab-filter-select border"
								style={{ textTransform: 'capitalize'}}
		                    >
		    		            <option>{this.state.org.status}</option>
		                        <option value="active">Active</option>
		                        <option value="event_only">Event only</option>
							    <option value="rejected">Rejected</option>
								<option value="inactive">Inactive</option>
		                    </select>
		                </div>
	                </div>
                </div>

                { this.renderStatusDate() }

				<div className="form-group row mt-5">
					<div className="col-12 col-lg-3 offset-md-3">
						<button type="submit" className="btn btn-primary btn-lg">Update</button>
						<button 
							className="btn text-primary"
							type="button" 
							onClick={
								(e) => {
									e.preventDefault()
									history.goBack();
								}
							}>Cancel</button>
					</div>
				</div>
			</form>
		)
	}
}

export default OrgEdit;