import { 
	AUTH_USER, 
	AUTH_SUCCESS,
	AUTH_ERROR,
	USER,
	EVENT_TYPES,
	ARTFORM_TYPES
} from '../actions/types';

const INITIAL_STATE = {
	accessToken: '',
	authenticated: '',
	user: '',
	errorMessage: '',
	eventTypes: '',
	artformTypes: ''
};

export default function(state = INITIAL_STATE, action) {
	switch (action.type) {
		case AUTH_USER:
			return { ...state, accessToken: action.payload };
		case AUTH_SUCCESS:
			return { ...state, authenticated: action.payload };
		case AUTH_ERROR:
			return { ...state, errorMessage: action.payload };
		case USER:
			return { ...state, user: action.payload };
		case EVENT_TYPES:
			return { ...state, eventTypes: action.payload };
		case ARTFORM_TYPES:
			return { ...state, artformTypes: action.payload };
		default: 
			return state;
	}
}