import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import { install } from 'ga-gtag';
import App from './App';

install('G-EPHBNZ80JT');

const store = createStore(
    reducers,
    {
        auth: { authenticated: localStorage.getItem('authenticated') }
    },
    applyMiddleware(reduxThunk)
);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
	document.querySelector('#root')
);
