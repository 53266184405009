import React, { Component, Fragment } from 'react';
import { PulseLoader } from 'react-spinners';
import axios from 'axios';
import ProjectItem from './ProjectItem';
import ProjectFilter from './ProjectFilter';
import Pagination from 'react-js-pagination';

// import '../../../sass/modules/pagination.scss';
import { Scrollbars } from 'react-custom-scrollbars';

const API_URL = process.env.REACT_APP_API_URL+'api/projects';
const INITIAL_STATE = {
	projects: [],
	next_page: API_URL,
	loading: false,
	filtered: false,
	init_load_more: false,
	pagination: [],
	sorted: true,
	project: {
		title: '',
		type: 0,
		organisation_title: '',
		artsmark_partner: 0,
		support_send: 0,
		keystages: [],
		artform: 0,
		venue_name: '',
		postcode: '',
		status: '',
		sort: 'title',
		order: 'asc',
		free: 0,
		page: 1
	}
};

class ProjectList extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.init();
		this.projectFilter = React.createRef();

		if (!this.state.projects > 0) {
			this.setState({
				loading: false
			})
		}
	}

	init() {
		if(!this.state.loading) {
			this.setState({
				loading: true
			});

			axios.get(this.state.next_page)
				.then(response => {
					const paginator = response.data.meta.pagination;
					const projects = response.data.data;

					if(projects.length){
						this.setState( 
							prevState => ({
								...prevState,
								project: {
									...prevState.project
								},
								projects: projects,
								next_page: paginator.links.next,
								loading: false,
								init_load_more: true,
								pagination: paginator
							}), () => {
								const href = window.location.href;
								let url = href + '?page=' + this.state.project.page;
			
								if (window.location.search.indexOf('page') > 0) {
									const page = this.state.project.page;
									url = '?page=' + page;
								}
			
								this.saveState(url, this.state.projects, this.state.project.page, this.state.pagination);
							}
						);					
					} else {
						this.setState(
							prevState => ({
								...prevState,
								loading: false
							})
						);
					}

					if(paginator.links.next){
						this.setState({ init_load_more: true });
					}
				});
		}
	}

	onClearFilter = () => {
		this.setState(INITIAL_STATE, () => {
			this.init();
			this.projectFilter.current.getArtformTypes();
		});
	};

	onFilteredResults = (props) => {
		this.setState({
			projects: [],
			init_load_more: false,
			filtered: true,
			project: {
				keyword: props.project.keyword,
				type: props.project.type,
				organisation_title: props.project.organisation_title,
				support_send: props.project.support_send,
				keystages: props.project.keystages,
				postcode: props.project.postcode,
				artform: props.project.artform,
				sort: props.project.sort,
				status: props.project.status,
				free: props.project.free,
				artsmark_partner: props.project.artsmark_partner,
				page: props.page,		
			}
		}, () => {
			this.getFilteredResults();
		});
	}

	sortOrder(val) {
		const projectStatus = this.state.project.status === '' ? 'active' : this.state.project.status;

		if (!this.state.sorted) {
			this.setState(
				prevState => ({
					...prevState,
					projects: [],
					init_load_more: false,
					filtered: true,
					project: {
						...prevState.project,
						status: projectStatus,
						sort: val,
						order: 'asc'
					},
					sorted: true
				}), () => {
					this.getFilteredResults();
				}
			);				
		} else {
			this.setState(
				prevState => ({
					...prevState,
					projects: [],
					init_load_more: false,
					filtered: true,
					project: {
						...prevState.project,
						status: projectStatus,
						sort: val,
						order: 'desc'
					},
					sorted: false
				}), () => {
					this.getFilteredResults();
					
				}
			);
		}
	};

	getFilteredResults = () => {
		if (!this.state.loading) {
			this.setState({
				loading: true
			});

			axios.post(API_URL+'/filter', {
				keyword: this.state.project.keyword,
				type: this.state.project.type,
				organisation_title: this.state.project.organisation_title,
				support_send: this.state.project.support_send,
				keystages: this.state.project.keystages,
				postcode: this.state.project.postcode,
				artform: this.state.project.artform,
				sort: this.state.project.sort,
				page: this.state.project.page,
				free: this.state.project.free,
				status: this.state.project.status,
				artsmark_partner: this.state.project.artsmark_partner,
				order: this.state.project.order
			})
			.then(response => {
				const projects = response.data.data;
				const pagination = response.data.meta.pagination;

				if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
					this.setState({
						projects: projects,
						loading: false,
						pagination: pagination,
					}, () => {
						const href = window.location.href;
			            let url = href + '?page=' + this.state.project.page;
						
						if (window.location.search.indexOf('page') > 0) {
							const page = this.state.project.page;
							url = '?page=' + page;
						}

						this.saveState(url, this.state.projects);

					});
				} else {
					this.setState({
						projects: projects,
						loading: false,
						pagination: pagination,
					});				
				}
			});
		}
	};

	handlePageChange = (pageNumber) => {
        let url = '';

		if (window.location.search.indexOf('page') > 0) {
			url = API_URL + '?page=' + pageNumber;
		}

		this.setState(
			prevState => ({
				...prevState,
				next_page: url,
				project: {
					...prevState.project,
					page: pageNumber
				},
				pagination: {
					...prevState.pagination,
					current_page: pageNumber
				}
			}), () => {
				if (this.state.init_load_more) {
					this.init();
				} else {
					this.getFilteredResults();					
				}
			}
		)
	}

	saveState(url, container, page, pagination) {
		window.history.pushState({
			path: url,
			filtersHtml: container,
			page: page,
			pagination: pagination,
		}, 'Project list', url);
	}

	renderResults() {
		if (this.state.projects.length) {
			return (
				this.state.projects.map(project => 
					<ProjectItem key={project.id} project={project}/>
				)
			);
		}
	}

	renderLoadStatus() {
		if (this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<PulseLoader
								color={'#28A2FB'}
								loading={this.state.loading}
							/>
						</div>
					</div>
				</div>
			);
		}

		if (!this.state.loading && this.state.projects.length === 0) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);			
		}
	}

	renderPagination() {
		const pagination = this.state.pagination;

		if (pagination.total_pages > 1 && this.state.projects.length > 0 && !this.state.loading) {
			return (
				<section className="bg-grey">
					<div className="container">
						<nav aria-label="Pagination nav">
							<Scrollbars autoHide autoHeight>
								<Pagination 
									activePage={pagination.current_page}
									itemsCountPerPage={pagination.per_page}
									totalItemsCount={pagination.total}
									pageRangeDisplayed={pagination.count}
									onChange={this.handlePageChange}
									itemClassName="page-item"
									linkClassName="page-link"
									firstPageText={<span className="fa fa-angle-double-left"></span>}
									lastPageText={<span className="fa fa-angle-double-right"></span>}
									nextPageText={<span className="fa fa-angle-right"></span>}
									prevPageText={<span className="fa fa-angle-left"></span>}
								/>
							</Scrollbars>
						</nav>
					</div>
				</section>
			)			
		}
	}

	renderNumberResults() {
		const results = this.state.pagination;

		if (this.state.filtered) {
			return (
				<section className="bg-grey">
					<div className="container">
						<div className="row">
							<div className="col">
								<p className="my-2">{results.total} found in alphabetical order</p>
							</div>
						</div>
					</div>
				</section>
			)			
		} else {
			return (
				<section className="bg-grey">
					<div className="container">
						<div className="row">
							<div className="col">
								<p className="my-2">Total of {results.total} found in alphabetical order</p>
							</div>
						</div>
					</div>
				</section>
			)		
		}
	}

	render() {
		return (
			<Fragment>
				<ProjectFilter 
					ref={this.projectFilter}
					onClearFilter={this.onClearFilter} 
					onFilteredResults={this.onFilteredResults}
				/>

				{this.renderNumberResults()}

				<div className="border-top">
					{this.renderPagination()}
				</div>

				<section className="dashboard-results py-0">
				    <div className="dashboard-results-orgs container">
				        <div className="table-responsive">
							<table className="table dashboard-results-table">
								<thead>
									<tr>
										<th style={{borderTop: '0'}}>
											<button
												className="table-head-button"
												onClick={() => this.sortOrder('title')}
											>
												Name <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0'}}>Status</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('published')}
											>
												Date published <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('submitted')}
											>
												Submitted on <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('start')}
											>
												Start date <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
										<th style={{borderTop: '0', whiteSpace: 'nowrap'}}>
											<button 
												className="table-head-button" 
												onClick={() => this.sortOrder('organisation')}
											>
												Organisation <i className="fa fa-sort ml-1" aria-hidden="true"></i>
											</button>
										</th>
									</tr>
								</thead>

								<tbody>
									{ this.renderResults() }
								</tbody>

							</table>
						</div>
					</div>
				</section>
				
				{ this.renderLoadStatus() }
				{ this.renderPagination() }
			</Fragment>
		)
	}
}

export default ProjectList;