import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const ProjectItem = (props) => {
    const project = props.project;

	return (
		<tr>
			<th><NavLink to={'/admin/activity/'+project.id}>{ project.title }</NavLink></th>
			<th>{ project.status }</th>
			<th>{ project.published_at ? moment(project.published_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ project.created_at ? moment(project.created_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ project.start_date ? moment(project.start_date).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ project.organisation.title }</th>
		</tr>
	);
};

export default ProjectItem;