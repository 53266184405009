import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

class TagItem extends Component {
	render() {
		const { tag } = this.props;

		return (
			<Fragment>
				<tr>
					<th><Link to={'tags/'+tag.id}>{tag.name}</Link></th>
					<th>{tag.slug}</th>
					<th>{tag.public ? 'Yes' : 'No'}</th>
					<th>{ tag.created_at ? moment(tag.created_at).format("D MMM YYYY, h:mm A") : ''}</th>
				</tr>
			</Fragment>
		);		
	}
}

export default TagItem;