import React, { Component } from 'react';
import ReactGA from 'react-ga';

const API_URL = process.env.REACT_APP_BASE_URL;

class ResourceItem extends Component {

	logEvent = (resource) => {
		ReactGA.event({
			category: 'Resource',
			action: 'More Information (' + resource.title + ')',
			label: resource.title + ' - ' + resource.links.info
		});
	}

	renderCost() {
		const resource = this.props.resource;

		return (
			<div className="mt-2">
				{resource.cost !== null ? <p className="mb-0"><span className="font-weight-bold">COST: </span> £{resource.cost}</p> : <p className="font-weight-bold mb-0">FREE</p>}
			</div>
		);
	}

	renderArtformAndKeystages () {
		const resource = this.props.resource;
		const artforms = [];

		resource.artforms.map((art, index) => {
			if (index === 0) {
				artforms.push(art.charAt(0).toUpperCase() + art.slice(1));
			} else {
				artforms.push(art.toLowerCase());
			}
		});

		return (
			<div className="mb-2">
				<div className="mb-0">
					{resource.artforms.length !== 0 ?
						<span className="font-weight-bold">Artform</span>
						: <div><span className="font-weight-bold">Artform</span> - not specific</div>
					}
					{resource.artforms.length !== 0 ? ' - ' + artforms.join(', ') : ''}
				</div>
				<div>
					{resource.keystages.length !== 0 ?
						<span className="font-weight-bold">Key stage</span>
						: <div><span className="font-weight-bold">Key stage</span> - not specific</div>
					}

					{resource.keystages.length !== 0 ? ' - ' + resource.keystages.join(', ').replace("0", "EY") : ''}
				</div>
			</div>
		);
	}


	render() {
	    const resource = this.props.resource;
	    const resourceLogo = (resource.links.logo) ? <img className="p-1" src={API_URL+'storage/'+resource.links.logo } alt="" style={{maxHeight: '200px', float: 'right'}} /> : '';

		return (
			<div className="event-detail border-bottom">
				<div className="container">
					<div className="row no-gutters mb-2 mb-md-0">
						<div className="col-12 col-md-8">
							<div className="event-detail-border tab-filter-item my-2">
								<div className="row">
									<div className="col-12 col-md-8">
										<h2 className="mb-1">{ resource.title }</h2>
									</div>
								</div>

								<div className="row">
									<div className="col-12 col-md-8">
										<p className="font-weight-bold mb-0 mt-1">
											<a href={'/search/'+ resource.organisation.id + '/resources'}>{resource.organisation.title}</a>
										</p>
										
										<p>{ resource.info }</p>

										{this.renderArtformAndKeystages()}
										
										{(resource.support_send) ? <p className="font-weight-bold">Accessible for SEND students</p> : ''}
			                            {(resource.links.info)? <a onClick={() => this.logEvent(resource)} href={ resource.links.info } className="font-weight-bold" target="_blank" rel="noopener noreferrer"><i className="fa fa-angle-double-right" aria-hidden="true"></i> MORE INFORMATION</a> : ''}
									</div>

									<div className="col-12 col-md">
										{ resourceLogo }
									</div>
								</div>
							</div>
						</div>

						<div className="col-12 col-md-4 pl-0">
							<div className="p-md-2 tab-filter-item-border h-100 d-flex flex-column">
								<div className="d-flex">
									<h3 className="text-uppercase text-danger mb-0">
										{ resource.resource_type_name }
									</h3>
									
									<div className="ml-auto">
										{(resource.organisation.artsmark_partner === true) ? <a href="https://www.anewdirection.org.uk/artsmark" className="ml-1 d-inline-block"> <img src="/artsmark-logo.png" alt="artsmark logo" style={{ height:'30px' }} /></a> : ''}
										{(resource.organisation.arts_award_supporter === true) ? <a href="https://www.artsawardsupporter.com/" className="ml-1 d-inline-block"> <img src="/arts-award-logo.png" alt="arts award logo" style={{ height: '30px' }} /></a> : ''}
									</div>
								</div>

								{ this.renderCost() }						
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
};

export default ResourceItem;