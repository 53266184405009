import React, { Component } from 'react';

class Footer extends Component {
	render () {
		const footerCredit = 'Site produced by Steve Woodward and Jim Beck at';

		return (
			<footer id="footer" className="vcard footer-home">
			    <div className="container">
			        <div id="footer-information" className="row footer-information">
			            <div className="col">
			                <div id="address">
			                    <h2 className="org">A New Direction</h2>

			                    <p className="adr">
			                        A New Direction<br />
			                        <span className="street-address">Good Growth Hub, Unit 1-28, Echo Building<br />
			                        East Bay Lane</span>
			                        <span className="locality">London</span>
			                        <span className="postal-code">E15 2SJ</span>
			                    </p>
			                </div>

			                <div className="links-wrapper">
			                    <div className="quicklinks link-title">
			                        <h2>
			                            Quicklinks
			                        </h2>

			                        <div>
			                            <ul className="footer-list">
			                                <li>
			                                    <a href="https://www.anewdirection.org.uk/contact-us">Contact</a>          
			                                </li>
			                                <li>
			                                    <a href="https://www.anewdirection.org.uk/about-us/our-team">Our team</a>          
			                                </li>
			                                <li>
			                                    <a href="https://www.anewdirection.org.uk/about-us/our-supporters">Our supporters</a>          
			                                </li>
			                                <li>
			                                    <a href="https://www.anewdirection.org.uk/our-events">Calendar</a>
			                                </li>
			                            </ul>
			                        </div>
			                    </div>
			                </div>

			                <div className="links-wrapper">
			                    <div className="courtesy">
			                        <h2>More</h2>
			                        <ul className="footer-list">
			                            <li><a href="/accessibility">Accessibility</a></li>
			                            <li><a href="/privacy-policy-and-cookies">Privacy Policy and Cookies</a></li>
			                            <li><a href="/rss-feeds">RSS Feeds</a></li>
			                            <li><a href="/site-map">Site map</a></li>
			                        </ul>
			                    </div>
			                </div>

			                <div id="logos">
			                	<img src={'/AND-ACE_white.svg'} alt="A New Direction. Lottery funded. Supported using public funding by Arts Council England. footer logos" />
			                </div>
			            </div>
			        </div>

			        <div className="row mt-3">
				        <div className="col">
				            <div className="d-flex w-100 footer-bottom-wrap">
					            <div className="footer-bottom-wrap-item" style={{float: 'left'}} >
					                <p className="tel"><a href="tel:+442076082132">020 7608 2132</a><br/><br/>Charity number: 1126216</p>
					                <p><a href="mailto:lookup@anewdirection.org.uk" className="email">lookup@anewdirection.org.uk</a></p>
					            </div>

					            <div className='footer-bottom-wrap-item ml-md-auto'>
					                <div>
					                    <p className="mb-0">All materials © A New Direction London Ltd.</p>
					                    <p className="mb-0">All rights reserved.</p>
					                    <p className="mb-0 credit"><a href="https://www.uxblondon.com" target="_blank">Website design and development by <strong>UXB London</strong></a></p>
					                   	<p className="mb-0 credit"><a href="https://www.anewdirection.org.uk/" target="_blank">{footerCredit} <strong className="text-secondary">A New Direction</strong></a></p>
					                </div>
					            </div>
					        </div>
					    </div>
				    </div>
			    </div>
			</footer>
		)
	}
}

export default Footer;