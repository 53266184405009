import React, { Component, Fragment } from 'react';
import Header from '../../components/Auth/Header';
import Footer from '../../components/Auth/Footer';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { toast } from 'react-toastify';
import history from '../../history';
import { ClipLoader } from 'react-spinners';

class Signout extends Component {
	componentDidMount() {
		this.props.signout();

		toast.info('Redirecting to homepage...', {
			position: toast.POSITION.TOP_CENTER,
			autoClose: 2000,
			onClose: () => history.push('/')
		});
	}

	render() {
		return(
			<Fragment>
				<Header />

				<section className="container">
					<div className="row justify-content-center align-items-center" style={{ height: '100vh' }}>
						<div className="col">
							<h1 className="text-center">
								<ClipLoader 
									sizeUnit={"px"}
									size={80}
								/>
							</h1>
						</div>
					</div>
				</section>
				
				<Footer />
			</Fragment>
		);
	}
}

export default connect(null, actions)(Signout);