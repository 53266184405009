import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';


const BASE_URL = process.env.REACT_APP_API_URL+'api';
let keystages = [];
const INITIAL_STATE = {
	project: {
		title: '',
		keyword: '',
		type: 0,
		organisation_title: '',
		artsmark_partner: 0,
		support_send: 0,
		keystages: [],
		artform: 0,
		venue_name: '',
		postcode: '',
		free: '',
		status: 'active'
	},
	showFilter: true,
	allKeystages: false,
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	artforms: [],
	projects: [],
	page: 1
};

class ProjectFilter extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.getArtformTypes();
	}

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	};

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = this.isNumbericBoolean(boolean);

			this.setState(
				prevState => ({ 
					project: { ...prevState.project, [fieldName] : numericBoolean }
				}),
				() => this.handleSubmit
			);
		} else {
			this.setState(
				prevState => ({
					project: { ...prevState.project, [fieldName] : fieldValue }
				}),
				() => this.handleSubmit
			);
		}
	}

	onPostcodeChange = (event) => {
		let fieldValue = event.target.value;

		this.setState(
			prevState => ({
				project: { 
					...prevState.project, 
					postcode : fieldValue,
					sort: 'distance'
				}
			}),
			() => this.handleSubmit
		);
	};

	isNumbericBoolean = (boolean) => {
		if (boolean === true) {
			return 1;
		} else {
			return 0;
		}
	};

	selectOption = (e) => {
		let fieldValue = e.target.value;
		let fieldName = e.target.name;

		this.setState(
			prevState => ({
				project: { ...prevState.project, [fieldName] : fieldValue }
			}),
			() => this.handleSubmit
		);
	};

	selectKeystage = (e) => {
		let fieldValue = e.target.value;
		let keyName = e.target.id;

		if (fieldValue === 'all') {
			if (e.target.checked) {
				keystages.push(0);
				keystages.push(1);
				keystages.push(2);
				keystages.push(3);
				keystages.push(4);
				keystages.push(5);

				this.setState(
					prevState => ({
						project: { ...prevState.project, keystages: keystages },
						allKeystages: true,
						keystage0: true,
						keystage1: true,
						keystage2: true,
						keystage3: true,
						keystage4: true,
						keystage5: true
					})
				);
			} else {
				keystages = [];

				this.setState(
					prevState => ({
						project: { ...prevState.project, keystages: keystages },
						allKeystages: false,
						keystage0: false,
						keystage1: false,
						keystage2: false,
						keystage3: false,
						keystage4: false,
						keystage5: false,
					})
				);
			}

		} else {
			if (e.target.checked) {
				keystages.push(parseInt(fieldValue));

				this.setState(
					prevState => ({
						project: { ...prevState.project, keystages: keystages },
						[keyName]: true
					})
				);				
			} else {
				keystages.pop();

				this.setState(
					prevState => ({
						project: { ...prevState.project, keystages: keystages },
						[keyName]: false
					})
				);	
			}
		}
	};
	
	handleSubmit = (e) => {
		e.preventDefault();

		this.props.onFilteredResults(this.state);
	};

	onClearFilter = (e) => {
		e.preventDefault();

		this.setState(INITIAL_STATE);

		this.props.onClearFilter();
	};

	onHideFilters = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: false });			
		} else {
			this.setState({ showFilter: true });
		}
	};

	renderArtformSelection() {
		return (
			<select 
				onChange={this.onInputChange} 
				value={this.state.project.artform} 
				name="artform" 
				id="artform" 
				className="form-control tab-filter-select text-primary"
			>
				<option value={0}>CHOOSE</option>
				{this.state.artforms.map(art =>
					<option key={art.id} value={art.id}>{art.name}</option>
				)}
			</select>
		);
	}

	render () {
		return (
			<section className="dashboard-filter events-filter">
			    <div className="container">
			        <form className="row py-3">
	            		<div className="col-12 col-md-7 mb-4 mb-md-0">
							
							<div className="row d-flex mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
				    				<label htmlFor="keystage" className="text-white">Key stage</label>
				    			</div>

				    			<div className="col-12 col-md">
					    			<div id="keystage" className="d-flex justify-content-between text-white">
					    				<div>
						    				<label htmlFor="all" className="mr-1 mb-0">All</label>
						    				<input onChange={this.selectKeystage} checked={this.state.allKeystages} id="all" type="checkbox" name="keystages" value="all" />
						    			</div>
										<div>
						    				<label htmlFor="keystage0" className="mx-1 mb-0">EY</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage0} id="keystage0" type="checkbox" name="keystages" value={0} />
						    			</div>
						    			<div>
						    				<label htmlFor="keystage1" className="mx-1 mb-0">1</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage1} id="keystage1" type="checkbox" name="keystages" value={1} />
						    			</div>
						    			<div>
						    				<label htmlFor="keystage2" className="mx-1 mb-0">2</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage2} id="keystage2" type="checkbox" name="keystages" value={2} />
						    			</div>
						    			<div>
						    				<label htmlFor="keystage3" className="mx-1 mb-0">3</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage3} id="keystage3" type="checkbox" name="keystages" value={3} />
						    			</div>
						    			<div>
						    				<label htmlFor="keystage4" className="mx-1 mb-0">4</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage4} id="keystage4" type="checkbox" name="keystages" value={4} />
						    			</div>
						    			<div>
						    				<label htmlFor="keystage5" className="mx-1 mb-0">5</label>
						    				<input onChange={this.selectKeystage} checked={this.state.keystage5} id="keystage5" type="checkbox" name="keystages" value={5} />
						    			</div>
					    			</div>
					    		</div>
				    		</div>

							<div className="row mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
									<label htmlFor="artform" className="text-white">Artform</label>
								</div>

								<div className="col">
									{ this.renderArtformSelection() }
								</div>
							</div>

							<div className="row mb-2">
								<div className="col-12 col-lg text-white d-flex align-items-center mb-2 mb-lg-0">
				    				<label className="mb-0 mr-auto mr-lg-14" htmlFor="artsmark_partner" style={{marginRight: '15px'}}>Artsmark Partner</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="artsmark_partner" 
				    					type="checkbox" 
				    					checked={this.state.project.artsmark_partner} 
				    					name="artsmark_partner" 
				    				/>
				    			</div>

				    			<div className="col-12 col-lg text-white d-flex justify-content-end align-items-center">
					    			<label className="mb-0 mr-auto mr-lg-4" htmlFor="eventSuitable">Suitable for SEND</label>
									<input 
										onChange={this.onInputChange} 
										value={this.state.project.support_send} 
										checked={this.state.project.support_send} 
										id="eventSuitable" 
										type="checkbox" 
										name="support_send" 
									/>
						    	</div>
					    	</div>

					    	<div className="row mb-2">
					    		<div className="col-12 col-lg text-white d-flex justify-content-end align-items-center">
					    			<label className="mb-0 mr-auto mr-lg-4" htmlFor="eventFree">Free</label>
									<input 
										onChange={this.onInputChange} 
										value={this.state.project.free} 
										checked={this.state.project.free} 
										id="eventFree" 
										type="checkbox" 
										name="free" 
									/>
								</div>
					    	</div>

					    	<div className="row">
					    		<div className="col offset-lg-3">
			                		<NavLink to="/admin/activities/project/create" className="dashboard-form-modal btn btn-success text-white font-weight-bold"><i className="fa fa-plus" aria-hidden="true"></i> ADD PROJECT</NavLink>
								</div>
							</div>
			            </div>

			            <div className="col-12 col-md-5 d-md-flex flex-md-column">
							<div className="row mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
									<label htmlFor="eventByName" className="text-white">Keyword</label>
								</div>

								<div className="col">
									<input 
										onChange={this.onInputChange} 
										value={this.state.project.keyword} 
										id="eventByName" 
										type="text" 
										name="keyword" 
										placeholder="SEARCH BY KEYWORD" 
										className="form-control" 
									/>
								</div>
							</div>

							<div className="row mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
									<label htmlFor="eventByOrg" className="text-white">Organisation</label>
								</div>
								<div className="col">
									<input 
										onChange={this.onInputChange} 
										value={this.state.project.organisation_title} 
										id="eventByOrg" 
										type="text" 
										name="organisation_title" 
										placeholder="FIND EVENT BY ORGANISATION" 
										className="form-control" 
									/>
								</div>
							</div>

			                <div className="row mb-2 flex-column flex-lg-row">
			                    <div className="col-12 col-md-3">
			                    	<label htmlFor="status" className="text-white">Status</label>
			                    </div>

			                    <div className="col">
				                    <div className="tab-filter-select-wrap">
					                    <select 
					                    	onChange={this.onInputChange} 
					                    	value={this.state.project.status}
					                    	name="status"
					                    	className="form-control tab-filter-select text-primary"
					                    >
					                        <option value="active">ACTIVE</option>
					                        <option value="draft">DRAFT</option>
										    <option value="rejected">REJECTED</option>
											<option value="completed">COMPLETED</option>
					                    </select>
					                </div>
					            </div>
			                </div>

							<div className="row d-flex mt-auto">
								<div className="col offset-lg-3">
									<button onClick={this.handleSubmit} className="btn btn-dark mb-md-0 font-weight-bold text-white">SHOW RESULTS</button>
									<button onClick={this.onClearFilter} className="btn text-light font-weight-bold text-underline">CLEAR FILTERS</button>
								</div>
							</div>
						</div>
			        </form>
			    </div>
			</section>
		);
	}
}

export default ProjectFilter;