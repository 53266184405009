import React, { Component } from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import FrontNav from '../../../components/FrontNav';
import TabNav from '../../../components/TabNav';
import ResourceList from '../../../components/Activity/Resource/ResourceList';

class ScreenResourcesFreeList extends Component {
	render () {
		return (
			<section>
				<Header />
				<FrontNav match={this.props.match} />
				<TabNav match={this.props.match} />
			    <ResourceList free={1} />
			    <Footer />
			</section>
		)
	}
}

export default ScreenResourcesFreeList;