import React from 'react';
import { NavLink } from 'react-router-dom';

const TabNav = ({ match }) => {

    let path = "";
    if(match) {
        let path_array = match.path.split('/');
        path = path_array[path_array.length - 1];
    }

    return (
        <section className="bg-grey">
            <section className="container">
                <div className="row">
                    <div className="col">
                        <div className="tab-filter-buttons">
                            <NavLink to="/resources" exact className={"tab-filter-btn btn-tab-blue btn btn-white btn-lg border-bottom-0 mr-2" + ((path === 'resources')? ' active' : '')} style={{ whiteSpace: 'nowrap' }}>RESOURCES</NavLink>
                            <NavLink to="/projects" exact className={"tab-filter-btn btn-tab-blue btn btn-white btn-lg border-bottom-0 mr-2" + ((path === 'projects')? ' active' : '')} style={{ whiteSpace: 'nowrap' }}>PROJECTS</NavLink>
                            <NavLink to="/events" exact className={"tab-filter-btn btn-tab-blue btn btn-white btn-lg border-bottom-0 mr-2" + ((path === 'events')? ' active' : '')} style={{ whiteSpace: 'nowrap' }}>EVENTS</NavLink>
                            <NavLink to="/organisations" exact className={"tab-filter-btn btn-tab-blue btn btn-white btn-lg border-bottom-0" + ((path === 'organisations')? ' active' : '')} style={{ whiteSpace: 'nowrap' }}>ORGANISATIONS</NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
}

export default TabNav;