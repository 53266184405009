import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import Header from '../../components/Auth/Header';
import Footer from '../../components/Auth/Footer';
import requireAuth from '../../components/Auth/requireAuth';
import './Dashboard.css';
import moment from 'moment';
import ReactAutocomplete from 'react-autocomplete';

const ORG_URL = process.env.REACT_APP_API_URL+'api/organisations/filter';
const ACTIVITIES_URL = process.env.REACT_APP_API_URL+'api/activities/filter';
const INITIAL_STATE = {
	organisation_search: '',
	organisations: [],
	unpublished_organisations: [],
	activity_search: '',
	activities: [],
	unpublished_activities: [],
	activity_pagination: [],
	org_pagination: [],
	selected_activity_id: '',
	selected_org_id: ''
};

class Dashboard extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.getUnpublishedActivities();
		this.getUnpublishedOrganisations();
		this.getAllOrgs();
		this.getAllActivities();
	}

	getAllOrgs(org) {
		axios.post(ORG_URL, {
			title: org,
			not_status: 'rejected'
		})
		.then(response => {
			const orgs = response.data.data;

			this.setState({
				organisations: orgs
			});
		});
	}

	getAllActivities(title) {
		axios.post(ACTIVITIES_URL, {
			title: title,
			status: 'active'
		})
		.then(response => {
			const activities = response.data.data;

			this.setState({
				activities: activities
			});
		});
	}

	navigateToOrg(title) {
		axios.post(ORG_URL, {
			title: title
		})
		.then(response => {
			const activity = response.data.data;
			this.props.history.push('/admin/organisation/'+activity[0].id);
		});
	}

	navigateToActivity(title) {
		axios.post(ACTIVITIES_URL, {
			title: title
		})
		.then(response => {
			const activity = response.data.data;
			this.props.history.push('/admin/activity/'+activity[0].id);
		});
	}

	getUnpublishedActivities () {
		axios.post(ACTIVITIES_URL, {
			status: 'draft'
		})
		.then(response => {
			const results = response.data.data;
			const pagination = response.data.meta.pagination;

			console.log(results);

			this.setState(
				prevState => ({
					...prevState,
					unpublished_activities: results,
					activity_pagination: pagination
				})
			);
		});
	}

// loads all unpublished orgs 
	getUnpublishedOrganisations () {
		axios.post(ORG_URL, {
			sort: 'published',
			status: 'draft'
		})
		.then(response => {
			const results = response.data.data;
			const pagination = response.data.meta.pagination;

			this.setState(
				prevState => ({
					...prevState,
					unpublished_organisations: results,
					org_pagination: pagination
				})
			);
		});
	}
// loads all unpublished activities 
	renderUnpublishedActivitiesList () {
		if (this.state.unpublished_activities.length > 0) {
			return (
				this.state.unpublished_activities.map(activity => 
	                <tr key={activity.id}>
	                    <th><NavLink to={"/admin/activity/"+activity.id}>{activity.title}</NavLink></th>
	                    <th>{activity.status}</th>
						<th>{activity.created_at ? moment(activity.created_at).format('D MMM YYYY, h:mm A') : ''}</th>
	                    <th>{activity.organisation.title}</th>
	                    <th className="text-uppercase">{activity.type}</th>
	                </tr>
				)
			);			
		} else {
			return (
				<tr className="container">
					<th className="py-2">
						<h1>No results found</h1>
					</th>
				</tr>
			)
		}
	}

	renderUnpublishedOrgsList () {
		if (this.state.unpublished_organisations.length > 0) {
			return (
				this.state.unpublished_organisations.map(org =>							
	                <tr key={org.id}>
	                    <th><NavLink to={"/admin/organisation/"+org.id}>{org.title}</NavLink></th>
	                    <th>{org.status}</th>
	                    <th>{org.created_at ? moment(org.created_at).format('D MMM YYYY, h:mm A') : '' }</th>
	                </tr>
				)
			);			
		} else {
			return (
				<tr className="container">
					<th className="py-2">
						<h1>No results found</h1>
					</th>
				</tr>
			)
		}
	}

	render () {
		const { organisations, activities } = this.state;

		return (
			<section>
				<Header />
				<section className="dashboard-filter">
				    <div className="container">
				        <div className="row py-3">
				            <div className="col">
				                <div className="row mb-4 position-relative">
				                	<div className="col-12 col-md-2">
					                    <label 
					                    	htmlFor="organisations" 
					                    	className="text-white"
					                    >
					                    	Find organisation
					                    </label>
									</div>

									<div className="col-12 col-md-6">
					                    <ReactAutocomplete 
					                    	inputProps={{ className: "form-control mb-2 mb-md-0 mr-2", placeholder: 'Organisation by name'}}
					                    	wrapperStyle={{ display: 'block' }}
					                    	getItemValue={(org) => org.title}
					                    	items={organisations}
					                    	renderItem={(item, isHighlighted) => 
					                    		<div key={item.id} style={{ background: isHighlighted ? 'grey' : 'white', color: isHighlighted ? 'white' : ''}} className="form-control">
					                    			{item.title}
					                    		</div>
					                    	}
					                    	menuStyle={{
					                    		zIndex: '9', 
					                    		position: 'absolute',
					                    		left: '20px',
					                    		top: '40px'
					                    	}}
					                    	value={this.state.organisation_search}
					                    	onChange={
					                    		(e) => {
					                    			this.setState({ organisation_search: e.target.value });
					                    			this.getAllOrgs(e.target.value);
					                    		}
					                    	}
					                    	onSelect={
					                    		(val) => {
					                    			this.navigateToOrg(val);
					                    		}
					                    	}
					                    />
				                    </div>

				                    <div className="col-12 col-md-4">
				                    	<NavLink 
				                    		to="/admin/organisations/create" 
				                    		className="dashboard-form-modal btn btn-success btn-block text-white d-flex align-items-center font-weight-bold"
				                    	>
				                    		<i className="fa fa-plus mr-1" aria-hidden="true"></i> ADD ORGANISATION
				                    	</NavLink>
				                	</div>
				                </div>

				                <div className="row position-relative">
				                	<div className="col-12 col-md-2">
					                    <label 
					                    	htmlFor="activities" 
					                    	className="text-white"
					                    >
					                    	Find activity
					                    </label>
									</div>

									<div className="col-12 col-md-6">
					                    <ReactAutocomplete 
					                    	inputProps={{ className: "form-control mb-2 mb-md-0 mr-2", placeholder: 'Organisation by activity'}}
					                    	wrapperStyle={{ display: 'block' }}
					                    	getItemValue={(activity) => activity.title}
					                    	items={activities}
					                    	renderItem={(item, isHighlighted) => 
					                    		<div key={item.id} style={{ background: isHighlighted ? 'grey' : 'white', color: isHighlighted ? 'white' : ''}} className="form-control">
					                    			{item.title}
					                    		</div>
					                    	}
					                    	menuStyle={{
					                    		zIndex: '9', 
					                    		position: 'absolute',
					                    		left: '20px',
					                    		top: '40px'
					                    	}}
					                    	value={this.state.activity_search}
					                    	onChange={
					                    		(e) => {
					                    			this.setState({ activity_search: e.target.value });
					                    			this.getAllActivities(e.target.value);
					                    		}
					                    	}
					                    	onSelect={
					                    		(val) => {
					                    			this.navigateToActivity(val);
					                    		}
					                    	}
					                    />
			                    	</div>

				                    <div className="col-12 col-md-4">
				                    	<NavLink 
				                    		to="/admin/activities/event/create" 
				                    		className="dashboard-form-modal btn btn-success btn-block text-white d-flex align-items-center font-weight-bold"
				                    	>
				                    		<i className="fa fa-plus mr-1" aria-hidden="true"></i> ADD EVENT
				                    	</NavLink>
				                	</div>
				                </div>
				            </div>
				        </div>
				    </div>
				</section>

				<section className="dashboard-results">
				    <div className="dashboard-results-orgs container">
				        <h2 className="dashboard-results-title d-flex align-items-center">Unpublished organisation {(this.state.org_pagination.count > 0) ? <span className="badge badge-primary ml-1">{this.state.org_pagination.count}</span> : ''}</h2>
				        <div className="table-responsive">
				            <table className="table dashboard-results-table">
				                <thead>
				                    <tr>
				                        <th>Name</th>
				                        <th>Status</th>
				                        <th>Date Submitted</th>
				                    </tr>
				                </thead>
				                <tbody>
				                    { this.renderUnpublishedOrgsList() }
				                </tbody>
				            </table>
				        </div>
				    </div>

				    <div className="dashboard-results-events container mt-4">
				        <h2 className="dashboard-results-title d-flex align-items-center">Unpublished activities {(this.state.activity_pagination.count > 0) ? <span className="badge badge-primary ml-1">{this.state.activity_pagination.count}</span> : ''}</h2>
				        <div className="table-responsive">
				            <table className="table dashboard-results-table">
				                <thead>
				                    <tr>
				                        <th>Name</th>
				                        <th>Status</th>
				                        <th>Date Submitted</th>
				                        <th>Organisation</th>
				                        <th>Type</th>
				                    </tr>
				                </thead>
				                <tbody>
				                    { this.renderUnpublishedActivitiesList() }
				                </tbody>
				            </table>
				        </div>
				    </div>
				</section>
				<Footer />
			</section>
		);
	}
}

export default requireAuth(Dashboard);