import React, { Fragment, Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
	isNumericBoolean
} from '../Utilities/adapter';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import ContactModal from '../ContactModal';

const BASE_URL = process.env.REACT_APP_API_URL + 'api';
const API_URL = process.env.REACT_APP_API_URL + 'api/activities';
const ORG_URL = process.env.REACT_APP_API_URL + 'api/organisations/filter';
const INITIAL_STATE = {
	activity: {
		type: "event",
		event_type: 1,
		resource_type: 1,
		title: '',
		organisation_title: '',
		organisation_id: null,
		info: '',
		info_link: '',
		has_multi_dates: 0,
		dates: [],
		start_date: moment(new Date(new Date().setHours(0, 0))).format(),
		end_date: moment(new Date(new Date().setHours(0, 0))).format(),
		support_send: 0,
		delivered_in_school: 0,
		webinar: 0,
		artsmark_partner: 0,
		arts_award_supporter: 0,
		status: 'draft',
		artforms: [],
		keystages: [],
		venue_name: '',
		first_name: '',
		last_name: '',
		email: '',
		address_1: '',
		address_2: '',
		borough: '',
		postcode: '',
		free: 0,
		cost: ''
	},
	organisation_search: '',
	selected_organisation: '',
	organisations: [],
	artforms: [],
	startDate: new Date(new Date().setHours(0, 0)),
	endDate: new Date(new Date().setHours(0, 0)),
	calendarDate: new Date(new Date().setHours(0, 0)),
	loading: false,
	value: '',
	event_date_selection_error: '',
};

class ActivityCreateForm extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		window.scrollTo(0, 0);
		this.getArtformTypes();
	}

	handleSubmit = (e) => {
		e.preventDefault();

		this.setState(
			prevState => ({
				...prevState,
				loading: true
			})
		);

		axios.post(API_URL, this.state.activity)
			.then(response => {
				if (response.status === 200) {
					this.setState({ INITIAL_STATE });

					toast.success('Submitted', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: 5000,
						onClose: () => window.location.reload()
					});

					toast.info('Waiting for admin approval', {
						position: toast.POSITION.TOP_RIGHT,
						autoClose: false
					});
				}
			})
			.catch(errorMsg => {
				let eData = errorMsg.response.data;
				let errM = errorMsg.response.data.errors;

				if (errorMsg.response.data.hasOwnProperty('errors')) {
					Object.entries(errM).map(([field, index]) => {
						let message = 'Error: ' + index;

						toast.error(message, {
							position: toast.POSITION.TOP_CENTER,
							autoClose: false
						});
					});
				} else {
					console.log(eData);
					toast.error(eData.message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				}

				this.setState(
					prevState => ({
						...prevState,
						loading: false
					})
				);

			});
	};


	handleActivityType = (e, activity_type) => {
		e.preventDefault();

		let event_type = this.state.activity.activity_type;
		let resource_type = this.state.activity.resource_type;

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					type: activity_type,
					event_type: (event_type) ? event_type : 1,
					resource_type: (resource_type) ? resource_type : 1,
				}
			})
		)
	}


	handleProjectDateType = () => {

		let has_multi_dates = this.state.activity.has_multi_dates;

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					has_multi_dates: (has_multi_dates === 0) ? 1 : 0
				}
			})
		);
	}

	handleRemoveProjectDate = (date) => {

		let dates = this.state.activity.dates;

		dates.map(prevDate => {
			if (moment(date).isSame(moment(prevDate))) {
				console.log(date);
				const index = dates.indexOf(prevDate);
				if (index > -1) {
					dates.splice(index, 1);
				}
			}
		});

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					dates: dates
				}
			})
		);
	}

	handleProjectDates = (date) => {

		let dates = this.state.activity.dates;

		dates.map(prevDate => {
			if (moment(date).format('MM-DD-YYYY') === moment(prevDate).format('MM-DD-YYYY')) {
				console.log(date);
				const index = dates.indexOf(prevDate);
				if (index > -1) {
					dates.splice(index, 1);
				}
			}
		});

		dates.push(new Date(date));

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					dates: dates
				},
				calendarDate: date
			})
		);
	}


	handleStartDate = (date) => {

		 let activity_type = this.state.activity.type;
		 let start = moment(date, 'DD-MM-YYYY');
		 let end = moment(this.state.endDate, 'DD-MM-YYYY');
		 let difference = end.diff(start, 'days');

		 if (Math.abs(difference) > 14 && activity_type === 'event') {			 
			 this.setState(
				prevState => ({
					activity: {
						...prevState.activity,
						start_date: moment(date).format(),
						end_date: null,
					},
					startDate: date,
					endDate: null,
					event_date_selection_error: 'Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.'
				}));
		 } else {

			this.setState(
				prevState => ({
					...prevState,
					startDate: date
				}), () => {
					if (moment(this.state.startDate).isSameOrAfter(this.state.endDate) || this.state.endDate === '') {
						this.setState(
							prevState => ({
								activity: {
									...prevState.activity,
									start_date: moment(date).format(),
									end_date: moment(date).format()
								},
								startDate: date,
								endDate: date,
								event_date_selection_error: '',
							})
						)
					} else {
						this.setState(
							prevState => ({
								activity: {
									...prevState.activity,
									start_date: moment(date).format()
								},
								startDate: date,
								event_date_selection_error: '',
							})
						)
					}
				}
			);
		 }
	}

	handleEndDate = (date) => {

		let activity_type = this.state.activity.type;
		let start = moment(this.state.startDate, 'DD-MM-YYYY');
		let end = moment(date, 'DD-MM-YYYY');
		let difference = end.diff(start, 'days');

		if (Math.abs(difference) > 14 && activity_type === 'event') {

			this.setState(
				prevState => ({
					...prevState,
					event_date_selection_error:  'Event listings cannot exceed two weeks. Try listing your opportunity as a Project instead.'

				}));
				
		} else {
			this.setState(
				prevState => ({
					...prevState,
					endDate: date
				}), () => {
					if (moment(this.state.endDate).isBefore(this.state.startDate)) {
						this.setState(
							prevState => ({
								activity: {
									...prevState.activity,
									start_date: moment(date).format(),
									end_date: moment(date).format()
								},
								startDate: date,
								endDate: date,
								event_date_selection_error: '',
							})
						)
					} else {
						this.setState(
							prevState => ({
								activity: {
									...prevState.activity,
									end_date: moment(date).format()
								},
								endDate: date,
								event_date_selection_error: '',
							})
						)
					}
				}
			);
		}
	}

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({
					activity: { ...prevState.activity, [fieldName]: numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					activity: { ...prevState.activity, [fieldName]: fieldValue }
				})
			);
		}
	}

	onSearchOrg = (e) => {
		let fieldValue = e;

		if (fieldValue.length > 0) {
			this.setState(
				prevState => ({
					...prevState,
					activity: {
						...prevState.activity
					},
					organisation_search: fieldValue
				}),
				() => {
					this.getOrganisation();
				}
			);
		} else {
			this.setState(
				prevState => ({
					...prevState,
					activity: {
						...prevState.activity,
						organisation_title: '',
						organisation_id: null
					},
					organisation_search: '',
					selected_organisation: '',
					organisations: []
				})
			);
		}

	};

	onSuggest = (event, { newValue, method }) => {
		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					organisation_title: newValue
				},
				organisation_search: newValue,
				value: newValue
			})
		);
	};

	getOrganisation = () => {
		axios.post(ORG_URL, {
			title: this.state.organisation_search,
			not_status: 'rejected'
		})
			.then(response => {
				let organisations = response.data.data;

				if (organisations.length > 0) {
					this.setState(
						prevState => ({
							...prevState,
							organisations: organisations
						})
					);
				} else {
					this.setState(
						prevState => ({
							...prevState,
							activity: {
								...prevState.activity,
								organisation_id: null
							},
							organisations: organisations
						})
					);
				}
			});
	};

	onSelectExistingOrganisation = (e) => {
		const orgId = parseInt(e.id);
		const title = e.title;

		this.setState(
			prevState => ({
				...prevState,
				activity: {
					...prevState.activity,
					organisation_id: orgId,
					organisation_title: title
				},
				organisation_id: orgId,
				organisation_search: title,
				selected_organisation: orgId
			})
		);
	};

	getArtformTypes = () => {
		axios.get(BASE_URL + '/artform-types')
			.then(response => {
				this.setState({
					artforms: response.data
				});
			});
	}

	escapeRegexCharacters(str) {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	// Teach Autosuggest how to calculate suggestions for any given input value.
	getSuggestions = value => {
		const escapedValue = this.escapeRegexCharacters(value.trim());

		if (escapedValue === '') {
			return [];
		}

		const regex = new RegExp('^' + escapedValue, 'i');
		return this.state.organisations.filter(org =>
			regex.test(org.title)
		);
	};

	// When suggestion is clicked, Autosuggest needs to populate the input
	// based on the clicked suggestion. Teach Autosuggest how to calculate the
	// input value for every given suggestion.
	getSuggestionValue = suggestion => {
		this.onSelectExistingOrganisation(suggestion);

		return suggestion.title.toString();
	}

	// Autosuggest will call this function every time you need to update suggestions.
	// You already implemented this logic above, so just use it.
	onSuggestionsFetchRequested = ({ value }) => {
		this.onSearchOrg(value);
	};

	// Autosuggest will call this function every time you need to clear suggestions.
	onSuggestionsClearRequested = () => {
		if (this.state.organisation_id !== null && this.state.value === '') {
			this.setState(
				prevState => ({
					...prevState,
					activity: {
						...prevState.activity,
						organisation_id: null
					},
					organisation_id: null,
					organisation_title: '',
					organisations: [],
					selected_organisation: [],
				})
			);
		} else {
			this.setState(
				prevState => ({
					...prevState,
					activity: {
						...prevState.activity
					},
					organisation_title: '',
					organisations: [],
					selected_organisation: [],
				})
			);
		}
	};

	onSuggestionSelected(event, {
		suggestion, suggestionValue,
		suggestionIndex, sectionIndex, method
	}) {
		if (method === 'enter') {
			event.preventDefault();
		}
	}

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					activity: {
						...prevState.activity,
						artforms: [...prevState.activity.artforms, fieldValue]
					}
				})
			);
		} else {
			let artforms = [...this.state.activity.artforms];
			let index = artforms.indexOf(fieldValue);

			if (index !== -1) {
				artforms.splice(index, 1);

				this.setState(
					prevState => ({
						activity: {
							...prevState.activity,
							artforms: artforms
						}
					})
				);
			}

		}
	}

	selectKeystageCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					activity: {
						...prevState.activity,
						keystages: [...prevState.activity.keystages, fieldValue]
					}
				})
			);
		} else {
			let keystages = [...this.state.activity.keystages];
			let index = keystages.indexOf(fieldValue);

			if (index !== -1) {
				keystages.splice(index, 1);

				this.setState(
					prevState => ({
						activity: {
							...prevState.activity,
							keystages: keystages
						}
					})
				);
			}
		}
	}

	// Use your imagination to render suggestions.
	renderSuggestion(suggestion, { query }) {
		const matches = AutosuggestHighlightMatch(suggestion.title, query);
		const parts = AutosuggestHighlightParse(suggestion.title, matches);

		return (
			<Fragment>
				{parts.map((part, index) => {
					const className = part.highlight ? 'react-autosuggest__suggestion-match' : null;

					return (
						<span className={className} key={index}>{part.text}</span>
					);
				})}
			</Fragment>
		)
	}

	renderEventDates() {
		const startDate = moment(this.state.startDate);

		return (
			<div className="form-group">
				<label htmlFor="eventDates" className="font-weight-bold">Dates</label>
				<p className="mb-0">
					Date *<br />
					{this.state.startDate ?
						startDate.format("ddd, D MMM YYYY, h:mm A")
						: ''}
				</p>

				<DatePicker
					inline
					selectsStart
					showTimeSelect
					selected={this.state.startDate}
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					onChange={this.handleStartDate}
					name="start_date"
					timeFormat="HH:mm"
					timeIntervals={5}
					className="form-control border mb-2"
					placeholderText="Date *"
					required
				/>
				<p className="mb-0 mt-2">
					End date *<br />
					{this.state.endDate ?
						moment(this.state.endDate).format("ddd, D MMM YYYY, h:mm A")
						: ''}
				</p>
				<DatePicker
					inline
					selectsEnd
					showTimeSelect
					selected={this.state.endDate}
					startDate={this.state.startDate}
					endDate={this.state.endDate}
					onChange={this.handleEndDate}
					name="end_date"
					timeFormat="HH:mm"
					timeIntervals={5}
					className="form-control border mb-2"
					placeholderText="End date *"
					required
				/>

				<div className="text-danger">{this.state.event_date_selection_error}</div>
			</div>
		);
	}

	renderOrgResults() {
		if (this.state.organisations.length || this.state.organisation_search !== '') {
			return (
				<div className="form-group">
					<label htmlFor="orgResults">Organisations</label>
					<select onChange={this.onSelectExistingOrganisation} name="orgResults" id="orgResults" className="form-control border" size="5">
						{this.state.organisations.map(org =>
							<option key={org.id} value={org.id} data-title={org.title}>{org.title}</option>
						)}
					</select>
				</div>
			);
		}
	};

	renderOrgSearch() {
		if (this.state.activity.organisation_id === null) {
			return (
				<div className="form-group">
					<p className="mt-2 mb-0">If your organisation is not already listed, please enter your organisation name here</p>
					<input
						onChange={this.onInputChange}
						value={this.state.activity.organisation_search}
						type="text"
						name="organisation_title"
						placeholder="Organisation name"
						className="form-control border"
						disabled={this.state.organisation_search !== ''}
					/>
				</div>
			);
		}
	}

	renderArtformSelection() {
		return (
			this.state.artforms.map(art =>
				<div key={art.id} className="form-group" style={{ display: 'inline-block', width: '50%' }} >
					<input id={art.id} onChange={this.selectArtformCheckboxes} type="checkbox" name="artforms" className="border mr-1" value={art.id} />
					<label htmlFor={art.id}>{art.name}</label>
				</div>
			)
		);
	}

	renderProjectPeriod() {
		const startDate = moment(this.state.startDate);

		const activity_type = this.state.activity.type;

		if (activity_type === "project") {

			return (
				<div className="form-group">
					<label htmlFor="eventDates" className="font-weight-bold">Dates</label>
					<p className="mb-0">
						Date *<br />
						{this.state.startDate ?
							startDate.format("ddd, D MMM YYYY, h:mm A")
							: ''}
					</p>

					<DatePicker
						inline
						selectsStart
						showTimeSelect
						selected={this.state.startDate}
						startDate={this.state.startDate}
						endDate={this.state.endDate}
						onChange={this.handleStartDate}
						name="start_date"
						timeFormat="HH:mm"
						timeIntervals={5}
						className="form-control border mb-2"
						placeholderText="Date *"
						required
					/>
					<p className="mb-0 mt-2">
						End date *<br />
						{this.state.endDate ?
							moment(this.state.endDate).format("ddd, D MMM YYYY, h:mm A")
							: ''}
					</p>
					<DatePicker
						inline
						selectsEnd
						showTimeSelect
						selected={this.state.endDate}
						startDate={this.state.startDate}
						endDate={this.state.endDate}
						onChange={this.handleEndDate}
						name="end_date"
						timeFormat="HH:mm"
						timeIntervals={5}
						className="form-control border mb-2"
						placeholderText="End date *"
						required
					/>

					<button type="button" onClick={this.handleProjectDateType} className="btn btn-outline-primary btn-block">Add multiple dates instead</button>
				</div>
			);
		}
	}

	renderProjectDates() {
		let dates = [];

		const activity_type = this.state.activity.type;

		if (activity_type === "project") {

			this.state.activity.dates.map((date, index) => {
				dates.push(<li key={index} className="mb-2">

					<div className="row">
						<div className="col-md-7">{moment(date).format("dddd, D MMM YYYY, h:mm A")}</div>
						<div className="col-md-5"><button type="button" className="btn btn-sm btn-outline-primary float-left" date={date} onClick={(e) => this.handleRemoveProjectDate(date, e)}><i className="fa fa-times"></i></button></div>
					</div>

				</li>);
			})


			return (
				<div className="form-group">
					<label htmlFor="eventDates" className="font-weight-bold">Dates</label>
					<ul className="list-unstyled m-0 p-0">
						{dates}
					</ul>

					<DatePicker
						inline
						selected={this.state.calendarDate}
						highlightDates={[...this.state.activity.dates]}
						showTimeSelect
						onChange={this.handleProjectDates}
						timeFormat="HH:mm"
						timeIntervals={60}
						minTime={new Date(new Date().setHours(8, 0))}
						maxTime={new Date(new Date().setHours(23, 0))}
					/>
					<button type="button" onClick={this.handleProjectDateType} className="btn btn-outline-primary btn-block">Add a period instead</button>
				</div>
			);
		}
	}

	renderActivityCost() {
		if (this.state.activity.cost !== '') {
			return (
				<Fragment>
					<div className="form-group">
						<label htmlFor="activityCost" className="font-weight-bold">Cost</label>
						<input
							onChange={this.onInputChange}
							value={this.state.activity.cost}
							id="activityCost"
							type="text"
							name="cost"
							placeholder="Cost"
							className="form-control border"
						/>
					</div>
					<div className="form-group">
						<input
							onChange={this.onInputChange}
							value={this.state.activity.free}
							id="freeEvent"
							type="checkbox"
							name="free"
							className="form-checkbox-disabled"
							disabled
						/>
						<label htmlFor="freeEvent"><strike>Free </strike></label>
					</div>
				</Fragment>
			);
		} else if (this.state.activity.free) {
			return (
				<Fragment>
					<div className="form-group">
						<label htmlFor="activityCost" className="font-weight-bold">Cost</label>
						<input
							onChange={this.onInputChange}
							value={this.state.activity.cost}
							id="activityCost"
							type="text"
							name="cost"
							placeholder="Cost"
							className="form-control border"
							disabled
						/>
					</div>
					<div className="form-group">
						<input
							onChange={this.onInputChange}
							value={this.state.activity.free}
							id="freeEvent"
							type="checkbox"
							name="free"
							className="border mr-1"
						/>
						<label htmlFor="freeEvent">Free </label>
					</div>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<div className="form-group">
						<label htmlFor="activityCost" className="font-weight-bold">Cost</label>
						<input
							onChange={this.onInputChange}
							value={this.state.activity.cost}
							id="activityCost"
							type="text"
							name="cost"
							placeholder="Cost"
							className="form-control border"
						/>
					</div>
					<div className="form-group">
						<input
							onChange={this.onInputChange}
							value={this.state.activity.free}
							id="freeEvent"
							type="checkbox"
							name="free"
							className="border mr-1"
						/>
						<label htmlFor="freeEvent" className="text-primary">Free </label>
					</div>
				</Fragment>
			);
		}
	}

	renderOrgAutosuggest() {
		const { value, organisations } = this.state;

		const autoProps = {
			placeholder: 'Type here',
			value,
			onChange: this.onSuggest
		};

		const theme = {
			container: 'react-autosuggest__container',
			input: 'react-autosuggest__input form-control border',
			suggestion: 'react-autosuggest__suggestion',
			suggestionFirst: 'react-autosuggest__suggestion--first',
			suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
			suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open form-control border p-0',
			suggestionsList: 'list-unstyled p-0 m-0'
		};

		return (
			<div className="form-group">
				<label htmlFor="checkOrgName" className="mb-0 text-primary">What is your organisations name?</label>
				<Autosuggest
					suggestions={organisations}
					onSuggestionSelected={this.onSuggestionSelected}
					onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
					onSuggestionsClearRequested={this.onSuggestionsClearRequested}
					getSuggestionValue={this.getSuggestionValue}
					renderSuggestion={this.renderSuggestion}
					inputProps={autoProps}
					theme={theme}
				/>
			</div>
		)
	}

	renderEventTypes() {
		return (
			<div>
				<label className="font-weight-bold mb-0">Type *</label>
				<p>Please select a category</p>

				<div className="tab-filter-select-wrap">
					<select onChange={this.onInputChange} className="form-control tab-filter-select border" value={this.state.activity.event_type} name="event_type">
						<option value={1}>CPD</option>
						<option value={2}>Performance</option>
						<option value={3}>Workshop</option>
						<option value={4}>Networking</option>
						<option value={5}>Other</option>
					</select>
				</div>
			</div>
		);

	}

	renderResourceTypes() {
		return (
			<div>
				<label className="font-weight-bold mb-0">Type *</label>
				<p>Please select a category</p>

				<div className="tab-filter-select-wrap">
					<select onChange={this.onInputChange} className="form-control tab-filter-select border" value={this.state.activity.resource_type} name="resource_type">
						<option value={1}>Activities and worksheets</option>
						<option value={2}>Audio resources</option>
						<option value={3}>Lesson plans</option>
						<option value={4}>Online platforms</option>
						<option value={5}>Teacher CPD</option>
						<option value={6}>Video resources</option>
						<option value={7}>Virtual tours</option>
					</select>
				</div>
			</div>
		);
	}

	renderEventAddress() {
		return (
			<div className="my-4">
				<p className="font-weight-bold">Address</p>
				<input onChange={this.onInputChange} value={this.state.activity.venue_name} type="text" name="venue_name" className="form-control border mb-1" placeholder="Venue name" />
				<input onChange={this.onInputChange} value={this.state.activity.address_1} type="text" name="address_1" className="form-control border mb-1" placeholder="Address 1" />
				<input onChange={this.onInputChange} value={this.state.activity.address_2} type="text" name="address_2" className="form-control border mb-1" placeholder="Address 2" />
				<input onChange={this.onInputChange} value={this.state.activity.borough} type="text" name="borough" className="form-control border mb-1" placeholder="Borough" />
				<input onChange={this.onInputChange} value={this.state.activity.postcode} type="text" name="postcode" className="form-control border" placeholder="Postcode" />
			</div>
		);
	}

	renderProjectAddress() {
		return (
			<div className="my-4">
				<p className="font-weight-bold">Address</p>
				<input onChange={this.onInputChange} value={this.state.activity.venue_name} type="text" name="venue_name" className="form-control border mb-1" placeholder="Venue name" />
				<input onChange={this.onInputChange} value={this.state.activity.address_1} type="text" name="address_1" className="form-control border mb-1" placeholder="Address 1" />
				<input onChange={this.onInputChange} value={this.state.activity.address_2} type="text" name="address_2" className="form-control border mb-1" placeholder="Address 2" />
				<input onChange={this.onInputChange} value={this.state.activity.borough} type="text" name="borough" className="form-control border mb-1" placeholder="Borough" />
				<input onChange={this.onInputChange} value={this.state.activity.postcode} type="text" name="postcode" className="form-control border" placeholder="Postcode" />
			</div>
		);
	}

	renderDeliveredInSchoolCheckbox() {
		let activity_type = this.state.activity.type;

		if (activity_type === 'event' || activity_type === 'project') {
			return (
				<div className="form-group mt-4">
					<input onChange={this.onInputChange} value={this.state.activity.delivered_in_school} id="deliveredInSchool" type="checkbox" name="delivered_in_school" className="border mr-1" />
					<label htmlFor="deliveredInSchool">Delivered in your school </label>
				</div>
			);
		}
	}

	renderWebinarCheckbox() {
		let activity_type = this.state.activity.type;

		if (activity_type === 'event' || activity_type === 'project') {
			return (
				<div className="form-group mt-4">
					<input onChange={this.onInputChange} value={this.state.activity.webinar} id="webinar" type="checkbox" name="webinar" className="border mr-1" />
					<label htmlFor="webinar">Webinar </label>
				</div>
			);
		}
	}

	renderForm() {

		return (
			<Fragment>

				<div className="pt-1 pb-2">
					<h2>ADD AN EVENT, PROJECT OR RESOURCE TO LOOKUP</h2>
					<p><strong>Choose the kind of activity that best fits.</strong></p>

					<div className="row mb-2">
						<div className="col-md-4"> <button className={"btn btn-activity-selector" + ((this.state.activity.type === 'event') ? " active" : "")} onClick={(e) => this.handleActivityType(e, 'event')}>Event</button></div>
						<div className="col-md-8"><strong>A single date or on consequtive days lasting no longer than two weeks</strong></div>
					</div>

					<div className="row mb-1">
						<div className="col-md-4"><button className={"btn btn-activity-selector" + ((this.state.activity.type === 'project') ? " active" : "")} onClick={(e) => this.handleActivityType(e, 'project')}>Project</button></div>
						<div className="col-md-8"><strong>A recoccuring activity on a specific day or days or over a longer period like a term</strong></div>
					</div>

					<div className="row">
						<div className="col-md-4"><button className={"btn btn-activity-selector" + ((this.state.activity.type === 'resource') ? " active" : "")} onClick={(e) => this.handleActivityType(e, 'resource')}>Resource</button></div>
						<div className="col-md-8"><strong>Something that is not date related</strong></div>
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="activityTitle" className="font-weight-bold">Title</label>
					<input onChange={this.onInputChange} value={this.state.activity.title} id="activityTitle" type="text" name="title" placeholder="Title*" className="form-control border" required />
				</div>

				<div className="form-group">
					<label htmlFor="eventInfo" className="font-weight-bold">Information</label>
					<textarea
						onChange={this.onInputChange}
						value={this.state.activity.info}
						id="eventInfo"
						className="form-control border"
						name="info"
						maxLength="350"
						placeholder="Description – 350 characters max. Please note: copy should be aimed at teachers, not students or young people.*"
						rows="8" required>
					</textarea>
				</div>

				{this.renderOrgAutosuggest()}

				{(this.state.activity.type === "event") ? this.renderEventDates() : ""}

				{(this.state.activity.has_multi_dates === 0) ? this.renderProjectPeriod() : this.renderProjectDates()}

				{this.renderActivityCost()}

				<div className="form-group">
					<label htmlFor="moreInfo" className="font-weight-bold">More information</label>
					<input onChange={this.onInputChange} value={this.state.activity.info_link} id="moreInfo" type="url" name="info_link" className="form-control border" placeholder="Add link for more information or booking *" />
				</div>

				{this.renderDeliveredInSchoolCheckbox()}

				{this.renderWebinarCheckbox()}

				{(this.state.activity.type === "event") ? this.renderEventAddress() : ""}
				{(this.state.activity.type === "project") ? this.renderProjectAddress() : ""}
				{(this.state.activity.type === "event") ? this.renderEventTypes() : ""}
				{(this.state.activity.type === "resource") ? this.renderResourceTypes() : ""}

				<div className="form-group mt-4 mb-1">
					<p className="font-weight-bold mb-0">Artforms *</p>
				</div>

				<div className="row">
					<div className="col">
						{this.renderArtformSelection()}
					</div>
				</div>

				<div className="my-2">
					<p className="font-weight-bold mb-0">Key stage</p>
				</div>

				<div className="d-flex justify-content-between">
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key0"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={0}
						/>
						<label htmlFor="key0">EY</label>
					</div>
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key1"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={1}
						/>
						<label htmlFor="key1">1</label>
					</div>
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key2"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={2}
						/>
						<label htmlFor="key2">2</label>
					</div>
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key3"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={3}
						/>
						<label htmlFor="key3">3</label>
					</div>
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key4"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={4}
						/>
						<label htmlFor="key4">4</label>
					</div>
					<div>
						<input
							onChange={this.selectKeystageCheckboxes}
							id="key5"
							className="border mr-1"
							type="checkbox"
							name="keystages"
							value={5}
						/>
						<label htmlFor="key5">5</label>
					</div>
				</div>

				<div className="form-group mt-4">
					<input onChange={this.onInputChange} value={this.state.activity.support_send} id="suitableEvent" type="checkbox" name="support_send" className="border mr-1" />
					<label htmlFor="suitableEvent">Designed to be accessible for SEND students</label>
				</div>



				<div>
					<p className="font-weight-bold mb-0">Contact</p>
					<p>Please provide a contact email address. This will only be used by A New Direction to contact you with any queries about your submission.</p>
					<input onChange={this.onInputChange} value={this.state.activity.first_name} type="text" name="first_name" className="form-control border mb-1" placeholder="First name*" required />
					<input onChange={this.onInputChange} value={this.state.activity.last_name} type="text" name="last_name" className="form-control border mb-1" placeholder="Last name*" required />
					<input onChange={this.onInputChange} value={this.state.activity.email} type="text" name="email" className="form-control border mb-1" placeholder="Email address*" required />
				</div>

				<div className="mt-3">
					<p className="font-weight-bold">Privacy</p>
					<p>By submitting content to LookUp you are agreeing to A New Direction storing your details in case we need to contact you in relation to your submission.</p>
				</div>

				<div className="form-group">
					<input id="privacy" type="checkbox" name="privacy" className="border mr-2" required />
					<label htmlFor="privacy">I accept the terms and conditions</label>
				</div>

				<div className="mt-3">
					<p>We often communicate about LookUp via our newsletter, which includes information about our other work, events and opportunities. If you would also like to receive this, please sign up to our newsletter <a href="https://www.anewdirection.org.uk/newsletter" target="_blank" rel="noopener noreferrer">here.</a></p>
				</div>

				<button type="submit" className="btn btn-primary btn-lg mb-5" disabled={((this.state.loading) ? true : false)}> {((this.state.loading) ? <span className="fa fa-spinner fa-spin"></span> : '')} Submit</button>
			</Fragment>
		);
	}

	render() {
		return (
			<section className="container-fluid">
				<div className="row d-flex">
					<div className="col-12 col-md-6 bg-grey order-md-2">
						<div className="float-left my-4" style={{ maxWidth: '560px', width: '100%' }}>
							<p className="font-weight-bold">GUIDELINES</p>
							<p>A New Direction is happy to profile and promote your upcoming arts and cultural opportunities for London teachers on LookUp. Please add details via the form on the left. All submissions will be reviewed and approved by A New Direction, and all fields which are required are indicated with an *asterix</p>

							<p className="font-weight-bold">Address</p>
							<p>If your opportunity isn't venue-specific, it may be better suited to our 'Resource' or 'Project' sections</p>

							<p className="font-weight-bold">Artforms</p>
							<p>Please specify which artforms are relevant to you activity. You must select at least one artform to appear in search results. If you think we're currently missing an artform please <ContactModal name="contact us" classes=""/></p>
							<hr />

							<p className="font-weight-bold">Terms and conditions of use</p>
							<p>LookUp is designed to help users find creative and cultural opportunities across London’s varied and complex cultural offer.</p>
							<p>Publication of organisation details or an opportunity listing on LookUp does not constitute endorsement by A New Direction of the nature or quality of the work offered.</p>
							<p>Organisations are responsible for ensuring that their practices and policies comply with current child protection and safeguarding laws and regulations. Schools are responsible for ensuring that their own requirements around safeguarding and DBS checks are met when engaging with organisations.</p>
							<p>A New Direction cannot accept responsibility for any issues that may arise with organisations or opportunities listed on the LookUp portal. We also cannot guarantee bookings for events or projects listed on LookUp.</p>
						</div>
					</div>

					<div className="col-12 col-md-6 order-md-1">
						<div className="float-right" style={{ maxWidth: '560px', width: '100%' }}>
							<form onSubmit={this.handleSubmit} className="my-4">
								{this.renderForm()}
							</form>
						</div>
					</div>
				</div>
			</section>
		)
	}
}

export default ActivityCreateForm;