import axios from 'axios';
import { 
	AUTH_USER,
	AUTH_SUCCESS,
	AUTH_ERROR,
	USER
} from './types';
export * from './EventActions';

const CLIENT_ID = process.env.REACT_APP_CLIENT_CREDENTIAL_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_CREDENTIAL_SECRET;
const CLIENT_PASSWORD_ID = process.env.REACT_APP_CLIENT_PASSWORD_ID;
const CLIENT_PASSWORD_SECRET = process.env.REACT_APP_CLIENT_PASSWORD_SECRET;
const API_URL = process.env.REACT_APP_API_URL;

export const signup = (formProps, callback) => dispatch => {
	axios.post(API_URL+'oauth/token', {
		client_id: CLIENT_ID,
		client_secret: CLIENT_SECRET,
		grant_type: 'client_credentials',
	})
	.then(response => {
		return axios.post(
			API_URL+'api/user', formProps, {
				headers: { Authorization: "Bearer " + response.data.access_token }
			});
	})
	.then(response => {
		dispatch({ type: USER, payload: response.data.data });
		callback();
	})
	.catch(e => {
		let eResponse = e.response.data;

		if (eResponse.hasOwnProperty('error')) {
			Object.entries(eResponse.error).map(([field, index]) => {
				let message = 'Error: ' + index;

				dispatch({ type: AUTH_ERROR, payload: message });
			});
		} else {
			dispatch({ type: AUTH_ERROR, payload: eResponse.error });
		}
	});
};

export const signin = (formProps, callback) => dispatch => {
	axios.post(API_URL+'oauth/token', {
		client_id: CLIENT_PASSWORD_ID,
		client_secret: CLIENT_PASSWORD_SECRET,
		grant_type: 'password',
		username: formProps.username,
		password: formProps.password,
		scope: '*'
	})
	.then(response => {
		dispatch({ type: AUTH_SUCCESS, payload: true });
		dispatch({ type: AUTH_USER, payload: response.data.access_token });
		localStorage.setItem('accessToken', response.data.access_token);
		localStorage.setItem('authenticated', true);

		return axios.get(API_URL+'api/user', {
			headers: { Authorization: "Bearer " + response.data.access_token }
		});
	})
	.then(response => {
		localStorage.setItem('authUser', JSON.stringify(response.data));
		dispatch({ type: USER, payload: JSON.stringify(response.data) });
		callback();
	})
	.catch(e => {
		dispatch({ type: AUTH_ERROR, payload: 'Invalid user credentials' });

		console.log(e.response);
	});
};

export const signout = () => {
	localStorage.removeItem('authUser');
	localStorage.removeItem('accessToken');
	localStorage.removeItem('authenticated');

	return {
		type: AUTH_USER,
		payload: ''
	}
};