import moment from 'moment';
import parseISO from 'date-fns/parseISO';

// Used for PUT request to API
// Stringify results for
export const eventsToApiStringify = apiObj => ({
			
});

export const orgPostToApi = apiObj => {
	const formData = new FormData();

	if (apiObj.logo !== '') {
		const orgLogo = new Blob([apiObj.logo], { type: apiObj.logo.type });		
		formData.append('logo', orgLogo);
	}

	if (apiObj.status !== '') {
		formData.append('status', apiObj.status);		
	}

	const keystages = apiObj.keystages;
	const artforms = apiObj.artforms;

	for (var i = 0; i < keystages.length; i++) {
		formData.append('keystages[]', keystages[i]);
	}

	for (var x = 0; x < artforms.length; x++) {
		formData.append('artforms[]', artforms[x]);
	}

	formData.append('title', apiObj.title);
	formData.append('summary', apiObj.summary);
	formData.append('website', apiObj.website);
	formData.append('type', apiObj.type);
	formData.append('arts_award_supporter', apiObj.arts_award_supporter);
	formData.append('artsmark_partner', apiObj.artsmark_partner);
	formData.append('contact_email', apiObj.contact_email);
	formData.append('address_1', apiObj.address_1);
	formData.append('address_2', apiObj.address_2);
	formData.append('borough', apiObj.borough);
	formData.append('postcode', apiObj.postcode);
	formData.append('support_send', apiObj.support_send);
	formData.append('npo', apiObj.npo);
	formData.append('contact_first_name', apiObj.contact_first_name);
	formData.append('contact_last_name', apiObj.contact_last_name);
	formData.append('artsmark_partner_offer', apiObj.artsmark_partner_offer);

	return formData;
};

export const orgPutToApi = apiObj => {
	const formData = new FormData();

	if (apiObj.logo !== '' && typeof apiObj.logo !== 'undefined') {
		const orgLogo = new Blob([apiObj.logo], { type: apiObj.logo.type });		
		formData.append('logo', orgLogo);
	}

	if (apiObj.status !== '') {
		formData.append('status', apiObj.status);		
	}

	const keystages = apiObj.keystages;
	const artforms = apiObj.artforms;

	if (keystages.length > 0) {
		for (var i = 0; i < keystages.length; i++) {
			formData.append('keystages[]', keystages[i]);
		}		
	} else {
		formData.append('keystages[]', keystages);
	}

	for (var x = 0; x < artforms.length; x++) {
		formData.append('artforms[]', artforms[x]);
	}

	formData.append('title', apiObj.title);
	formData.append('summary', apiObj.summary);
	formData.append('website', apiObj.website);
	formData.append('type', apiObj.type);
	formData.append('arts_award_supporter', apiObj.arts_award_supporter);
	formData.append('artsmark_partner', apiObj.artsmark_partner);
	formData.append('contact_email', apiObj.contact_email);
	formData.append('address_1', apiObj.address_1);
	formData.append('address_2', apiObj.address_2);
	formData.append('borough', apiObj.borough);
	formData.append('postcode', apiObj.postcode);
	formData.append('support_send', apiObj.support_send);
	formData.append('npo', apiObj.npo);
	formData.append('contact_first_name', apiObj.contact_first_name);
	formData.append('contact_last_name', apiObj.contact_last_name);
	formData.append('artsmark_partner_offer', apiObj.artsmark_partner_offer);
	formData.append('_method', 'PUT');

	return formData;
};

// Used for mapping results from API
export const activityFromApi = activity => ({
	activity: {
		type: activity.type,
		event_type: activity.event_type,
		resource_type: activity.resource_type,
		title: activity.title,
		organisation_title: activity.organisation.title,
		organisation_id: activity.organisation.id,
		info: activity.info,
		info_link: activity.links.info,
		has_multi_dates: (activity.has_multi_dates) ? 1 : 0,
		dates: (activity.dates) ? activity.dates : [],
		start_date: (activity.start_date) ? moment(parseISO(activity.start_date), moment.HTML5_FMT.DATETIME_LOCAL).format() : null,
		end_date: (activity.end_date) ? moment(parseISO(activity.end_date), moment.HTML5_FMT.DATETIME_LOCAL).format() : null,
		support_send: isNumericBoolean(activity.support_send),
		delivered_in_school: isNumericBoolean(activity.delivered_in_school),
		webinar: isNumericBoolean(activity.webinar),
		status: activity.status,
		artforms: activity.artforms,
		keystages: activity.keystages,
		tags: activity.tags,
		venue_name: activity.contacts.venue_name,
		first_name: activity.contacts.first_name,
		last_name: activity.contacts.last_name,
		email: activity.contacts.email,
		address_1: activity.contacts.address_1,
		address_2: activity.contacts.address_2,
		borough: activity.contacts.borough,
		postcode: activity.contacts.postcode,
		free: activity.free,
		cost: activity.cost ? activity.cost : '',
	},
	keystages: activity.keystages,
});


export const isNumericBoolean = (boolean) => {
	if (boolean === true) {
		return 1;
	} else {
		return 0;
	}
};

export const handleDate = (event, state) => {
	const fieldValue = event.target.value;
	const fieldName = event.target.name;
	// const fieldClass = event.target.getAttribute('data-date');

	if (fieldName === 'start_date') {
		if (moment(state.event.start_date).isSameOrAfter(state.event.end_date) || state.event.end_date === '') {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue,
						end_date : fieldValue
					}
				}),
				() => console.log(state)
			)
		} else {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue
					}
				}),
				() => console.log(state)
			)
		}
	} else if (fieldName === 'end_date') { 
		if (moment(state.event.end_date).isBefore(state.event.start_date)) {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						start_date : fieldValue,
						end_date : fieldValue
					}
				}),
				() => console.log(state)
			)
		} else {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						end_date : fieldValue
					}
				}),
				() => console.log(state)
			)
		}
	} else if (fieldName === 'visible_from') {
		if (moment(state.event.visible_from).isSameOrAfter(state.event.end_date) || state.event.end_date === '') {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						visible_from : fieldValue,
						visible_until : fieldValue
					}
				}),
				() => console.log(state)
			)
		} else {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						visible_from : fieldValue
					}
				}),
				() => console.log(state)
			)
		}
	} else if (fieldName === 'visible_until') {
		if (moment(state.event.visible_until).isBefore(state.event.visible_from)) {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						visible_from : fieldValue,
						visible_until : fieldValue
					}
				}),
				() => console.log(state)
			)
		} else {
			return this.setState(
				prevState => ({
					event: {
						...prevState.event,
						end_date : fieldValue
					}
				}),
				() => console.log(state)
			)
		}
	} else {
		return this.setState(
			prevState => ({
				event: {
					...prevState.event,
					[fieldName] : fieldValue
				}
			}),
			() => console.log(state)
		)
	}
};