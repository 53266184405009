import React, { Component } from 'react';
import axios from 'axios';
import history from '../../../history';
import { toast } from 'react-toastify';
import { 
	isNumericBoolean
} from '../../Utilities/adapter';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
const API_URL = process.env.REACT_APP_API_URL+'api/tags';

const INITAL_STATE = {
	name: '',
	public: false,
	accessToken: '',
};

class TagCreate extends Component {
	state = INITAL_STATE;

	componentDidMount() {
 		this.setState({ accessToken: localStorage.getItem('accessToken') });
	}

	handleSubmit = (e) => {
		e.preventDefault();

		const formData = {
			name: this.state.name,
			public: this.state.public
		};
		
		axios.post(API_URL, formData, {
			headers: {
				'Authorization': 'Bearer ' + this.state.accessToken
			}
		})
		.then(response => {
			if (response.status === 200) {
				toast.success(response.data.message, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
					onClose: () => history.push('/admin/tags')
				});
			}
		})
		.catch(errorMsg => {
			let eData = errorMsg.response.data;
			let errM = errorMsg.response.data.errors;

			if (errorMsg.response.data.hasOwnProperty('errors')) {
				Object.entries(errM).map(([field, index]) => {
					let message = 'Error: ' + index;

					toast.error(message, {
						position: toast.POSITION.TOP_CENTER,
						autoClose: false
					});
				});				
			} else {
				toast.error(eData.message, {
					position: toast.POSITION.TOP_CENTER,
					autoClose: false
				});
			}
		});
	};

	onInputChange = (e) => {
		let fieldName = e.target.name;
		let fieldValue = e.target.value;

		if (e.target.type === 'checkbox') {
			let boolean = e.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					 [fieldName] : numericBoolean 
				})
			);
		} else {
			this.setState(
				prevState => ({
					[fieldName] : fieldValue
				})
			);
		}
	}

	selectArtformCheckboxes = (e) => {
		let fieldValue = parseInt(e.target.value);

		if (e.target.checked) {
			this.setState(
				prevState => ({
					org: {
						...prevState.org,
						artforms: [...prevState.org.artforms, fieldValue]
					}
				})
			);
		} else {
			let artforms = [...this.state.org.artforms];
			let index = artforms.indexOf(fieldValue);

			if (index !== -1) {
				artforms.splice(index, 1);

				this.setState(
					prevState => ({
						org: {
							...prevState.org,
							artforms: artforms
						}
					})
				);				
			}

		}
	}

	render () {
		return (
			<form onSubmit={this.handleSubmit}>
				<div className="form-group row">
					<label 
						className="form-label text-primary col-12 col-md-3">Tag: </label>
					
					<div className="col-12 col-md-8 col-lg-6">
						<input 
							onChange={this.onInputChange} 
							value={this.state.name} 
							type="text" 
							name="name" 
							placeholder="Add New Tag" 
							className="form-control border" 
							required />
					</div>
				</div>

				<div className="form-group row">
					<label 
						htmlFor="artsmarkPartner" 
						className="col-12 col-md-3 text-primary">Public</label>

					<div className="col-12 col-md-6">
						<input onChange={this.onInputChange} 
							value={this.state.public} 
							id="isPublic" 
							type="checkbox" 
							name="public" 
							className="border mr-1"
						/>
					</div>
				</div>

				<div className="form-group row mt-4">
					<div className="col-12 col-lg-3 offset-md-3">
						<button className="btn btn-primary btn-lg">Submit</button>
						<button className="btn text-primary" onClick={() => history.goBack() }>Cancel</button>
					</div>
				</div>

		</form>
		)
	}
}

export default TagCreate;