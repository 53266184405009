import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FrontNav from '../../components/FrontNav';
import ActivityCreateForm from '../../components/Activity/ActivityCreateForm';

class ScreenCreateNewActivity extends Component {
	render () {
		return (
			<section>
				<Header />
				<FrontNav match={this.props.match} />

				<section className="bg-success">
					<div className="container">
						<div className="row py-2">
						<div className="col-12 col-md-6">
							<h2 className="pt-1 text-white">FOR ARTS & CULTURAL ORGANISATIONS</h2>
						</div>
							<div className="col-12 col-md-6">
								<p className="mb-0 text-white font-weight-bold" style={{fontSize: '18px'}}>London arts & cultural organisations can submit an event or opportunity to LookUp.</p>
							</div>
						</div>
					</div>
				</section>

				<ActivityCreateForm />

				<Footer />
			</section>
		)
	}
}

export default ScreenCreateNewActivity;