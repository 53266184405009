import React, { Component } from 'react';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import OrgEdit from '../../../components/Auth/Organisation/OrgEdit';
import requireAuth from '../../../components/Auth/requireAuth';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ScrollToTopMount from '../../../components/Utilities/ScrollToTopMount';

class ScreenAdminEditOrganisation extends Component {
	state = {
		modal: false
	};

	toggle = () => {
		this.org = React.createRef();

		this.setState({
			modal: !this.state.modal
		});
	}

	onDelete = () => {
		this.org.current.onDelete();
	}

	render () {
		return (
			<section>
				<ScrollToTopMount />
				<Header />

				<section className="bg-primary">
					<section className="container py-3">
						<div className="row">
							<div className="col d-flex justify-content-between">
								<h2 className="mb-0 text-light text-uppercase">Organisation</h2>
							</div>
						</div>
					</section>
				</section>

				<section className="container py-5">
					<OrgEdit id={this.props.match.params.id} props={this.props} ref={this.org} />
				</section>

				<div className="bg-dark">
					<div className="container">
						<div className="row py-3">
							<div className="col-12 col-md-3 offset-md-3">
								<button className="btn btn-danger" onClick={this.toggle}>DELETE THIS ORGANISATION</button>
							</div>
						</div>
					</div>
				</div>

				<Modal 
					isOpen={this.state.modal} 
					toggle={this.toggle}
					className={this.props.className}>
					<ModalHeader toggle={this.toggle}>Lookup - ANewDirection</ModalHeader>
					<ModalBody>Are you sure do you want to delete this?</ModalBody>
					<ModalFooter>
						<Button color="danger" className="btn-lg" onClick={this.onDelete}>Delete</Button>
						<Button color="link" className="btn-lg" onClick={this.toggle}>Cancel</Button>
					</ModalFooter>
				</Modal>

				<Footer />
			</section>
		)
	}
}

export default requireAuth(ScreenAdminEditOrganisation);