import React, { Component, Fragment } from 'react';
import { ClipLoader, BarLoader } from 'react-spinners';
import axios from 'axios';
import ProjectItem from './ProjectItem';
import ProjectFilter from './ProjectFilter';
import Pagination from 'react-js-pagination';
import history from "../../../history";
import PublicTagLinks from '../../PublicTagLinks';
// import '../../sass/modules/pagination.scss';
import { Scrollbars } from 'react-custom-scrollbars';

const API_URL = process.env.REACT_APP_API_URL+'api/projects';

class ProjectTaggedList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			projects: [],
			next_page: API_URL,
			loading: false,
			filtered: false,
			init_load_more: false,
			pagination: [],
			tag: this.props.tag,
			project: {
				title: '',
				type: 0,
				organisation_title: '',
				artsmark_partner: 0,
				support_send: 0,
				keystages: [],
				artform: 0,
				venue_name: '',
				postcode: '',
				status: 'active',
				sort: 'end',
				free: 0,
				visible: 1,
				page: 1
			}
		};
	}

	componentDidMount() {
		this.init();
		this.projectFilter = React.createRef();
		this.scrollInto = React.createRef();
	}

	init() {
		if(!this.state.loading) {
			this.setState({
				loading: true
			});

			axios.get(API_URL+'/tags/'+this.state.tag, {
					status: 'active',
					visible: this.state.project.visible,
					page: this.state.project.page
				})
				.then(response => {
					const pagination = response.data.meta.pagination;
					const projects = response.data.data;

					if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
						this.setState({
							projects: projects,
							loading: false,
							pagination: pagination,
							init_load_more: true
						}, () => {
							const url = window.location.href;

							console.log('>>>>');

							this.saveState(url, this.state.projects, this.state.project.page, this.state.pagination);
							this.events();
						});
					} else {
						this.setState({
							projects: projects,
							loading: false,
							pagination: pagination,
							init_load_more: false
						}, () => {
							const url = window.location.href;

							this.saveState(url, this.state.projects, this.state.project.page, this.state.pagination);
							this.events();
						});				
					}
				});
		}
	}

	events() {
		if (window.location.search.indexOf('page') > 0) {
			const url = new URL(window.location.href);
			const page = url.searchParams.get('page');

			axios.post(API_URL+'/filter', { 
					status: 'active',
					visible: 1,
					page: page,
				})
				.then(response => {
					const projects = response.data.data;
					const pagination = response.data.meta.pagination;

					this.setState(
						prevState => ({
							projects: projects,
							loading: false,
							pagination: pagination,
							init_load_more: false,
							project: {
								...prevState.project,
								page: parseInt(page)
							}
						}), () => {
							const url = window.location.href;

							this.saveState(url, this.state.projects, this.state.project.page, this.state.pagination);
						}
					);
				});
		}

		window.addEventListener("popstate", (e) => {
			if (e.state !== null) {
				this.setState(
					prevState => ({
						...prevState,
						projects: e.state.filtersHtml,
						project: {
							...prevState.project,
							page: e.state.page
						},
						pagination: e.state.pagination
					})
				);				
			}
		});
	}

	onClearFilter = () => {
		history.push('/projects');
		// this.setState(INITIAL_STATE, () => {
		// 	this.init();
		// 	this.projectFilter.current.getArtformTypes();
		// });
	};

	onFilteredResults = (props) => {
		this.setState({
			projects: [],
			init_load_more: false,
			loading: true,
			filtered: true,
			tag: '',
			project: {
				keyword: props.project.keyword,
				type: props.project.type,
				organisation_title: props.project.organisation_title,
				support_send: props.project.support_send,
				keystages: props.project.keystages,
				postcode: props.project.postcode,
				artform: props.project.artform,
				distance: props.project.distance,
				sort: props.project.sort,
				free: props.project.free,
				status: this.state.project.status,
				visible: this.state.project.visible,
				page: props.page,				
			}
		}, () => {
			this.getFilteredResults();
		});
	}

	getFilteredResults = () => {
		axios.post(API_URL+'/filter', {
			keyword: this.state.project.keyword,
			type: this.state.project.type,
			organisation_title: this.state.project.organisation_title,
			support_send: this.state.project.support_send,
			keystages: this.state.project.keystages,
			postcode: this.state.project.postcode,
			artform: this.state.project.artform,
			sort: this.state.project.sort,
			distance: this.state.project.distance,
			status: this.state.project.status,
			free: this.state.project.free,
			visible: this.state.project.visible,
			page: this.state.project.page,
		})
		.then(response => {
			const projects = response.data.data;
			const pagination = response.data.meta.pagination;

			if (this.state.pagination.total_pages >= this.state.pagination.current_page) {
				this.setState({
					projects: projects,
					loading: false,
					pagination: pagination,
				}, () => {
					const href = window.location.href;
		            let url = href + '?page=' + this.state.project.page;
					
					if (window.location.search.indexOf('page') > 0) {
						const page = this.state.project.page;
						url = '?page=' + page;
					}

					this.saveState(url, this.state.projects, this.state.project.page, this.state.pagination);
				});
			} else {
				this.setState({
					projects: projects,
					loading: false,
					pagination: pagination,
				});				
			}
		});
	};

	handleSortOrderChange = (e, sort) => {
		this.setState(
			prevState => ({
				...prevState,
				tag: '',
				project: {
					...prevState.project,
					sort: sort
				}
			}), () => {
				this.getFilteredResults();
				this.scrollInto.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}
		)
	}


	handlePageChange = (pageNumber, ref) => {
		this.setState(
			prevState => ({
				...prevState,
				project: {
					...prevState.project,
					page: pageNumber
				},
				pagination: {
					...prevState.pagination,
					current_page: pageNumber
				}
			}), () => {
				this.getFilteredResults();
				this.scrollInto.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start'
				});
			}
		)
	}

	saveState(url, container, page, pagination) {
		window.history.pushState({
			path: url,
			filtersHtml: container,
			page: page,
			pagination: pagination
		}, 'Project list', url);
	}

	renderLoadmore() {
		if (this.state.pagination.total_pages > this.state.pagination.current_page || this.state.init_load_more) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-1 py-md-4">						
						<button onClick={this.onLoadMore} className="btn btn-primary btn-lg">
							Load More <div className="loading-spinner">
							<BarLoader
								color={'#FFF'}
								loading={this.state.loading}
							/>
						</div></button>
					</div>
				</div>
			);
		}
	};

	renderResults() {
		if (this.state.projects.length) {
			return (
				this.state.projects.map(project => 
					<ProjectItem key={project.id} project={project}/>
				)
			);
		}

		if (!this.state.loading) {
			return (
				<div className="container">
					<div className="d-flex justify-content-center align-items-center py-4">
						<h1>No results found</h1>
					</div>
				</div>
			);			
		}
	}

	renderLoadFinalResult() {
		if (this.state.loading) {
			return (
				<div className="container py-10">
					<div className="d-flex justify-content-center align-items-center py-4">
						<div className="loading-spinner">
							<ClipLoader
								color={'#28A2FB'}
								size={100}
								loading={true}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<Fragment>
						{this.renderSorting()}
					<section id="tabResults" className="tab-results" ref={this.scrollInto}>
						{ this.renderPagination() }
						{ this.renderResults() }
						{ this.renderPagination() }
					</section>
				</Fragment>
			);
		}
	}

	renderPagination() {
		const pagination = this.state.pagination;

		if (pagination.total_pages > 1) {
			return (
				<section className="bg-grey">
					<div className="container">
						<nav aria-label="Pagination nav">
							<Scrollbars autoHide autoHeight>
								<Pagination 
									activePage={pagination.current_page}
									itemsCountPerPage={pagination.per_page}
									totalItemsCount={pagination.total}
									pageRangeDisplayed={pagination.total_pages}
									onChange={this.handlePageChange}
									itemClass="page-item"
									linkClass="page-link"
									firstPageText={<span className="fa fa-angle-double-left"></span>}
									lastPageText={<span className="fa fa-angle-double-right"></span>}
									nextPageText={<span className="fa fa-angle-right"></span>}
									prevPageText={<span className="fa fa-angle-left"></span>}
								/>
							</Scrollbars>
						</nav>
					</div>
				</section>
			)			
		}
	}

	renderNumberResults() {
		const results = this.state.pagination;

		if (this.state.filtered && this.state.project.postcode.length > 0 && this.state.project.sort === 'distance') {
			return (
				<span>
					{results.total} projects found by closest to postcode
				</span>
			)
		} else {
			return (
				<span>
					{results.total} projects found
				</span>
			)
		}
	}

	renderSorting() {
		return (
			<section className="bg-grey border-bottom">
				<div className="container py-2">
					<div className="row">
						<div className="col">
							<label className="pr-2">SORT</label>
							<button className={'btn btn-sort mr-1' + ((this.state.project.sort === 'title' && this.state.tag === '') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'title')}>A-Z</button>
							<button className={'btn btn-sort mr-1' + ((this.state.project.sort === 'end' && this.state.tag === '') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'end')}>MOST RECENT</button>
							<button className={'btn btn-sort mr-1' + ((this.state.project.sort === 'random' && this.state.tag === '') ? ' active' : '')} onClick={(e) => this.handleSortOrderChange(e, 'random')}>RANDOM</button>
							<PublicTagLinks activity_type="projects" tag={this.state.tag}/>
							{this.renderNumberResults()}
						</div>
					</div>
				</div>
			</section>
		);
	}


	render() {
		return (
			<Fragment>
				<ProjectFilter 
					ref={this.projectFilter}
					onClearFilter={this.onClearFilter} 
					onFilteredResults={this.onFilteredResults}
				/>

				{ this.renderLoadFinalResult() }
			</Fragment>
		)
	}
}

export default ProjectTaggedList;