import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import {
	Collapse,
	Container,
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem
} from 'reactstrap';
import axios from 'axios';
import FileDownload from 'js-file-download';
import './Header.css';

const BASE_URL = process.env.REACT_APP_API_URL + 'api';

class Header extends Component {
	state = {
		isOpen: false
	};

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	onExportList = (e, list) => {
		e.preventDefault();
		console.log(list);

		let url = "";
		if (list === 'organisations') {
			url = BASE_URL + '/export/organisations';
		} else if (list === 'activities') {
			url = BASE_URL + '/export/activities';
		}

		const token = localStorage.getItem('accessToken');

		axios.get(url, {
			headers: { Authorization: "Bearer " + token }
		})
			.then(response => {
				FileDownload(response.data, list + '-list.csv');
			});
	}

	renderLogin() {
		const auth = JSON.parse(localStorage.getItem('authUser'));

		if (this.props.authenticated && auth && typeof this.props.props !== 'undefined') {
			return (
				<Nav className="ml-auto" navbar>
					{this.props.props.match.path === '/admin/organisations' ?
						<NavItem>
							<button onClick={(e) => this.onExportList(e, 'organisations')} className="border-0 bg-dark nav-link text-white header-export"><i className="fa fa-download mr-1"></i>EXPORT</button>
						</NavItem>
						: ''}

					{this.props.props.match.path === '/admin/activities' ?
						<NavItem>
							<button onClick={(e) => this.onExportList(e, 'activities')} className="border-0 bg-dark nav-link text-white header-export"><i className="fa fa-download mr-1"></i>EXPORT</button>
						</NavItem>
						: ''}
					<NavItem>
						<NavLink to="/dashboard" className="nav-link">{auth.first_name} {auth.last_name}</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="/logout" className="nav-link">Logout</NavLink>
					</NavItem>
				</Nav>
			);
		} else if (this.props.authenticated && auth && typeof this.props.props === 'undefined') {
			return (
				<Nav className="ml-auto" navbar>
					<NavItem>
						<NavLink to="/dashboard" className="nav-link">{auth.first_name} {auth.last_name}</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to="/logout" className="nav-link">Logout</NavLink>
					</NavItem>
				</Nav>
			);
		}
	}

	render() {
		const headerTitle = 'LookUp';

		return (
			<Navbar color="dark" dark expand="lg" className="header-admin">
				<Container className="py-2 py-lg-0">
					<NavbarBrand className="navbar-brand" href="/" target="_blank">{headerTitle}</NavbarBrand>
					<NavbarToggler onClick={this.toggle}></NavbarToggler>

					<Collapse className="mt-3 mt-md-0" isOpen={this.state.isOpen} navbar>
						<Nav className="mr-auto" navbar>
							<NavItem>
								<NavLink className="nav-link" activeClassName="active" to="/dashboard">Dashboard</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="nav-link" activeClassName="active" to="/admin/organisations">Organisation</NavLink>
							</NavItem>
							<NavItem>
								<NavLink className="nav-link" activeClassName="active" to="/admin/activities">Activity</NavLink>
							</NavItem>
						</Nav>

						{this.renderLogin()}
					</Collapse>
				</Container>
			</Navbar>
		)
	}
}

function mapStateToProps(state) {
	return {
		authenticated: state.auth.authenticated,
		user: state.auth.user
	};
}

export default connect(mapStateToProps)(Header);