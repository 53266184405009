import React, { Component } from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import FrontNav from '../../components/FrontNav';
import AreaLinks from '../../components/AreaLinks';
import QuickSearchLinks from '../../components/QuickSearchLinks';
import FormLinks from '../../components/FormLinks';


class ScreenHome extends Component {
	render() {
		return (
			<section>
				<Header />
				<FrontNav match={this.props.match} />

				<div className="container">
					<div className="row">
						<div className="col-md-6 pt-5 pb-5"><h3>LookUp helps London schools to navigate, search for and engage with arts & cultural organisations and opportunities.</h3></div>
						<div className="col-md-6 pt-5 pb-5">Teachers can find projects, workshops, CPDs, events, performances and resources for their school and search over 700 organisations who offer arts and cultural services.</div>
					</div>
				</div>

				<AreaLinks/>
				<QuickSearchLinks/>
				<FormLinks/>

				<Footer />
			</section>
		)
	}
}

export default ScreenHome;