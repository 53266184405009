import React, { Component } from 'react';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import ActivityCreateForm from '../../../components/Auth/Activity/ActivityCreateForm';
import requireAuth from '../../../components/Auth/requireAuth';
import { NavLink } from 'react-router-dom';

class ScreenAdminCreateNewEvent extends Component {
	render() {

		let activity_type = this.props.match.params.type;

		return (
			<section>
				<Header />
				
				<section className="bg-primary">
					<section className="container py-3">
						<div className="row">
							<div className="col d-flex justify-content-between">
								<h2 className="mb-0 text-light text-uppercase">Create {activity_type} Activity</h2>
							</div>
						</div>
					</section>
				</section>

				<section className="border-bottom border-bottom-primary">
					<div className="container py-5">
						<div className="form-group row">
							<div className="col-12 col-md-3 text-primary">
								<p>Section</p>
							</div>

							<div className="col-12 col-md-8 col-lg-6">
								<div className="row mb-2">
									<div className="col-md-4"> <NavLink to="/admin/activities/event/create" className="btn btn-activity-selector">Event</NavLink></div>
									<div className="col-md-8"><strong>A single date or on consequtive days lasting no longer than two weeks</strong></div>
								</div>

								<div className="row mb-1">
									<div className="col-md-4"><NavLink to="/admin/activities/project/create" className="btn btn-activity-selector">Project</NavLink></div>
									<div className="col-md-8"><strong>A recoccuring activity on a specific day or days or over a longer period like a term</strong></div>
								</div>

								<div className="row">
									<div className="col-md-4"><NavLink to="/admin/activities/resource/create" className="btn btn-activity-selector">Resource</NavLink></div>
									<div className="col-md-8"><strong>Something that is not date related</strong></div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section className="container py-5">
					<ActivityCreateForm key={activity_type} activity_type={activity_type} />
				</section>
				<Footer />
			</section>
		)
	}
}

export default requireAuth(ScreenAdminCreateNewEvent);