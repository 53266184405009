import axios from 'axios';
import {
	EVENT_TYPES,
	ARTFORM_TYPES
} from './types';

const BASE_URL = process.env.REACT_APP_API_URL+'api';

export const getArtformTypes = () => dispatch => {
	axios.get(BASE_URL+'/artform-types')
	.then(response => {
		let data = response.data.map((key) => {
			let art = Object.assign({}, key);
			art.isChecked = false;

			return art;
		});

		dispatch({ type: ARTFORM_TYPES, payload: data });

		// this.setState({
		// 	artforms: data
		// });
	});
};

export const getEventTypes = () => dispatch => {
	const eventTypes = [
		{
			id: 1,
			name: 'Programme/project'
		},
		{
			id: 2,
			name: 'Workshop'
		},
		{
			id: 3,
			name: 'Resource'
		}
	];

	dispatch({ type: EVENT_TYPES, payload: eventTypes});
};