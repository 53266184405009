import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Header from '../../../components/Auth/Header';
import Footer from '../../../components/Auth/Footer';
import TagList from '../../../components/Auth/Tag/TagList';
import requireAuth from '../../../components/Auth/requireAuth';
import ScreenAdminEditOrganisation from './Edit';
import ScreenAdminCreateNewOrganisation from './Create';

import { NavLink } from 'react-router-dom';

class ScreenAdminTagsList extends Component {
	render () {
		const { match } = this.props;

		return (
			<section>
				<Header props={this.props} />

				<section className="bg-primary">
					<section className="container py-3">
						<div className="row">
							<div className="col">
								<h2 className="mb-0 mr-5 text-light text-uppercase float-left">Tags</h2>
								<NavLink to="/admin/tags/create" className="btn btn-success text-white font-weight-bold"><i className="fa fa-plus" aria-hidden="true"></i> ADD TAG</NavLink>
							
							</div>

						

					

						</div>
					</section>
				</section>



				<TagList props={this.props} />
	    		<Footer />

				<Route path={`${match.path}/:id`} component={ScreenAdminEditOrganisation} />
				<Route path={`${match.path}/create`} component={ScreenAdminCreateNewOrganisation} />
			</section>
		);
	}
}

export default requireAuth(ScreenAdminTagsList);