import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL + "api";

class PublicTagLinks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tags: []
        }
    }

  componentDidMount() {
    axios.get(BASE_URL + "/get-public-tags").then((response) => {
        this.setState({
          tags: response.data.data,
        });
      });
  }

  render() {
    return (
      <div className="d-inline">
        {this.state.tags.map((tag, index) => {
          return (
            <a
              href={"/tags/" + tag.slug + "/" + this.props.activity_type}
              className={'btn btn-sort mr-1' + ((this.props.tag === tag.slug) ? ' active' : '')}
              key={index}
              role="button"
            >
              {tag.name.toUpperCase()}
            </a>
          );
        })}
      </div>
    );
  }
}

export default PublicTagLinks;
