import React from 'react';

const MegaLinks = () => (
	<ul className="mega-nav">
                  <li>
                    <a class="" href="https://www.anewdirection.org.uk/training-cpd">
                      Training
                    </a>
                  </li>
                  <li>
                    <a class="" href="https://www.anewdirection.org.uk/networks">
                      Networks
                    </a>
                  </li>
                  <li>
                    <a class="" href="https://www.anewdirection.org.uk/resources">
                      Resources
                    </a>
                  </li>
                  <li>
                    <a class="" href="https://www.anewdirection.org.uk/research">
                      Research
                    </a>
                  </li>
                </ul>
)

export default MegaLinks;