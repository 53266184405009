import React from 'react';

const Logo = () => (
	<svg width="180px" height="64px" viewBox="0 0 712 299" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	    <title>AND black</title>
	    <desc>Created with Sketch.</desc>
	    <defs>
	        <polygon id="path-1" points="0.06 0.793 711.903 0.793 711.903 227.141 0.06 227.141"></polygon>
	        <polygon id="path-3" points="0.033 0.429 102.227 0.429 102.227 40.94 0.033 40.94"></polygon>
	        <polygon id="path-5" points="0.166 0.526 53.421 0.526 53.421 54.94 0.166 54.94"></polygon>
	    </defs>
	    <g id="AND-black" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
	        <g id="Group-3">
	            <mask id="mask-2" fill="white">
	                <use xlinkHref="#path-1"></use>
	            </mask>
	            <g id="Clip-2"></g>
	            <polyline id="Fill-1" fill="#000000" mask="url(#mask-2)" points="430.653 101.149 430.653 0.793 0 0.793 0 126.63 65.569 126.63 65.569 227.141 711.903 227.141 711.903 101.149 430.653 101.149"></polyline>
	        </g>
	        <path d="M127.847,268.437 C128.646,270.635 129.165,272.183 129.405,273.081 C129.645,273.981 129.845,275.049 130.005,276.288 L130.244,276.288 C130.403,275.049 130.613,273.92 130.873,272.902 C131.134,271.883 131.602,270.414 132.281,268.497 L140.312,245.066 L146.484,245.066 L130.425,287.674 L129.825,287.674 L120.057,261.545 C119.657,260.507 119.388,259.608 119.248,258.848 C119.107,258.09 118.998,257.23 118.919,256.272 L118.679,256.272 C118.558,257.271 118.428,258.15 118.289,258.909 C118.148,259.668 117.899,260.547 117.54,261.545 L107.712,287.674 L107.113,287.674 L91.052,245.066 L97.284,245.066 L105.255,268.497 C105.933,270.414 106.403,271.883 106.663,272.902 C106.922,273.92 107.132,275.049 107.292,276.288 L107.532,276.288 C107.691,275.009 107.911,273.901 108.191,272.962 C108.47,272.024 108.971,270.515 109.689,268.437 L118.438,244.407 L119.158,244.407 L127.847,268.437" id="Fill-4" fill="#1A1919"></path>
	        <path d="M157.182,265.081 C155.642,266.44 154.733,268.298 154.455,270.655 L154.515,270.714 C155.313,270.355 156.351,270.175 157.63,270.175 L167.159,270.175 C168.357,270.175 169.236,270.205 169.796,270.265 C170.354,270.325 170.794,270.455 171.114,270.655 L171.174,270.594 C171.014,268.197 170.224,266.34 168.807,265.022 C167.389,263.704 165.501,263.043 163.143,263.043 C160.707,263.043 158.719,263.724 157.182,265.081 Z M172.973,262.354 C175.329,264.972 176.508,268.538 176.508,273.051 L176.508,274.071 L157.571,274.071 C155.932,274.071 154.833,273.93 154.274,273.651 L154.155,273.771 L154.394,274.43 C154.635,277.147 155.572,279.264 157.211,280.782 C158.849,282.301 161.007,283.059 163.683,283.059 C165.441,283.059 167.018,282.72 168.418,282.04 C169.815,281.362 171.214,280.223 172.612,278.624 L175.789,281.92 C172.632,285.915 168.576,287.914 163.623,287.914 C160.746,287.914 158.22,287.304 156.043,286.085 C153.864,284.868 152.177,283.16 150.978,280.962 C149.78,278.765 149.182,276.207 149.182,273.291 C149.182,270.335 149.76,267.728 150.919,265.47 C152.077,263.213 153.724,261.476 155.863,260.256 C157.999,259.039 160.486,258.429 163.324,258.429 C167.398,258.429 170.614,259.739 172.973,262.354 Z" id="Fill-5" fill="#1A1919"></path>
	        <path d="M202.827,286.055 C200.528,284.818 198.741,283.081 197.464,280.842 C196.183,278.605 195.546,276.047 195.546,273.171 C195.546,270.294 196.183,267.738 197.464,265.5 C198.741,263.264 200.528,261.526 202.827,260.288 C205.123,259.049 207.75,258.429 210.707,258.429 C214.142,258.429 217.3,259.369 220.176,261.246 L217.719,265.561 C215.48,264.082 213.084,263.343 210.527,263.343 C207.73,263.343 205.433,264.263 203.636,266.099 C201.838,267.938 200.938,270.294 200.938,273.171 C200.938,276.047 201.838,278.406 203.636,280.243 C205.433,282.081 207.73,282.999 210.527,282.999 C213.084,282.999 215.48,282.26 217.719,280.782 L220.176,285.097 C217.3,286.975 214.142,287.914 210.707,287.914 C207.75,287.914 205.123,287.293 202.827,286.055" id="Fill-6" fill="#1A1919"></path>
	        <path d="M241.45,258.548 L241.45,264.362 C240.77,264.283 240.092,264.243 239.413,264.243 C236.656,264.243 234.548,265.182 233.091,267.059 C231.632,268.937 230.903,271.654 230.903,275.209 L230.903,287.014 L225.809,287.014 L225.809,259.328 L230.903,259.328 C230.903,260.567 230.833,261.926 230.693,263.404 C230.553,264.882 230.384,266.16 230.183,267.239 L230.483,267.359 C231.842,261.447 235.057,258.49 240.132,258.49 C240.65,258.49 241.092,258.51 241.45,258.548" id="Fill-7" fill="#1A1919"></path>
	        <path d="M252.326,265.081 C250.787,266.44 249.879,268.298 249.599,270.655 L249.66,270.714 C250.458,270.355 251.496,270.175 252.775,270.175 L262.304,270.175 C263.503,270.175 264.381,270.205 264.94,270.265 C265.5,270.325 265.938,270.455 266.259,270.655 L266.319,270.594 C266.159,268.197 265.37,266.34 263.952,265.022 C262.533,263.704 260.645,263.043 258.289,263.043 C255.851,263.043 253.864,263.724 252.326,265.081 Z M268.117,262.354 C270.474,264.972 271.653,268.538 271.653,273.051 L271.653,274.071 L252.716,274.071 C251.077,274.071 249.978,273.93 249.42,273.651 L249.3,273.771 L249.539,274.43 C249.779,277.147 250.718,279.264 252.356,280.782 C253.994,282.301 256.151,283.059 258.829,283.059 C260.586,283.059 262.164,282.72 263.563,282.04 C264.961,281.362 266.359,280.223 267.758,278.624 L270.933,281.92 C267.777,285.915 263.722,287.914 258.768,287.914 C255.891,287.914 253.364,287.304 251.187,286.085 C249.01,284.868 247.322,283.16 246.123,280.962 C244.926,278.765 244.326,276.207 244.326,273.291 C244.326,270.335 244.905,267.728 246.064,265.47 C247.223,263.213 248.87,261.476 251.008,260.256 C253.144,259.039 255.632,258.429 258.469,258.429 C262.544,258.429 265.76,259.739 268.117,262.354 Z" id="Fill-8" fill="#1A1919"></path>
	        <path d="M280.491,274.19 C279.272,275.23 278.664,276.568 278.664,278.206 C278.664,279.883 279.263,281.212 280.462,282.19 C281.66,283.17 283.258,283.659 285.256,283.659 C287.294,283.659 288.991,283.14 290.349,282.1 C291.708,281.062 292.387,279.744 292.387,278.145 C292.387,276.548 291.708,275.23 290.349,274.19 C288.991,273.152 287.294,272.631 285.256,272.631 C283.298,272.631 281.71,273.152 280.491,274.19 Z M295.054,261.126 C296.911,262.924 297.84,265.741 297.84,269.576 L297.84,287.014 L292.686,287.014 C292.686,286.055 292.736,285.257 292.837,284.618 C292.935,283.979 293.126,283.22 293.405,282.34 L293.225,282.22 C292.226,284.058 290.889,285.466 289.211,286.445 C287.533,287.424 285.635,287.914 283.517,287.914 C280.441,287.914 277.965,287.014 276.087,285.216 C274.209,283.418 273.269,281.062 273.269,278.145 C273.269,275.149 274.209,272.772 276.087,271.014 C277.965,269.256 280.462,268.377 283.578,268.377 C285.735,268.377 287.642,268.817 289.302,269.696 C290.958,270.575 292.246,271.814 293.166,273.411 L293.347,273.291 C293.105,272.493 292.945,271.115 292.866,269.157 C292.706,265.162 290.41,263.164 285.975,263.164 C283.657,263.164 280.881,263.683 277.645,264.722 L276.207,260.107 C279.682,258.99 283.057,258.429 286.334,258.429 C290.289,258.429 293.196,259.328 295.054,261.126 Z" id="Fill-9" fill="#1A1919"></path>
	        <path d="M313.241,256.811 C313.241,258.09 313.16,259.029 313.002,259.627 L313.182,259.747 C313.62,259.468 314.38,259.328 315.459,259.328 L318.636,259.328 L318.636,264.122 L315.459,264.122 C314.779,264.122 314.29,264.093 313.99,264.033 C313.69,263.972 313.422,263.864 313.182,263.704 L313.002,263.823 C313.16,264.422 313.241,265.38 313.241,266.699 L313.241,287.014 L308.208,287.014 L308.208,266.699 C308.208,265.38 308.287,264.422 308.447,263.823 L308.327,263.704 C308.088,263.864 307.808,263.972 307.488,264.033 C307.168,264.093 306.669,264.122 305.99,264.122 L303.113,264.122 L303.113,259.328 L305.99,259.328 C307.108,259.328 307.888,259.468 308.327,259.747 L308.447,259.627 C308.287,259.029 308.208,258.09 308.208,256.811 L308.208,248.122 L312.942,244.526 L313.241,244.526 L313.241,256.811" id="Fill-10" fill="#1A1919"></path>
	        <path d="M327.894,265.081 C326.355,266.44 325.446,268.298 325.168,270.655 L325.227,270.714 C326.026,270.355 327.064,270.175 328.344,270.175 L337.872,270.175 C339.07,270.175 339.949,270.205 340.509,270.265 C341.067,270.325 341.507,270.455 341.827,270.655 L341.888,270.594 C341.727,268.197 340.937,266.34 339.519,265.022 C338.101,263.704 336.214,263.043 333.856,263.043 C331.42,263.043 329.432,263.724 327.894,265.081 Z M343.685,262.354 C346.042,264.972 347.221,268.538 347.221,273.051 L347.221,274.071 L328.284,274.071 C326.645,274.071 325.547,273.93 324.987,273.651 L324.868,273.771 L325.107,274.43 C325.348,277.147 326.285,279.264 327.924,280.782 C329.561,282.301 331.72,283.059 334.396,283.059 C336.154,283.059 337.731,282.72 339.131,282.04 C340.528,281.362 341.927,280.223 343.325,278.624 L346.502,281.92 C343.345,285.915 339.289,287.914 334.337,287.914 C331.46,287.914 328.932,287.304 326.756,286.085 C324.577,284.868 322.89,283.16 321.691,280.962 C320.494,278.765 319.894,276.207 319.894,273.291 C319.894,270.335 320.473,267.728 321.632,265.47 C322.79,263.213 324.437,261.476 326.576,260.256 C328.712,259.039 331.199,258.429 334.037,258.429 C338.111,258.429 341.327,259.739 343.685,262.354 Z" id="Fill-11" fill="#1A1919"></path>
	        <g id="Group-16" transform="translate(366.000000, 258.000000)">
	            <mask id="mask-4" fill="white">
	                <use xlinkHref="#path-3"></use>
	            </mask>
	            <g id="Clip-13"></g>
	            <path d="M10.969,10.467 C9.751,11.646 9.141,13.214 9.141,15.171 C9.141,17.129 9.751,18.698 10.969,19.875 C12.187,21.054 13.735,21.644 15.614,21.644 C17.491,21.644 19.039,21.054 20.259,19.875 C21.476,18.698 22.086,17.129 22.086,15.171 C22.086,13.214 21.476,11.646 20.259,10.467 C19.039,9.289 17.491,8.699 15.614,8.699 C13.735,8.699 12.187,9.289 10.969,10.467 Z M23.585,2.256 C25.941,3.476 27.789,5.204 29.128,7.441 C30.466,9.679 31.136,12.255 31.136,15.171 C31.136,18.088 30.466,20.664 29.128,22.902 C27.789,25.14 25.941,26.868 23.585,28.085 C21.226,29.304 18.569,29.914 15.614,29.914 C12.618,29.914 9.94,29.304 7.584,28.085 C5.226,26.868 3.379,25.14 2.041,22.902 C0.701,20.664 0.033,18.088 0.033,15.171 C0.033,12.255 0.701,9.679 2.041,7.441 C3.379,5.204 5.226,3.476 7.584,2.256 C9.94,1.039 12.618,0.429 15.614,0.429 C18.569,0.429 21.226,1.039 23.585,2.256 Z" id="Fill-12" fill="#1A1919" mask="url(#mask-4)"></path>
	            <path d="M46.235,10.317 C44.997,11.515 44.378,13.133 44.378,15.171 C44.378,17.209 44.997,18.827 46.235,20.026 C47.474,21.224 49.112,21.824 51.149,21.824 C53.186,21.824 54.824,21.224 56.063,20.026 C57.302,18.827 57.921,17.209 57.921,15.171 C57.921,13.133 57.302,11.515 56.063,10.317 C54.824,9.12 53.186,8.52 51.149,8.52 C49.112,8.52 47.474,9.12 46.235,10.317 Z M61.068,2.227 C63.006,3.425 64.514,5.143 65.593,7.38 C66.671,9.619 67.211,12.235 67.211,15.231 C67.211,18.188 66.681,20.774 65.622,22.992 C64.563,25.208 63.075,26.916 61.158,28.116 C59.239,29.314 57.022,29.914 54.505,29.914 C52.147,29.914 50.08,29.304 48.304,28.085 C46.525,26.868 45.196,25.081 44.317,22.722 L44.078,22.782 C44.438,24.94 44.617,26.737 44.617,28.176 L44.617,40.999 L35.748,40.999 L35.748,1.328 L44.678,1.328 C44.678,3.766 44.457,5.843 44.017,7.561 L44.258,7.621 C46.335,2.827 49.731,0.429 54.445,0.429 C56.922,0.429 59.13,1.029 61.068,2.227 Z" id="Fill-14" fill="#1A1919" mask="url(#mask-4)"></path>
	            <path d="M81.252,10.317 C80.014,11.515 79.394,13.133 79.394,15.171 C79.394,17.209 80.014,18.827 81.252,20.026 C82.49,21.224 84.129,21.824 86.166,21.824 C88.203,21.824 89.841,21.224 91.08,20.026 C92.318,18.827 92.937,17.209 92.937,15.171 C92.937,13.133 92.318,11.515 91.08,10.317 C89.841,9.12 88.203,8.52 86.166,8.52 C84.129,8.52 82.49,9.12 81.252,10.317 Z M96.085,2.227 C98.022,3.425 99.53,5.143 100.609,7.38 C101.687,9.619 102.227,12.235 102.227,15.231 C102.227,18.188 101.697,20.774 100.639,22.992 C99.58,25.208 98.092,26.916 96.175,28.116 C94.256,29.314 92.039,29.914 89.521,29.914 C87.164,29.914 85.097,29.304 83.32,28.085 C81.542,26.868 80.213,25.081 79.334,22.722 L79.095,22.782 C79.455,24.94 79.634,26.737 79.634,28.176 L79.634,40.999 L70.765,40.999 L70.765,1.328 L79.694,1.328 C79.694,3.766 79.474,5.843 79.034,7.561 L79.275,7.621 C81.351,2.827 84.748,0.429 89.463,0.429 C91.938,0.429 94.146,1.029 96.085,2.227 Z" id="Fill-15" fill="#1A1919" mask="url(#mask-4)"></path>
	        </g>
	        <path d="M482.159,268.467 C480.94,269.646 480.331,271.214 480.331,273.171 C480.331,275.129 480.94,276.698 482.159,277.875 C483.378,279.054 484.925,279.644 486.804,279.644 C488.682,279.644 490.229,279.054 491.448,277.875 C492.666,276.698 493.276,275.129 493.276,273.171 C493.276,271.214 492.666,269.646 491.448,268.467 C490.229,267.289 488.682,266.699 486.804,266.699 C484.925,266.699 483.378,267.289 482.159,268.467 Z M494.774,260.256 C497.131,261.476 498.978,263.204 500.317,265.441 C501.655,267.679 502.325,270.255 502.325,273.171 C502.325,276.088 501.655,278.664 500.317,280.902 C498.978,283.14 497.131,284.868 494.774,286.085 C492.417,287.304 489.76,287.914 486.804,287.914 C483.807,287.914 481.131,287.304 478.773,286.085 C476.416,284.868 474.568,283.14 473.23,280.902 C471.891,278.664 471.223,276.088 471.223,273.171 C471.223,270.255 471.891,267.679 473.23,265.441 C474.568,263.204 476.416,261.476 478.773,260.256 C481.131,259.039 483.807,258.429 486.804,258.429 C489.76,258.429 492.417,259.039 494.774,260.256 Z" id="Fill-17" fill="#1A1919"></path>
	        <path d="M525.876,258.609 L525.876,267.478 C524.956,267.399 524.058,267.359 523.18,267.359 C520.821,267.359 519.034,268.017 517.815,269.336 C516.597,270.655 515.988,272.791 515.988,275.748 L515.988,287.014 L506.938,287.014 L506.938,259.328 L515.509,259.328 C515.509,262.365 515.228,265.401 514.67,268.437 L514.909,268.497 C516.307,261.825 519.244,258.49 523.719,258.49 C524.357,258.49 525.077,258.53 525.876,258.609" id="Fill-18" fill="#1A1919"></path>
	        <path d="M542.856,257.23 C542.856,257.631 542.835,258.05 542.797,258.49 C542.756,258.929 542.637,259.308 542.436,259.627 L542.557,259.747 C542.876,259.548 543.224,259.429 543.605,259.388 C543.984,259.349 544.534,259.328 545.253,259.328 L548.309,259.328 L548.309,266.939 L545.253,266.939 C544.453,266.939 543.885,266.919 543.546,266.878 C543.205,266.839 542.876,266.719 542.557,266.52 L542.436,266.639 C542.637,266.959 542.756,267.349 542.797,267.807 C542.835,268.267 542.856,269.017 542.856,270.055 L542.856,287.014 L533.807,287.014 L533.807,270.055 C533.807,269.017 533.827,268.267 533.867,267.807 C533.907,267.349 534.026,266.959 534.226,266.639 L534.106,266.52 C533.786,266.719 533.457,266.839 533.118,266.878 C532.778,266.919 532.209,266.939 531.41,266.939 L528.893,266.939 L528.893,259.328 L531.41,259.328 C532.13,259.328 532.678,259.349 533.058,259.388 C533.437,259.429 533.786,259.548 534.106,259.747 L534.226,259.627 C533.946,259.148 533.807,258.35 533.807,257.23 L533.807,250.039 L541.957,244.526 L542.856,244.526 L542.856,257.23" id="Fill-19" fill="#1A1919"></path>
	        <path d="M579.891,287.014 L571.082,287.014 C571.082,284.897 571.381,282.58 571.98,280.063 L571.74,280.002 C570.782,282.64 569.523,284.618 567.966,285.936 C566.407,287.253 564.409,287.914 561.973,287.914 C558.935,287.914 556.479,286.935 554.601,284.977 C552.724,283.02 551.785,280.183 551.785,276.467 L551.785,259.328 L561.074,259.328 L561.074,273.771 C561.074,275.808 561.482,277.307 562.303,278.265 C563.12,279.224 564.25,279.703 565.687,279.703 C567.206,279.703 568.445,279.155 569.403,278.055 C570.362,276.957 570.842,275.268 570.842,272.992 L570.842,259.328 L579.891,259.328 L579.891,287.014" id="Fill-20" fill="#1A1919"></path>
	        <path d="M611.951,261.276 C613.63,263.174 614.469,265.98 614.469,269.696 L614.469,287.014 L605.479,287.014 L605.479,272.212 C605.479,270.255 605.069,268.788 604.25,267.807 C603.432,266.83 602.282,266.34 600.805,266.34 C599.007,266.34 597.599,266.929 596.58,268.107 C595.56,269.286 595.052,271.133 595.052,273.651 L595.052,287.014 L586.003,287.014 L586.003,259.328 L594.932,259.328 C594.932,260.926 594.891,262.135 594.812,262.954 C594.731,263.773 594.592,264.642 594.392,265.561 L594.632,265.68 C595.551,263.443 596.82,261.676 598.437,260.377 C600.056,259.079 602.162,258.429 604.76,258.429 C607.876,258.429 610.273,259.378 611.951,261.276" id="Fill-21" fill="#1A1919"></path>
	        <path d="M620.341,287.014 L629.39,287.014 L629.39,259.328 L620.341,259.328 L620.341,287.014 Z M628.941,246.054 C629.998,247.113 630.528,248.462 630.528,250.1 C630.528,251.777 629.998,253.136 628.941,254.174 C627.882,255.213 626.533,255.733 624.896,255.733 C623.218,255.733 621.859,255.213 620.82,254.174 C619.781,253.136 619.263,251.777 619.263,250.1 C619.263,248.462 619.781,247.113 620.82,246.054 C621.859,244.997 623.218,244.466 624.896,244.466 C626.533,244.466 627.882,244.997 628.941,246.054 Z" id="Fill-22" fill="#1A1919"></path>
	        <g id="Group-26" transform="translate(633.000000, 244.000000)">
	            <mask id="mask-6" fill="white">
	                <use xlinkHref="#path-5"></use>
	            </mask>
	            <g id="Clip-24"></g>
	            <path d="M14.128,13.23 C14.128,13.631 14.107,14.05 14.069,14.49 C14.028,14.929 13.909,15.308 13.709,15.627 L13.829,15.747 C14.148,15.548 14.497,15.429 14.878,15.388 C15.257,15.349 15.807,15.328 16.525,15.328 L19.582,15.328 L19.582,22.939 L16.525,22.939 C15.725,22.939 15.157,22.919 14.817,22.878 C14.477,22.839 14.148,22.719 13.829,22.52 L13.709,22.639 C13.909,22.959 14.028,23.349 14.069,23.807 C14.107,24.267 14.128,25.017 14.128,26.055 L14.128,43.014 L5.079,43.014 L5.079,26.055 C5.079,25.017 5.099,24.267 5.14,23.807 C5.179,23.349 5.299,22.959 5.499,22.639 L5.379,22.52 C5.058,22.719 4.729,22.839 4.39,22.878 C4.051,22.919 3.481,22.939 2.682,22.939 L0.166,22.939 L0.166,15.328 L2.682,15.328 C3.401,15.328 3.95,15.349 4.33,15.388 C4.709,15.429 5.058,15.548 5.379,15.747 L5.499,15.627 C5.219,15.148 5.079,14.35 5.079,13.23 L5.079,6.039 L13.229,0.526 L14.128,0.526 L14.128,13.23" id="Fill-23" fill="#1A1919" mask="url(#mask-6)"></path>
	            <path d="M35.742,54.999 L26.693,54.999 L33.165,40.858 L21.36,15.328 L31.068,15.328 L35.682,26.175 C36.201,27.414 36.57,28.503 36.791,29.441 C37.01,30.38 37.16,31.568 37.24,33.007 L37.48,32.947 C37.599,31.149 38.019,29.391 38.738,27.673 L43.712,15.328 L53.421,15.328 L35.742,54.999" id="Fill-25" fill="#1A1919" mask="url(#mask-6)"></path>
	        </g>
	        <path d="M78.06,87.26 L52.446,87.26 L47.838,101.302 L25.329,101.302 L54.374,26.276 L76.132,26.276 L105.177,101.302 L82.67,101.302 L78.06,87.26 Z M72.916,71.183 L65.306,47.712 L57.591,71.183 L72.916,71.183 Z" id="Fill-27" fill="#FFFFFE"></path>
	        <polyline id="Fill-28" fill="#FFFFFE" points="210.462 101.302 194.599 101.302 159.229 61.002 159.016 61.002 159.016 101.302 138.652 101.302 138.652 26.275 154.515 26.275 189.884 66.576 190.099 66.576 190.099 26.275 210.462 26.275 210.462 101.302"></polyline>
	        <polyline id="Fill-29" fill="#FFFFFE" points="275.662 82.652 275.662 101.302 226.253 101.302 226.253 26.276 274.056 26.276 274.056 44.925 246.617 44.925 246.617 53.822 270.731 53.822 270.731 71.505 246.617 71.505 246.617 82.652 275.662 82.652"></polyline>
	        <polyline id="Fill-30" fill="#FFFFFE" points="378.161 101.302 361.225 101.302 345.362 58.108 345.148 58.108 329.286 101.302 312.354 101.302 285.344 26.275 309.246 26.275 321.248 67.327 321.463 67.327 336.683 26.275 353.831 26.275 369.053 67.327 369.265 67.327 381.269 26.275 405.17 26.275 378.161 101.302"></polyline>
	        <path d="M138.479,131.133 C144.587,134.134 149.286,138.457 152.573,144.102 C155.858,149.748 157.503,156.429 157.503,164.145 C157.503,171.862 155.858,178.545 152.573,184.189 C149.286,189.833 144.568,194.156 138.424,197.158 C132.277,200.159 125.027,201.659 116.667,201.659 L91.052,201.659 L91.052,126.632 L116.667,126.632 C125.098,126.632 132.369,128.131 138.479,131.133 M111.415,144.852 L111.415,183.438 L116.774,183.438 C122.703,183.438 127.403,181.794 130.868,178.508 C134.334,175.221 136.066,170.433 136.066,164.145 C136.066,157.858 134.334,153.071 130.868,149.782 C127.403,146.496 122.703,144.852 116.774,144.852 L111.415,144.852" id="Fill-31" fill="#FFFFFE"></path>
	        <polygon id="Fill-32" fill="#FFFFFE" points="169.005 201.659 189.368 201.659 189.368 126.632 169.005 126.632"></polygon>
	        <path d="M244.815,201.658 L225.522,173.789 L225.522,201.658 L205.159,201.658 L205.159,126.632 L237.206,126.632 C243.564,126.632 248.85,127.758 253.069,130.008 C257.283,132.258 260.411,135.244 262.447,138.957 C264.482,142.673 265.499,146.781 265.499,151.284 C265.499,156.498 264.142,161.11 261.429,165.111 C258.713,169.112 254.711,172.005 249.423,173.789 L270.218,201.658 L244.815,201.658 Z M225.522,144.638 L225.522,160.715 L234.419,160.715 C237.635,160.715 240.1,160.037 241.813,158.679 C243.527,157.324 244.386,155.285 244.386,152.568 C244.386,147.283 241.062,144.638 234.419,144.638 L225.522,144.638 Z" id="Fill-33" fill="#FFFFFE"></path>
	        <polyline id="Fill-34" fill="#FFFFFE" points="330.056 183.009 330.056 201.659 280.647 201.659 280.647 126.632 328.449 126.632 328.449 145.282 301.011 145.282 301.011 154.178 325.126 154.178 325.126 171.862 301.011 171.862 301.011 183.009 330.056 183.009"></polyline>
	        <path d="M362.21,198.605 C355.959,195.496 351.027,190.993 347.421,185.1 C343.811,179.205 342.007,172.221 342.007,164.145 C342.007,156.073 343.811,149.086 347.421,143.191 C351.027,137.295 355.959,132.794 362.21,129.686 C368.461,126.577 375.482,125.024 383.271,125.024 C393.846,125.024 403.101,127.776 411.032,133.276 L400.314,150.748 C397.527,148.816 394.863,147.39 392.328,146.459 C389.793,145.533 386.987,145.067 383.915,145.067 C380.127,145.067 376.681,145.8 373.571,147.265 C370.463,148.73 367.998,150.907 366.178,153.802 C364.356,156.696 363.442,160.145 363.442,164.145 C363.442,168.149 364.356,171.595 366.178,174.49 C367.998,177.383 370.463,179.563 373.571,181.025 C376.681,182.492 380.127,183.224 383.915,183.224 C386.987,183.224 389.793,182.76 392.328,181.829 C394.863,180.903 397.527,179.472 400.314,177.543 L411.032,195.011 C403.101,200.517 393.846,203.264 383.271,203.264 C375.482,203.264 368.461,201.71 362.21,198.605" id="Fill-35" fill="#FFFFFE"></path>
	        <polyline id="Fill-36" fill="#FFFFFE" points="478.857 145.282 460.101 145.282 460.101 201.659 439.738 201.659 439.738 145.282 420.981 145.282 420.981 126.632 478.857 126.632 478.857 145.282"></polyline>
	        <polygon id="Fill-37" fill="#FFFFFE" points="491.286 201.659 511.649 201.659 511.649 126.632 491.286 126.632"></polygon>
	        <path d="M583.389,129.901 C589.462,133.153 594.266,137.761 597.805,143.727 C601.342,149.693 603.111,156.498 603.111,164.145 C603.111,171.791 601.342,178.597 597.805,184.563 C594.266,190.529 589.462,195.137 583.389,198.389 C577.315,201.642 570.562,203.264 563.13,203.264 C555.699,203.264 548.948,201.642 542.875,198.389 C536.801,195.137 531.996,190.529 528.459,184.563 C524.921,178.597 523.152,171.791 523.152,164.145 C523.152,156.498 524.921,149.693 528.459,143.727 C531.996,137.761 536.801,133.153 542.875,129.901 C548.948,126.651 555.699,125.024 563.13,125.024 C570.562,125.024 577.315,126.651 583.389,129.901 M549.788,150.479 C546.32,153.874 544.588,158.43 544.588,164.145 C544.588,169.863 546.32,174.418 549.788,177.809 C553.252,181.206 557.7,182.902 563.13,182.902 C568.559,182.902 573.008,181.206 576.474,177.809 C579.939,174.418 581.676,169.863 581.676,164.145 C581.676,158.43 579.939,153.874 576.474,150.479 C573.008,147.087 568.559,145.389 563.13,145.389 C557.7,145.389 553.252,147.087 549.788,150.479" id="Fill-38" fill="#FFFFFE"></path>
	        <polyline id="Fill-39" fill="#FFFFFE" points="686.421 201.658 670.557 201.658 635.188 161.359 634.974 161.359 634.974 201.658 614.611 201.658 614.611 126.632 630.474 126.632 665.843 166.932 666.057 166.932 666.057 126.632 686.421 126.632 686.421 201.658"></polyline>
	    </g>
	</svg>
)

export default Logo;