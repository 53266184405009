import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';

const ResourceItem = (props) => {
    const resource = props.resource;

	return (
		<tr>
			<th><NavLink to={'/admin/activity/'+resource.id}>{ resource.title }</NavLink></th>
			<th>{ resource.status }</th>
			<th>{ resource.published_at ? moment(resource.published_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ resource.created_at ? moment(resource.created_at).format('D MMM YYYY, h:mm A') : '' }</th>
			<th>{ resource.organisation.title }</th>
			<th>{ resource.resource_type_name }</th>
		</tr>
	);
};

export default ResourceItem;