import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { 
	isNumericBoolean
} from '../../Utilities/adapter';

const BASE_URL = process.env.REACT_APP_API_URL+'api';
let keystages = [];
const INITIAL_STATE = {
	org: {
		keyword: '',
		artsAward: 0,
		artsMark: 0,
		supportSend: 0,
		artform: 0,
		postcode: '',
		sort: 'title',
		status: 'active',
		basic: 0,
		keystages: [],
		contact_first_name: '',
		contact_last_name: '',
		npo: 0
	},
	organisations: [],
	artforms: [],
	next_page: '',
	allKeystages: false,
	keystage0: false,
	keystage1: false,
	keystage2: false,
	keystage3: false,
	keystage4: false,
	keystage5: false,
	loading: false,
	showFilter: true,
	page: 1
};

class OrganisationFilter extends Component {
	state = INITIAL_STATE;

	componentDidMount() {
		this.getArtformTypes();
	}

	getArtformTypes = () => {
		axios.get(BASE_URL+'/artform-types')
		.then(response => {
			this.setState({
				artforms: response.data
			});
		});
	};

	onInputChange = (event) => {
		let fieldName = event.target.name;
		let fieldValue = event.target.value;

		if (event.target.type === 'checkbox') {
			let boolean = event.target.checked;
			let numericBoolean = isNumericBoolean(boolean);

			this.setState(
				prevState => ({ 
					org: { ...prevState.org, [fieldName] : numericBoolean }
				})
			);
		} else {
			this.setState(
				prevState => ({
					org: { ...prevState.org, [fieldName] : fieldValue }
				})
			);
		}
	}

	selectArtform = (e) => {
		let fieldValue = e.target.value;

		this.setState(
			prevState => ({
				org: { ...prevState.org, artform : fieldValue }
			}),
			() => this.handleSubmit
		);
	};

	selectKeystage = (e) => {
		let fieldValue = e.target.value;
		let keyName = e.target.id;

		if (fieldValue === 'all') {
			if (e.target.checked) {
				keystages.push(0);
				keystages.push(1);
				keystages.push(2);
				keystages.push(3);
				keystages.push(4);
				keystages.push(5);

				this.setState(
					prevState => ({
						org: { ...prevState.org, keystages: keystages },
						allKeystages: true,
						keystage0: true,
						keystage1: true,
						keystage2: true,
						keystage3: true,
						keystage4: true,
						keystage5: true
					}),
					() => this.handleSubmit
				);
			} else {
				keystages = [];

				this.setState(
					prevState => ({
						org: { ...prevState.org, keystages: keystages },
						allKeystages: false,
						keystage0: false,
						keystage1: false,
						keystage2: false,
						keystage3: false,
						keystage4: false,
						keystage5: false,
					}),
					() => this.handleSubmit
				);
			}

		} else {
			if (e.target.checked) {
				keystages.push(parseInt(fieldValue));

				this.setState(
					prevState => ({
						org: { ...prevState.org, keystages: keystages },
						[keyName]: true
					}),
					() => this.handleSubmit
				);				
			} else {
				keystages.pop();

				this.setState(
					prevState => ({
						org: { ...prevState.org, keystages: keystages },
						[keyName]: false
					}),
					() => this.handleSubmit
				);	
			}
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();

		this.props.onFilteredResults(this.state);
	};

	onClearFilter = (e) => {
		e.preventDefault();

		this.setState(INITIAL_STATE);

		this.props.onClearFilter();
	};

	renderArtformSelection() {
		return (
			<select 
				onChange={this.onInputChange} 
				value={this.state.org.artform} 
				name="artform" 
				id="artform" 
				className="form-control tab-filter-select"
			>
				<option value={0}>CHOOSE</option>
				{this.state.artforms.map(art =>
					<option key={art.id} value={art.id}>{art.name}</option>
				)}
			</select>
		);
	}

	render () {
		const { match } = this.props;

		return (
			<section className="dashboard-filter events-filter">
			    <div className="container">
			        <form className="row py-3">
			            <div className="col-12 col-md-7 mb-4 mb-md-0">
							<div className="row d-flex mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
				    				<label htmlFor="keystage" className="text-white">Key stage</label>
				    			</div>

				    			<div className="col">
					    			<div id="keystage" className="d-flex justify-content-between text-white">
					    				<div>
						    				<label htmlFor="all" className="mr-1 mb-0">All</label>
						    				<input onChange={this.selectKeystage} id="all" type="checkbox" name="keystages" value="all" checked={this.state.allKeystages} />
						    			</div>
										<div>
						    				<label htmlFor="key0" className="mx-1 mb-0">EY</label>
						    				<input onChange={this.selectKeystage} id="keystage0" type="checkbox" name="keystages" value={0} checked={this.state.keystage0} />
						    			</div>
						    			<div>
						    				<label htmlFor="key1" className="mx-1 mb-0">1</label>
						    				<input onChange={this.selectKeystage} id="keystage1" type="checkbox" name="keystages" value={1} checked={this.state.keystage1} />
						    			</div>
						    			<div>
						    				<label htmlFor="key2" className="mx-1 mb-0">2</label>
						    				<input onChange={this.selectKeystage} id="keystage2" type="checkbox" name="keystages" value={2} checked={this.state.keystage2} />
						    			</div>
						    			<div>
						    				<label htmlFor="key3" className="mx-1 mb-0">3</label>
						    				<input onChange={this.selectKeystage} id="keystage3" type="checkbox" name="keystages" value={3} checked={this.state.keystage3} />
						    			</div>
						    			<div>
						    				<label htmlFor="key4" className="mx-1 mb-0">4</label>
						    				<input onChange={this.selectKeystage} id="keystage4" type="checkbox" name="keystages" value={4} checked={this.state.keystage4} />
						    			</div>
						    			<div>
						    				<label htmlFor="key5" className="mx-1 mb-0">5</label>
						    				<input onChange={this.selectKeystage} id="keystage5" type="checkbox" name="keystages" value={5} checked={this.state.keystage5} />
						    			</div>
					    			</div>
				    			</div>
				    		</div>

							<div className="row mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
									<label htmlFor="artform" className="text-white">Artform</label>
								</div>

								<div className="col">
									{ this.renderArtformSelection() }
								</div>
							</div>

							<div className="tab-filter-checklist row mb-2">
								<div className="col-12 col-lg text-white mb-2 mb-lg-0 d-flex align-items-center">
				    				<label htmlFor="artsmark" className="mb-0 mr-auto mr-lg-14">Artsmark Partner</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="artsmark" 
				    					type="checkbox" 
				    					checked={this.state.org.artsMark} 
				    					name="artsMark" 
				    				/>
					    		</div>

					    		<div className="col-12 col-lg text-white d-flex justify-content-end align-items-center">
				    				<label htmlFor="artsaward" className="mb-0 mr-auto mr-lg-2" style={{whiteSpace: 'nowrap'}}>Arts Award supporter</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="artsaward" 
				    					type="checkbox" 
				    					checked={this.state.org.artsAward} 
				    					name="artsAward" 
				    				/>
				    			</div>
				    		</div>

				    		<div className="row mb-2">
				    			<div className="col-12 col-lg-6 text-white d-flex mb-2 mb-lg-0 align-items-center">
				    				<label htmlFor="sendapp" className="mb-0 mr-auto mr-lg-2" style={{whiteSpace: 'nowrap'}}>SEND applicable</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="sendapp" 
				    					type="checkbox" 
				    					checked={this.state.org.supportSend} 
				    					name="supportSend" 
				    				/>
				    			</div>

				    			<div className="col-12 col-lg-6 text-white d-flex mb-2 justify-content-end align-items-center">
				    				<label htmlFor="basic" className="mr-auto mr-lg-2 mb-0">Basic</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="basic" 
				    					type="checkbox" 
				    					checked={this.state.org.basic} 
				    					name="basic" 
				    				/>
				    			</div>

				    			<div className="col-12 col-lg text-white d-flex justify-content-end align-items-center">
				    				<label htmlFor="npo" className="mr-auto mr-lg-2 mb-0">NPO</label>
				    				<input 
				    					onChange={this.onInputChange} 
				    					id="npo" 
				    					type="checkbox" 
				    					checked={this.state.org.npo} 
				    					name="npo" 
				    				/>
				    			</div>
				    		</div>

				    		<div className="row">
			                	<div className="col offset-lg-3">
				                	<Link to={`${match.path}/create`} className="dashboard-form-modal btn btn-success text-white font-weight-bold"><i className="fa fa-plus" aria-hidden="true"></i> ADD ORGANISATION</Link>
								</div>
			            	</div>
			            </div>

			        	<div className="col-12 col-md-5 d-md-flex flex-md-column">
							<div className="row mb-2 flex-column flex-lg-row">
								<div className="col-12 col-md-3">
									<label htmlFor="orgbyname" className="text-white">Keyword</label>
								</div>
								<div className="col">
									<input 
										id="orgbyname" 
										onChange={this.onInputChange} 
										value={this.state.org.keyword} 
										type="text" 
										name="keyword" 
										placeholder="SEARCH BY KEYWORD" 
										className="form-control mb-2 mb-md-0" 
									/>
								</div>
							</div>

			                <div className="row mb-2 flex-column flex-lg-row">
			                	<div className="col-12 col-md-3">
			                	    <label htmlFor="contactFirstname" className="text-white">Contact</label>
			                    </div>
			                    <div className="col">
				                    <input 
				                    	onChange={this.onInputChange} 
				                    	value={this.state.org.contact_first_name} 
				                    	id="contact_first_name" 
				                    	className="form-control mr-2" 
				                    	type="text" 
				                    	name="contact_first_name" 
				                    	placeholder="CONTACT FIRST NAME" 
				                    />
				                </div>
			                </div>

			                <div className="row mb-2">
				                <div className="col offset-lg-3">
				                    <input 
				                    	onChange={this.onInputChange} 
				                    	value={this.state.org.contact_last_name} 
				                    	id="contact_last_name" 
				                    	className="form-control mb-2 mb-md-0" 
				                    	type="text" 
				                    	name="contact_last_name" 
				                    	placeholder="CONTACT LAST NAME" 
				                    />
				                </div>
			                </div>

			                <div className="row mb-2 flex-column flex-lg-row">
			                    <div className="col-12 col-md-3">
			                    	<label htmlFor="status" className="text-white">Status</label>
			                    </div>
			                    <div className="col">
			                    	<div className="tab-filter-select-wrap">
					                    <select 
					                    	onChange={this.onInputChange} 
					                    	value={this.state.org.status}
					                    	name="status"
					                    	className="form-control tab-filter-select text-primary"
					                    >
					                        <option value="active">ACTIVE</option>
					                        <option value="draft">DRAFT</option>
					                        <option value="activity_only">ACTIVITY ONLY</option>
										    <option value="rejected">REJECTED</option>
											<option value="inactive">INACTIVE</option>
					                    </select>
					                </div>
				                </div>
			                </div>

							<div className="dashboard-form-group row mt-md-auto">
								<div className="col offset-lg-3">
									<button onClick={this.handleSubmit} className="btn btn-dark mb-md-0 font-weight-bold text-white">SHOW RESULTS</button>
									<button onClick={this.onClearFilter} className="btn text-light font-weight-bold text-underline">CLEAR FILTERS</button>
								</div>
							</div>

						</div>
			        </form>
			    </div>
			</section>
		);
	}
}

export default OrganisationFilter;