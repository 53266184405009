import React from 'react';

const Footer = () => {
	const footerText = 'Designed and built by UXB London';
	const footerCredit = 'Site produced by Steve Woodward and Jim Beck at';

	return(
		<footer className="bg-primary">
			<div className="container py-3 py-md-8">
				<p className="text-white text-right m-0">{footerText}</p>
				<p className="text-white text-right m-0">{footerCredit} <a className="text-white" href="https://www.anewdirection.org.uk/">A New Direction</a></p>
			</div>
		</footer>
	);
}

export default Footer;